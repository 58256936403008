import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {handleThemeRedirection} from "../../my-account/myAccountHelpers";
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {emailValidator} from '../../../util/validator';
import {RegistrationContext} from '../../../contexts/registration';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import {InputLabel} from '@mui/material';
import {useSnackbar} from 'notistack';
import {handleSubmit} from "./helpers";
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {GlobalContext} from '../../../contexts/global';
import Link from '@mui/material/Link';
import {Box} from '@mui/system';
import {MR_APP_REDIRECT_URI} from "../../../util/constants";

export type VerifyEmailPageProps = {
  theme: string
}

/**
 * Registration page containing input to enter email to be verified.
 */
const VerifyEmailPage = (props: VerifyEmailPageProps) => {
  const { theme } = props;
  const navigate = useNavigate();
  const { globalContext, } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { setRegistrationContext } = useContext(RegistrationContext);
  const [email, setEmail] = useState<string>('');
  const [emailHelperText, setEmailHelperText] = useState<string>('');
  const [emailIsSent, setEmailIsSent] = useState<boolean>(false);

  useEffect(() => {
    pushRouteChangeEvent('Register: VerifyEmailPage');
  }, []);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(
          event,
          email,
          setEmailHelperText,
          setRegistrationContext,
          enqueueSnackbar,
          setEmailIsSent,
          t,
          theme,
          globalContext,
          navigate
        )}
        noValidate
        className={globalContext.referer === 'choices' ? "choicesWhiteBox" : ""}
      >
        {globalContext.isWebview ?
            <></>
            :
            <Typography variant="h2" component="h2" className={theme === "choices" ? "topHeaderChoices" : "topHeader"}>
              {t('REGISTER.HEADERS.CREATE_ACCOUNT')}
            </Typography>
        }
        <Typography
          component="p"
          variant="body2"
          className={theme === "choices" ? "topTextRegisterChoices" : "topTextRegister"}>
          {globalContext.referer === "choices" ? t('REGISTER.VERIFY_EMAIL.CHOICES.TOP_TEXT-VERIFY') : t('REGISTER.VERIFY_EMAIL.TOP_TEXT-VERIFY')}
        </Typography>
        <div className="centeredContainer">
          <div className="innerColumn">
            <div className="noBG-form">
              <InputLabel required className="inputLabel inputLabelWhite">{t('BASIC.EMAIL')}</InputLabel>
              <TextField
                className="fullWidth"
                required
                id="regEmail"
                // label={t('BASIC.EMAIL')}
                name="email"
                autoComplete="email"
                // autoFocus
                error={emailHelperText !== ''}
                helperText={emailHelperText}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailHelperText(emailValidator(event.target.value, t));
                  setEmailIsSent(false);
                }}
                inputProps={{
                  'aria-label': 'Email Address text field',
                }}
              />
            </div>
          </div>
        </div>
        <div className="centeredContainer account-steps-nav-cancel">
          <Button
            type="submit"
            variant="contained"
            className="primaryButtonDefault"
            id="nextStepBtn"
            disabled={emailIsSent}
          >{t('BUTTONS.SUBMIT')}</Button>
          {globalContext.isWebview ?
            <Button
              onClick={() => {
                if (globalContext.referer == 'morerewards') {
                  window.location.replace(MR_APP_REDIRECT_URI);
                } else {
                  navigate('/');
                }
              }}
              variant="contained"
              className="cancelButtonDefault"
              id="nextStepBtn"
              disabled={emailIsSent}
            >{t('Cancel')}</Button>
            :
            <Button
                onClick={() => handleThemeRedirection(theme, globalContext.trafficType)}
                variant="contained"
                className="cancelButtonDefault"
                id="nextStepBtn"
                disabled={emailIsSent}
            >{t('Cancel')}</Button>
          }
        </div>
        {globalContext.isWebview ?
            <></>
            :
            <Box className="registerBox">
              {theme === "choices" ?
                <div className="registerLink">
                  Already have an account?
                  <Link className="link-yellow link-choices" onClick={() => navigate('/')}>
                    Login
                  </Link>
                </div>
                :
                <div className="registerLink">
                  Already a member?
                  <Link className="link-yellow link-choices" onClick={() => navigate('/')}>
                    Login
                  </Link>
                </div>
              }
            </Box>
        }
      </form>
    </RegistrationLayout>
  );
};

export default VerifyEmailPage;