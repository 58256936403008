import React, {useContext} from 'react';
import Link from '@mui/material/Link';
import { GlobalContext } from '../../contexts/global';
import { LINK_CONTACT_US, LINK_CONTACT_US_CHOICES } from '../../util/constants';

type NeedHelpProps = { theme: string }

const NeedHelpComponent: React.FC<NeedHelpProps> = (props: NeedHelpProps) => {
  const { globalContext, setGlobalContext} = useContext(GlobalContext);
  const { theme } = props;
  const contactLink = globalContext.referer === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US;

  return (
    <div className="need-help">
      {theme === 'choices' ? 'Have Questions?' : 'Need help?'}&nbsp;
      <Link
        href={contactLink}
        target="_blank"
        className="need-help-link link-tamewhite-contact-us-link link-choices"
      >
        Contact us
      </Link>
    </div>
  );
};

export default NeedHelpComponent;