import React from 'react';
import {signOutAllSPs} from '../../../pages/login/logout';

// Logic to handle when a user goes idle with the logout confirmation modal left open
const onIdleInLogoutModal = async (
  handleOpen: Function,
  sessionTimeoutRef: React.MutableRefObject<any>,
  theme: string
) => {
  let gotoSessionStorage = localStorage.getItem('goto');
  if (gotoSessionStorage === 'mobiletest') { // Don't auto logout when a session is established in a mobile web view
    return;
  }
  handleOpen();
  sessionTimeoutRef.current = await setTimeout(() => {
    signOutAllSPs(null, theme); // Same as 'sign out' button. Log user out of all SP's if idle in modal for 10 seconds
  }, 10000);
};

// Handle when user clicks to keep their session active in modal
const keepSessionActive = (
  handleClose: Function,
  sessionTimeoutRef: React.MutableRefObject<any>
) => {
  handleClose();
  clearTimeout(sessionTimeoutRef.current);
};

// Handle when user clicks button to confirm sign out in idle modal
const confirmDialogueLogout = (
  handleClose: Function,
  sessionTimeoutRef: React.MutableRefObject<any>,
  theme: string
) => {
  handleClose(); // Close the dialogue anyway
  clearTimeout(sessionTimeoutRef.current);
  signOutAllSPs(null, theme); // Same as 'sign out' button. Log user out of all service providers if click confirm button
};

export {onIdleInLogoutModal, keepSessionActive, confirmDialogueLogout};