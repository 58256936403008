import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {EmailInput} from './changeEmailTypes';
import {EmailProps} from '../myAccountTypes';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {checkInputFields, emailValidator} from '../../../util/validator';
import {checkMatchingInput, handleSubmitEmailChange} from './helper-functions/helpers';
import {useTranslation} from 'react-i18next';
import {Grid, InputLabel} from '@mui/material';
import {useAtom} from 'jotai';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import {LoadingButton} from '@mui/lab';
import {GlobalContext} from '../../../contexts/global';
import SaveIcon from '@mui/icons-material/Save';
import './changeEmail.scss';

export default function ChangeEmail(props: EmailProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalContext } = useContext(GlobalContext);
  const { setRehydrateApplication, theme } = props;
  const { t } = useTranslation();
  const [profileInfo, updateProfileInfo] = useAtom(profileInfoAtom);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailConfirmationHelperText, setEmailConfirmationHelperText] = useState<string>('');
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [email, setNewEmail] = useState<EmailInput>({
    newEmail: '',
    newEmailConfirmation: '',
    anyInputIsEmpty: true
  });

  useEffect(() => {
    pushRouteChangeEvent('Profile: ChangeEmail');
  }, []);

  useEffect(() => {
    checkInputFields(email, setNewEmail, 'email');
    checkMatchingInput(
      emailHelperText,
      emailConfirmationHelperText,
      setEmailConfirmationHelperText,
      setEmailHelperText,
      email,
      profileInfo
    );
  }, [email.newEmailConfirmation, email.newEmail]);

  return (
    <>
      <Box>
        <Paper elevation={0}>
          <div className="infoBar infoBarThin">
            <div className="info"><h3>Manage Your Email Address</h3></div>
          </div>
          <div className="centeredContainer">
            <div className="innerColumn">
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputLabel className="inputLabel">Current Email</InputLabel>
                  <TextField
                    disabled
                    id="outlined-basic"
                    variant="outlined"
                    value={profileInfo.email.toLowerCase()}
                    className="fullWidth"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputLabel required className="inputLabel">New Email</InputLabel>
                  <TextField
                    value={email.newEmail}
                    aria-label="New Email"
                    id="outlined-basic"
                    variant="outlined"
                    className="fullWidth"
                    // @ts-ignore
                    error={emailHelperText}
                    helperText={emailHelperText}
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setNewEmail((prev: EmailInput) => ({
                        ...prev,
                        newEmail: event.target.value
                      }));
                      setEmailHelperText(emailValidator(event.target.value, t));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputLabel required className="inputLabel">Confirm New Email</InputLabel>
                  <TextField
                    value={email.newEmailConfirmation}
                    aria-label="Confirm New Email"
                    id="outlined-basic"
                    variant="outlined"
                    className="fullWidth"
                    // @ts-ignore
                    error={emailConfirmationHelperText}
                    helperText={emailConfirmationHelperText}
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setNewEmail((prev: EmailInput) => ({
                        ...prev,
                        newEmailConfirmation: event.target.value
                      }));
                      setEmailConfirmationHelperText(emailValidator(event.target.value, t));
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="editButtons">
              {loadingEmail
                ?
                <LoadingButton
                  loading
                  className="primaryButtonDefault"
                  loadingPosition="start"
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<SaveIcon/>}
                  variant="outlined"
                >
                  Processing...
                </LoadingButton>
                :
                <Button
                  disabled={email.anyInputIsEmpty || (emailHelperText !== '' || emailConfirmationHelperText !== '')}
                  type="submit"
                  className="primaryButtonDefault"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSubmitEmailChange(
                    setNewEmail,
                    email,
                    setEmailHelperText,
                    setEmailConfirmationHelperText,
                    profileInfo,
                    updateProfileInfo,
                    setLoadingEmail,
                    setRehydrateApplication,
                    t,
                    navigate,
                    setGlobalContext,
                    enqueueSnackbar,
                    theme
                  )}>
                  Save
                </Button>
              }
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
}