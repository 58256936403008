import React, {useContext, useEffect, useState} from 'react';
import {LINK_CONTACT_US} from "../../util/constants";
import RegistrationLayout from '../register/layout/registration-layout';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../components/loading/circular-loading';
import {
  handleSubmitOTP,
  handleCancelOTP,
  handleTextFieldChange,
  handleSubmitNewOTPEmail,
  disableOTPPage
} from './helpers/helpers';
import {useSnackbar} from 'notistack';
import {InputLabel, Link, Paper} from '@mui/material';
import {GlobalContext} from '../../contexts/global';
import {Box} from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {OneTimePassProps, OTPState} from './one-time-pass-types';
import {LoginStateType} from "./loginTypes";

// param revert is the initial login mount call.
export const OneTimePassPage = (props: OneTimePassProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const {t, ready} = useTranslation();
  const { revert, theme, setLoginState, loginState } = props;
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const [oneTimePass, setOneTimePass] = useState<string>('');
  const [oneTimePassHelperText, setOneTimePassHelperText] = useState<string>('');

  // set a 10 minute timeout on OTP page and create a new AM login session
  useEffect(() => {
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: false,
        resendButtonLoading: false
      }
    }))

    if (loginState.OTPState.displayOTP) {
      setTimeout(
        () => disableOTPPage(
          enqueueSnackbar,
          globalContext,
          setGlobalContext,
          setLoginState
        ), 600000);
    }
  }, []);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <div className="OTPContainer">
        <Paper elevation={0} className="paperOTP">
          <Typography
            variant="h5"
            component="h5"
            className="topHeaderOTP">{t('LOGIN.ONE_TIME_PASS.HEADER')}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className={theme === "choices" ? "topTextOTPChoices" : "topTextOTP"}>
            We noticed something out of the ordinary, for security purposes, we need to verify it's really you.
            <br/><br/>
            We sent you an email with a temporary code, valid for 10 minutes, please enter it below to access your
            account.
            <br/><br/>
            Didn’t receive an email? Please check your Junk mail. Still didn't receive an email? Please&nbsp;
            <Link
              href={LINK_CONTACT_US}
              target="_blank"
              className="link-white link-choices"
            >contact us
            </Link>
          </Typography>
        </Paper>
      </div>
      <div className="centeredContainer">
        <div className="innerColumn">
          <Box className="login-form noBG-form">
            <InputLabel required className="inputLabel inputLabelWhite">One Time Password</InputLabel>
            <TextField
              className="fullWidth"
              required
              // autoFocus
              inputProps={{maxLength: 6}}
              error={oneTimePassHelperText !== ''}
              helperText={oneTimePassHelperText}
              value={oneTimePass}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleTextFieldChange(
                event,
                setOneTimePass,
                setOneTimePassHelperText,
                t
              )}/>
          </Box>
        </div>
      </div>
      <div className="centeredContainer">
        <div className="OTPButtonMargins">
          {loginState.OTPState.resendButtonLoading
            ?
            <LoadingButton
              loading
              className="resendButtonDefaultOTP"
              loadingPosition="start"
              startIcon={<SaveIcon/>}
              variant="outlined"
            >
              Sending...
            </LoadingButton>
            :
            <Button
              disabled={loginState.OTPState.resendButtonLoading || loginState.OTPState.OTPLoading}
              type="submit"
              variant="contained"
              className="resendButtonDefaultOTP"
              id="nextStepBtn"
              onClick={() => handleSubmitNewOTPEmail(
                globalContext,
                setGlobalContext,
                enqueueSnackbar,
                loginState.password,
                setLoginState
              )}
            > Resend
            </Button>
          }
        </div>
        <div className="OTPButtonMargins">
          {loginState.OTPState.OTPLoading
            ?
            <LoadingButton
              loading
              className="primaryButtonDefault"
              loadingPosition="start"
              startIcon={<SaveIcon/>}
              variant="outlined"
            >
              Verifying...
            </LoadingButton>
            :
            <Button
              disabled={loginState.OTPState.resendButtonLoading || loginState.OTPState.OTPLoading}
              type="submit"
              variant="contained"
              className="primaryButtonDefault"
              id="nextStepBtn"
              onClick={() => handleSubmitOTP(
                loginState.password,
                oneTimePass,
                setOneTimePassHelperText,
                globalContext,
                setGlobalContext,
                enqueueSnackbar,
                setLoginState,
                t
              )}
            >Verify</Button>
          }
        </div>
        <div className="OTPButtonMargins">
          <Button
            disabled={loginState.OTPState.resendButtonLoading || loginState.OTPState.OTPLoading}
            variant="outlined"
            className="cancelButtonDefault"
            onClick={() => handleCancelOTP(
              revert,
              globalContext,
              setGlobalContext,
              setLoginState
            )}
          >{t('BUTTONS.CANCEL')}</Button>
        </div>
      </div>
    </RegistrationLayout>
  );
};

export default OneTimePassPage;