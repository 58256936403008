import {PasswordInfo, Weakness, WeaknessesArray} from '../../../pages/my-account/change-password/changePasswordTypes';

// calculate amount of lowercase & uppercase characters in the password
const characterTypeWeakness = (newPassword: string, regex: RegExp, type: string): Weakness => {
  const matches = newPassword.match(regex) || [];
  if (matches.length < 1) {
    return {
      message: `X ${type}`,
      deduction: 20,
      color: '#f3692d',
      error: true
    };
  }
  return {
    message: `✔ ${type}`,
    deduction: 0,
    color: '#36d13e',
    error: false
  };
};

// calculate the length of the password
const lengthWeakness = (newPassword: string): Weakness => {
  const length = newPassword.length;
  if (length <= 8) {
    return {
      message: 'X Minimum 8 characters',
      deduction: 20,
      color: '#f3692d',
      error: true
    };
  }
  return {
    message: '✔ Minimum 8 characters',
    deduction: 0,
    color: '#36d13e',
    error: false
  };
};

// show user checkbox if the old and new password match or not
const passwordMatchWeakness = (newPassword: string, confirmPassword: string): Weakness => {
  if (newPassword.length === 0) {
    return {
      message: 'X please type a new password',
      deduction: 0,
      color: '#f3692d',
      error: false
    };
  } else if (newPassword === confirmPassword) {
    return {
      message: '✔ password and password confirmation match',
      deduction: 0,
      color: '#36d13e',
      error: false
    };
  } else {
    return {
      message: `X password and password confirmation do not match`,
      deduction: 0,
      color: '#f3692d',
      error: false
    };
  }
};

// calculate a users overall password strength
const calculatePasswordStrength = (passwordInfo: PasswordInfo): WeaknessesArray => {
  const newPassword: string = passwordInfo.newPassword.input;
  const weaknesses: WeaknessesArray = [];
  // weaknesses.push(passwordMatchWeakness(newPassword, passwordInfo.confirm.input));
  weaknesses.push(lengthWeakness(newPassword));
  weaknesses.push(characterTypeWeakness(newPassword, /[A-Z]/g, 'Uppercase characters'));
  weaknesses.push(characterTypeWeakness(newPassword, /[a-z]/g, 'Lowercase characters'));
  weaknesses.push(characterTypeWeakness(newPassword, /[^0-9a-zA-Z\s]/g, 'Special characters'));
  weaknesses.push(characterTypeWeakness(newPassword, /[0-9]/g, 'Numbers'));
  return weaknesses;
};

export {calculatePasswordStrength};