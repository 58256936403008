export const sofTheme = {
  palette: {
    primary: {
      main: '#78be20',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#6cab73'
    },
    success: {
      main: '#03a9f4'
    },
    warning: {
      main: '#ff9800'
    },
    error: {
      main: '#ef5350'
    }
  }
};
