import React, {useContext, useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {completeEmailChangeRequest} from '../../../network-requests/resources';
import {logoutOauthSession} from '../../login/logout';
import PageFooter from '../../../components/footer/footer';
import {useSnackbar} from 'notistack';
import {OAuth2Tokens} from "../../../network-requests/network-request-types";
import {NavigateFunction} from 'react-router';
import {GlobalContext} from '../../../contexts/global';
import {GlobalContextType} from '../../../contexts/types/global';
import './changeEmailConfirmation.css';
import {FRUser} from "@forgerock/javascript-sdk";
import {CircularLoading} from "../../../components/loading/circular-loading";

export type SuccessfulEmailUpdateResponse = {
  'data': {
    'completeChangeEmail': {
      'success': boolean,
      'oldEmail': string,
      'newEmail': string
    }
  }
}

export type ChangeEmailConfirmationProps = {
  setIsAuth: any,
  theme: string
}

// Conditionally handle when a user's email is successfully changed either log them out or simply navigate to the login screen
const navigateUserUponSuccessfulVerification = async (
  userIsAuthenticated: boolean,
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  setIsAuth: any,
  enqueueSnackbar: any
) => {
  if (userIsAuthenticated) {
    enqueueSnackbar('Your email address has been changed. Please log back in with your new email address!', { variant: 'success' })
    await logoutOauthSession(setGlobalContext, navigate, setIsAuth, 'desktop', null);
  } else {
    enqueueSnackbar('Your email address has been changed. Please log back in with your new email address!', { variant: 'success' })
    await navigate('/');
  }
};

// Send off the second request to the microservice to confirm the change email change
const completeEmailChange = async (
  jwt: string,
  setUserIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessfullyVerifiedEmail: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  enqueueSnackbar: any
) => {
  try {
    setLoading(true);
    const testClientID: string = 'forgerock-sdk-MoreRewardsOAuth';
    const isAuth: OAuth2Tokens = JSON.parse(localStorage.getItem(testClientID));
    if (isAuth) {
      await setUserIsAuthenticated(true);
    }
    let r = await completeEmailChangeRequest(jwt);
    if (r.status == 200) {
      setSuccessfullyVerifiedEmail(true);
      setLoading(false);
      // call logout method to invalidate the access token from the same browser
      await FRUser.logout();
    } else if (r.status == 202) {
      setError('Oops, looks like you had already clicked on this link. To change your email address again, please go to your account and follow the prompts.');
      setLoading(false);
      // call logout method to invalidate the access token from the same browser
      await FRUser.logout();
    } else {
      setLoading(false);
      setError('Oops, looks like that change email request is no longer valid. Please leave this page');
      return;
    }
  } catch (e) {
    enqueueSnackbar('Something went wrong when trying to complete your email change. Please try again', { variant: 'error' });
    return setLoading(false); // if fails stop the loading spinner
  }
};

export default function ChangeEmailConfirmation(props: ChangeEmailConfirmationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jwt: string = useMemo(() => searchParams.get('jwt'), []);
  const { setIsAuth, theme } = props;
  const { setGlobalContext } = useContext(GlobalContext);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [successfullyVerifiedEmail, setSuccessfullyVerifiedEmail] = useState<boolean>(false);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (jwt) {
      completeEmailChange(
        jwt,
        setUserIsAuthenticated,
        setLoading,
        setSuccessfullyVerifiedEmail,
        setError,
        enqueueSnackbar
      );
    }
  }, []);

  useEffect(() => {
    if (successfullyVerifiedEmail) {
      navigateUserUponSuccessfulVerification(
        userIsAuthenticated,
        navigate,
        setGlobalContext,
        setIsAuth,
        enqueueSnackbar
      );
    }
  }, [successfullyVerifiedEmail]);

  const buttonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    clickType: string,
    navigate: NavigateFunction
  ) => {
    switch (clickType) {
      case 'profile':
        navigate('/userPage');
        break;
      case 'login':
        navigate('/');
        break;
    }
  };

  return (
    <>
      <div className="changeEmailConfirmation">
        <div>
          {loading
            ?
            <div className="loadingProfile">
              <Box>
                <CircularLoading theme={theme}/>
              </Box>
            </div>
            :
            <>
              {successfullyVerifiedEmail
                ?
                <>
                </>
                :
                <>
                  <div className="errorChangeEmail">
                    {userIsAuthenticated
                      ?
                      <>
                        <h2 style={theme === 'choices' ? { color: 'black' } : { color: 'white' }}>{error}</h2>
                        <div style={{ marginTop: '30px', marginBottom: '100px' }}>
                          <Button
                            className="primaryButtonDefault sign-in-button g-recaptcha"
                            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => buttonClick(
                              event,
                              'profile',
                              navigate
                            )}>Return
                            To Profile Management</Button>
                        </div>
                      </>
                      :
                      <>
                        <h2 style={{ color: 'white' }}>{error}</h2>
                        <div style={{ marginTop: '30px', marginBottom: '100px', textAlign: 'center' }}>
                          <Button
                            className="primaryButtonDefault sign-in-button-error-page g-recaptcha"
                            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => buttonClick(
                              event,
                              'login',
                              navigate
                            )}>Sign In</Button>
                        </div>
                      </>
                    }
                  </div>
                </>
              }
            </>
          }
        </div>
      </div>
      <PageFooter isLogin={true} theme={theme}/>
    </>
  );
}