import React from "react";
import {RegInfoType} from "../../../contexts/registration";
import {TFunction} from "i18next";
import {sendForgotPasswordEmail} from "../../../network-requests/register";
import {displayCustomError} from "../errors/display-custom-error";
import {displaySendVerificationEmailConfirmation} from "../success/send-email-confirmation-success";
import {HTTP_STATUS_OK} from "../../../util/constants";

/* some notes on this method and what needs to be fixed for phase 2:
 * 1. Why is this method using promises to resolve requests from the Microservice and not async/await?
 * 2. Why is the response from the microservice for the error msg not a JSON payload? Using 'r.text().then(msg)'
 * to read this is verbose. Using promises like this is not ideal. */
export const resetPasswordLogin = async (
  email: string,
  enqueueSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string
) => {
  sendForgotPasswordEmail(email, theme).then((r: Response) => {
    if (r.status !== HTTP_STATUS_OK) { // if not successful display error response and stop
      r.text().then(msg => displayCustomError(enqueueSnackbar, theme, msg));
      return;
    }
    setRegistrationContext((prev: RegInfoType) => ({ ...prev, email: email }));
    displaySendVerificationEmailConfirmation(enqueueSnackbar, theme, email);
  }).catch(() => enqueueSnackbar(t('ERRORS.API_OFFLINE', { variant: 'error' })));
}