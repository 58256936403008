import CardWithLogo from '../../../components/card/card-with-logo';
import PageFooter from '../../../components/footer/footer';
import React, {useContext, useEffect, useState} from 'react';
import '../../../styling/registration/registration.scss';
import '../../../styling/registration/registration-layout.scss';
import {getSessionReferer} from "../../../util/referer";
import {GlobalContext} from "../../../contexts/global";

function RegistrationLayout(props: any) {
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const theme = getSessionReferer();
    setTheme(theme);
  }, []);

  return (
    <div className="root">
      <div className="page">
        <CardWithLogo displayBannerLogo={!globalContext.isWebview} globalContext={globalContext}>
          {props.children}
        </CardWithLogo>
      </div>
      <div>
        <PageFooter isLogin={false} theme={theme}/>
      </div>
    </div>
  );
}

export default RegistrationLayout;