import React from 'react';
import {PasswordInfo, WeaknessesArray} from '../../../pages/my-account/change-password/changePasswordTypes';
import {calculatePasswordStrength} from './passwordStrengthChecker';
import {updateUsersPassword} from '../../../network-requests/resources';
import {NavigateFunction} from 'react-router';
import {GlobalContextType} from '../../../contexts/types/global';

let zxcvbn = require('zxcvbn');

// Find the color of the strength meter based on its state
const findStrengthMeterColor = (
  passwordStrengthMeterValue: number,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): void => {
  if (passwordStrengthMeterValue === 100) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrengthInformation: {
        strengthMeterBarColor: 'success',
        strengthMeterText: 'very strong'
      }
    }));
  } else if (passwordStrengthMeterValue === 75) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrengthInformation: {
        strengthMeterBarColor: 'success',
        strengthMeterText: 'strong'
      }
    }));
  } else if (passwordStrengthMeterValue === 50) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrengthInformation: {
        strengthMeterBarColor: 'warning',
        strengthMeterText: 'weak'
      }
    }));
  } else {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrengthInformation: {
        strengthMeterBarColor: 'error',
        strengthMeterText: 'very weak'
      }
    }));
  }
};

// Determine the strength of a password based on its score
const findStrengthValueString = (
  passwordStrengthMeterValue: number,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): void => {
  if (passwordStrengthMeterValue === 100) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrength: 'very strong'
    }));
  } else if (passwordStrengthMeterValue === 75) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrength: 'strong'
    }));
  } else if (passwordStrengthMeterValue === 50) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrength: 'weak'
    }));
  } else if (passwordStrengthMeterValue <= 25) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      passwordStrength: 'very weak'
    }));
  }
};

// Determine strength of the current password the user is currently typing in
const determinePasswordStrength = async (
  passwordInfo: PasswordInfo,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): Promise<void> => {
  let newPasswordMeterValue: number = 100;
  const weaknessesArr = calculatePasswordStrength(passwordInfo);
  let result = zxcvbn(passwordInfo.newPassword.input);
  newPasswordMeterValue = result.score / 4 * 100;
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    weaknesses: weaknessesArr,
    passwordStrengthMeterValue: newPasswordMeterValue,
    warning: result.feedback.warning,
    suggestions: result.feedback.suggestions
  }));
};

// Ensure all requirements are met for a password being typed
const allRequirementsMet = (
  passwordInfoWeaknesses: WeaknessesArray,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): void => {
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    allRequirementsMet: prev.passwordStrengthMeterValue > 20
  }));
};

// Set the helper text for the old password field
const setOldPasswordHelperText = (
  passwordInfo: PasswordInfo,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): void => {
  let helperText: string;
  let helperTextNewPw: string;
  let helperTextConfirm: string;
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    oldPassword: {
      ...prev.oldPassword,
      helperText: helperText
    }
  }));
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    newPassword: {
      ...prev.newPassword,
      helperText: helperTextNewPw
    }
  }));
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    confirm: {
      ...prev.confirm,
      helperText: helperTextConfirm
    }
  }));
};

// Clear all input fields on submission
const handleSubmit = async function (
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>,
  passwordInfo: PasswordInfo,
  setSavingChanges: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  theme: string,
  setPasswordUpdated: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  setSavingChanges(true);
  const response: Response = await updateUsersPassword(passwordInfo, theme);
  let responseMessage = await response.json();
  setSavingChanges(false);
  if (response.status !== 200) {
    enqueueSnackbar(responseMessage, { variant: 'error' });
    return setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      oldPassword: {
        ...prev.oldPassword,
        input: ''
      }
    }));
  }
  await setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    oldPassword: {
      ...prev.oldPassword,
      input: ''
    },
    newPassword: {
      ...prev.newPassword,
      input: ''
    },
    confirm: {
      ...prev.confirm,
      input: ''
    }
  }));
  setPasswordUpdated(true);
};

export {
  determinePasswordStrength,
  findStrengthMeterColor,
  allRequirementsMet,
  setOldPasswordHelperText,
  findStrengthValueString,
  handleSubmit
};