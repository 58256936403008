import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useMemo} from "react";
import {t} from "i18next";
import Typography from "@mui/material/Typography";
import {useAtom} from "jotai";
import {NavigateFunction} from "react-router";
import {GlobalContextType} from "../../../../contexts/types/global";
import {ProfileInfoType} from "../../../../contexts/types/myAccount";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel
} from "@mui/material";
import {profileInfoAtom} from "../../../../jotai-state-management/profile-global-state";
import {handleChangeEdit} from "../helpers/helpers";
import {PhoneNumbers} from "../profile";
import {DOBFields, NameFields} from "../textFields";

export type OneTimeEditProps = {
  setOpenOneTimeEditDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  openOneTimeEditDialogue: boolean,
  setLoadingPhoneNumberChange: React.Dispatch<React.SetStateAction<boolean>>,
  setPhoneEditState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  setPhoneEditInitialState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  updateProfileInfo: any,
  phone: any,
  updatePhone: any,
  phoneEditState: any,
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  nameFields: NameFields,
  setNameFields: React.Dispatch<React.SetStateAction<NameFields>>,
  theme: string,
  dobFields: DOBFields,
  nameMatch: boolean,
  setDisableDOBFields: React.Dispatch<React.SetStateAction<boolean>>
}

export default function OneTimeNameEditDialog(props: OneTimeEditProps) {
  const [profileInfo] = useAtom<ProfileInfoType>(profileInfoAtom);
  const {
    setOpenOneTimeEditDialogue,
    openOneTimeEditDialogue,
    setLoadingPhoneNumberChange,
    setPhoneEditState,
    setPhoneEditInitialState,
    updateProfileInfo,
    phone,
    updatePhone,
    phoneEditState,
    navigate,
    setGlobalContext,
    enqueueSnackbar,
    nameFields,
    setNameFields,
    theme,
    dobFields,
    nameMatch,
    setDisableDOBFields
  } = props;

  const dobHasChanged: boolean = useMemo(() => {
    const profileInfoDobHasData: boolean = Boolean(
      profileInfo.dateOfBirth?.day ||
      profileInfo.dateOfBirth?.month ||
      profileInfo.dateOfBirth?.year
    );
    return !profileInfoDobHasData && Boolean(dobFields?.dobYear && dobFields?.dobDay && dobFields?.dobMonth);
  }, [dobFields]);

  const handleClose = () => {
    setOpenOneTimeEditDialogue(false);
  };

  return (
    <Dialog
      open={openOneTimeEditDialogue}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {dobHasChanged ?
        <DialogTitle id="alert-dialog-title">
          {'One-time Name and Date of Birth change'}
        </DialogTitle> :
        <DialogTitle id="alert-dialog-title">
          {'One-time Name change'}
        </DialogTitle>
      }
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{fontSize: 16,}}>
          For security, you are only allowed to change this information once.
          Please review your changes before saving.
        </DialogContentText>
        {(!nameMatch || dobHasChanged) &&
          <div className="dialogue-font-one-time-edit">
            <div className="names-one-time-fields">
              <InputLabel className="inputLabelDialogue">{t('NEW FIRST NAME')}</InputLabel>
              <Typography sx={{fontSize: 16,}}>{nameFields.firstName}</Typography>
            </div>
            <div className="names-one-time-fields">
              <InputLabel className="inputLabelDialogue">{t('NEW LAST NAME')}</InputLabel>
              <Typography sx={{fontSize: 16}}>{nameFields.lastName}</Typography>
            </div>
          </div>
        }
        {dobHasChanged &&
          <>
            <InputLabel required className="inputLabelDialogueDob">Date of Birth</InputLabel>
            <Grid
              container rowSpacing={2}
              columnSpacing={{md: 5, lg: 5}}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InputLabel required className="inputLabel">Year</InputLabel>
                <TextField
                  id="outlined-basic"
                  disabled
                  // label="Home Phone"
                  variant="outlined"
                  className="fullWidth"
                  inputProps={{
                    maxLength: 12,
                    'aria-label': 'Year'
                  }}
                  // @ts-ignore
                  FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                  value={dobFields.dobYear}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InputLabel required className="inputLabel">Month</InputLabel>
                <TextField
                  id="outlined-basic"
                  disabled
                  // label="Home Phone"
                  variant="outlined"
                  className="fullWidth"
                  inputProps={{
                    maxLength: 12,
                    'aria-label': 'Month'
                  }}
                  // @ts-ignore
                  FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                  value={dobFields.dobMonth}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <InputLabel required className="inputLabel">Day</InputLabel>
                <TextField
                  id="outlined-basic"
                  disabled
                  // label="Home Phone"
                  variant="outlined"
                  className="fullWidth"
                  inputProps={{
                    maxLength: 12,
                    'aria-label': 'Day'
                  }}
                  // @ts-ignore
                  FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                  value={dobFields.dobDay}
                />
              </Grid>
            </Grid>
          </>
        }
      </DialogContent>
      <DialogActions className="flexCenter">
        <div className="account-steps-nav">
          <Button
            className="primaryButtonDefault"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="cancelButtonDefault"
            onClick={() => {
              handleChangeEdit(
                setLoadingPhoneNumberChange,
                setPhoneEditState,
                setPhoneEditInitialState,
                updateProfileInfo,
                phone,
                updatePhone,
                phoneEditState,
                profileInfo,
                navigate,
                setGlobalContext,
                enqueueSnackbar,
                nameFields,
                setNameFields,
                setOpenOneTimeEditDialogue,
                true,
                theme,
                dobFields,
                setDisableDOBFields
              )
              handleClose();
            }}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}