// https://www.robinwieruch.de/react-router-private-routes/
import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {protectedRouteProps} from '../../../App';
import {CLIENT_ID} from '../../../util/constants';

export const ProtectedRoute = (props: protectedRouteProps) => {
  const { setGoto, children } = props;
  if (!JSON.parse(localStorage.getItem(CLIENT_ID))) {
    const goto: string = '/?goto=' + useLocation().pathname;
    setGoto(useLocation().pathname); // properly set the new goto param in the apps state
    return <Navigate to={goto} replace/>;
  }
  return children;
};