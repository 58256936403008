import {Link, Typography} from "@mui/material";
import {Base64} from 'js-base64';
import {NavigateFunction} from "react-router";
import {CompleteVerifyEmailResponseType, jwtInfo, validateCard} from '../../../network-requests/register';
import {RegInfoType, RegistrationPagesEnum} from '../../../contexts/registration';
import React from 'react';
import {TFunction} from 'i18next';
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../../util/constants";
import {moreRewardsActivationCodeValidator, moreRewardsCardValidator} from '../../../util/validator';
import pushRouteChangeEvent, {pushSpecificEvent} from '../../../util/google-tag-manager';
import {displayCustomError} from "../../../components/notifications/errors/display-custom-error";

const startUp = async (
  registrationContext: RegInfoType,
  setStartUpDone: React.Dispatch<React.SetStateAction<boolean>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  setAlreadyRegistered: React.Dispatch<React.SetStateAction<boolean>>,
  setMoreRewardsCard: React.Dispatch<React.SetStateAction<string>>,
  setMrIsPrefilled: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  t: TFunction,
  navigate: NavigateFunction,
  theme: string
) => {
  if (!registrationContext.jwt) {
    enqueueSnackbar('No email token found in url', { variant: 'error' });
    pushSpecificEvent("Register: CreateAccountPage - INVALID JWT");
    setStartUpDone(true);
    return navigate('/register'); // punt user back to general reg page on error
  }
  try {
    // validate jwt before sending a request to microservice
    let sections = registrationContext.jwt.split('.');
    if (sections.length < 2) {
      enqueueSnackbar(t('ERRORS.NO_EMAIL_FOUND', { variant: 'error' }));
      pushSpecificEvent("Register: CreateAccountPage - INVALID JWT");
      setStartUpDone(true);
      return navigate('/register'); // punt user back to general reg page on error
    }
    let j = JSON.parse(Base64.decode(sections[1]));
    if (j.exp === null) {
      enqueueSnackbar(t('ERRORS.INVALID_REQUEST', { variant: 'error' }));
      pushSpecificEvent("Register: CreateAccountPage - INVALID JWT");
      setStartUpDone(true);
      return navigate('/register'); // punt user back to general reg page on error
    }
    if (Math.floor(Date.now() / 1000) > j.exp) {
      enqueueSnackbar(
        <>
          <Typography
            sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
            This link has expired. Please enter your email below to try again. The new link will expire in 24 hours.
          </Typography>
        </>,
        { variant: 'error' }
      );
      setStartUpDone(true);
      return navigate('/register/email-expired');
    }

    // get jwtInformation
    let r = await jwtInfo(registrationContext.jwt);
    if (r.status !== 200) {
      pushSpecificEvent("Register: CreateAccountPage - FAILED");
      // 406 old token, error from gql
      if (r.status === 406) {
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          currentPage: RegistrationPagesEnum.VerifyEmailExpiredPage
        }));
        setStartUpDone(true);
        return navigate('/register/email-expired');
      } else if (r.status === 423) {
        setAlreadyRegistered(true);
      } else {
        // unexpected error - more helpful for user
        r.text().then(msg => enqueueSnackbar(
          <>
            <Typography
              sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
              An unexpected error occurred. Please try again. If this issue persists please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white"
              >contact us
              </Link>
            </Typography>
          </>,
          { variant: 'error' }
        ));
      }
      // correct response
    } else {
      let completeVerifyData = await r.json() as CompleteVerifyEmailResponseType;
      pushEvent(registrationContext.jwt);
      pushSpecificEvent("Register: CreateAccountPage - SUCCESS");
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        email: completeVerifyData.email,
        moreRewardsCard: completeVerifyData.moreRewardsNumber || ''
      }));
      if (completeVerifyData.moreRewardsNumber) {
        setMoreRewardsCard(completeVerifyData.moreRewardsNumber || '');
        setMrIsPrefilled(true)
      }
    }
    // catch error if api down
  } catch (e) {
    pushSpecificEvent("Register: CreateAccountPage - FAILED");
    enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
  }
  setStartUpDone(true);
};

const pushEvent = (jwt: string) => {
  let sections = jwt.split('.');
  let j = JSON.parse(Base64.decode(sections[1]));
  if (j.source === 'Card Distribution Welcome Email') {
    pushRouteChangeEvent('Register: CreateAccountPage - Card Distribution Welcome Email');
  } else if (j.source === 'Card Distribution 30 Days') {
    pushRouteChangeEvent('Register: CreateAccountPage - Card Distribution 30 Days Reminder');
  } else if (j.source === 'Card Distribution 60 Days') {
    pushRouteChangeEvent('Register: CreateAccountPage - Card Distribution 60 Days Reminder');
  } else {
    if (j.actionId == 2 || j.actionId == 9) {
      pushRouteChangeEvent('Register: CreateAccountPage - Registration');
    } else if (j.actionId == 7 || j.actionId == 11) {
      pushRouteChangeEvent('Register: CreateAccountPage - Forgot Password');
    } else {
      pushRouteChangeEvent('Register: CreateAccountPage - No information');
    }
  }
}

const handleSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  needMrCard: boolean,
  setMoreRewardsCardHelperText: React.Dispatch<React.SetStateAction<string>>,
  moreRewardsCard: string,
  setMoreRewardsActivationCodeHelperText: React.Dispatch<React.SetStateAction<string>>,
  moreRewardsActivationCode: string,
  enqueueSnackbar: any,
  t: TFunction,
  theme: string
) => {
  event.preventDefault();
  let inputError = false;
  if (!needMrCard) {
    if (moreRewardsCardValidator(moreRewardsCard, t).length !== 0) {
      setMoreRewardsCardHelperText(moreRewardsCardValidator(moreRewardsCard, t));
      inputError = true;
    }
    if (moreRewardsActivationCodeValidator(moreRewardsActivationCode, t).length !== 0) {
      setMoreRewardsActivationCodeHelperText(moreRewardsActivationCodeValidator(moreRewardsActivationCode, t));
      inputError = true;
    }
    if (inputError) {
      return;
    }
    try {
      pushSpecificEvent("Register: Clicked ValidateCard");
      let response: Response = await validateCard(registrationContext.jwt, moreRewardsCard, moreRewardsActivationCode);
      if (response.status !== 200) {
        pushSpecificEvent("Register: CardValidation - FAILED");
        return response.text().then(msg => displayCustomError(enqueueSnackbar, theme, msg));
      }
      let validateCardResponse = await response.json();
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        jwt: validateCardResponse.jwt
      }));
    } catch (e) { // catch error if api down
      enqueueSnackbar(t('ERRORS.API_OFFLINE', { variant: 'error' }));
      return;
    }
    setRegistrationContext((prev: RegInfoType) => ({
      ...prev,
      currentPage: RegistrationPagesEnum.SecurityQuestionsPage,
      moreRewardsCard: moreRewardsCard,
      moreRewardsActivationCode: moreRewardsActivationCode,
      needMrCard: needMrCard
    }));
    // needs card
  } else {
    setRegistrationContext((prev: RegInfoType) => ({
      ...prev,
      currentPage: RegistrationPagesEnum.ValidationPage,
      needMrCard: needMrCard
    }));
  }
};

export {startUp, handleSubmit}