import React from 'react';
import {APP_URL, CLIENT_ID, LINK_MR_OFFERS} from "../../util/constants";
import {Navigate, useLocation} from 'react-router-dom';
import {HandlePridProps} from "../../App";
import {OAuth2Tokens} from "../../network-requests/network-request-types";
import {TokenManager} from "@forgerock/javascript-sdk";
import {checkTokens} from "../../network-requests/authentication";
import {getJWTJsonPayload} from "../../util/jwt";
import {setDataAnalMetaData} from "../../util/google-tag-manager";
import Cookies from "js-cookie";
import {getUrlPath} from "./helpers/helpers"

export default function HandlePrid (props: HandlePridProps) {
    const {goto, setGoTo } = props;
    const oAuthToken = JSON.parse(localStorage.getItem(CLIENT_ID))
    const pridCookie = Cookies.get('PRID')
    const gotoUrlDomain = getUrlPath(goto);
    if (!oAuthToken) {
        window.location.replace(`${APP_URL}?goto=${encodeURIComponent(goto)}`);
    }
    if (oAuthToken && !pridCookie) {
        setPridCookie().then( () => {
            if (gotoUrlDomain == LINK_MR_OFFERS) {
                document.location = goto;
            }
        });
    }
    return <Navigate to='/userPage/home' replace/>;
}
const setPridCookie = async () => {
    const tokens: OAuth2Tokens | void = await TokenManager.getTokens();
    await checkTokens(tokens);
    // @ts-ignore
    let jwtInfo: any = getJWTJsonPayload(tokens.accessToken);
    setDataAnalMetaData({ prid: jwtInfo.publicReferenceId });
    Cookies.set('PRID', jwtInfo.publicReferenceId, {
        path: '/',
        domain: '.morerewards.ca',
        expires: 365,
        sameSite: 'None',
        secure: true
    });
}
