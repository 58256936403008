import React, {createContext, useState} from 'react';
import {GlobalContextProviderProps, GlobalContextProviderType, GlobalContextType} from './types/global';

// @ts-ignore
const GlobalContext = createContext<GlobalContextProviderType>(undefined);

function GlobalContextProvider({ children }: GlobalContextProviderProps) {
  const [globalContext, setGlobalContext] = useState<GlobalContextType>({
    SSOAuthenticated: null,
    bannerRefer: '',
    theme: null,
    oAuthStep: null,
    sessionTimedOut: false,
    appBarPageSelected: '',
    referer: '',
    loginInformation: {
      email: ''
    },
    isAuth: null,
    maintenanceMessage: null
  });

  return (
    <GlobalContext.Provider value={{ globalContext, setGlobalContext }}>
      {children}
    </GlobalContext.Provider>
  );
}

export {GlobalContext, GlobalContextProvider};
