import {Typography} from "@mui/material";
import React from "react";

const handleProdUrlClick = (prodUrl: string): void => {
  window.location.href = prodUrl;
}

export const generateAttentionMessage = (productionUrl: string): JSX.Element => {
  return (
    <>
      <Typography sx={{
        marginRight: '5px',
        marginLeft: '5px',
        cursor: 'pointer'
      }}>
        Oops, something is wrong. You're in the developer testing site.
        To fix it, sign in with your More Rewards ID at:{" "}
        <span
          style={{textDecoration: "underline", fontWeight: "bold"}}
          onClick={() => handleProdUrlClick(productionUrl)}
        >
        {productionUrl}
      </span>
      </Typography>
    </>
  )
}