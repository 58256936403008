// Format phone numbers with dashes on the frontend
const formatPhoneNumber = (phoneValue: string): string => {
  if (!phoneValue) {
    return phoneValue;
  }
  const phoneNumber: string = phoneValue.replace(/[^\d]/g, '');
  const phoneNumberLength: number = phoneNumber.length;
  if (phoneNumberLength < 4) {
    return phoneNumber;
  }
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, phoneNumberLength)}`;
};

const removeDashesPhoneNumber = (phoneValue: string): string => {
  return phoneValue.replace(/[^\d]/g, '');
}

export {formatPhoneNumber, removeDashesPhoneNumber};