export const moreRewardsTheme = {
  palette: {
    primary: {
      main: '#1976d2',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#1976d2'
    },
    success: {
      main: '#4caf50'
    },
    warning: {
      main: '#ff9800'
    },
    error: {
      main: '#ef5350'
    }
  }
};