import {Base64} from "js-base64";
import React from "react";
import {NavigateFunction} from "react-router";
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../util/constants";
import {emailValidator} from "../../util/validator";
import {
  CompleteVerifyEmailResponseType,
  forgotPasswordChange,
  jwtInfo,
  sendForgotPasswordEmail
} from "../../network-requests/register";
import {RegInfoType} from "../../contexts/registration";
import {TFunction} from "i18next";
import {PasswordInfo} from "../my-account/change-password/changePasswordTypes";
import {Link, Typography} from "@mui/material";
import {pushSpecificEvent} from "../../util/google-tag-manager";

const handleSubmitForgotPassword = (
  event: React.FormEvent<HTMLFormElement>,
  email: string,
  setEmailHelperText: React.Dispatch<React.SetStateAction<string>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  setEmailIsSent: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  t: TFunction,
  theme: string
) => {
  event.preventDefault();
  // check email is valid if not stop process
  if (emailValidator(email, t).length !== 0) {
    setEmailHelperText(emailValidator(email, t));
    return;
  }
  // setEmailIsSent to disable submit button
  setEmailIsSent(true);
  sendForgotPasswordEmail(email, theme).then((r: Response) => {
    // if not successful display error response and stop
    if (r.status !== 200) {
      r.text().then(msg => enqueueSnackbar(msg, { variant: 'error' }));
      return;
    }
    pushSpecificEvent("Register: ForgotPasswordPage - Email Sent");
    setRegistrationContext((prev: RegInfoType) => ({ ...prev, email: email }));
    enqueueSnackbar(
      <div className="flexMessage">
        <Typography sx={{ marginRight: '10px', cursor: 'pointer' }}>
          An email has been sent to:&nbsp;<Link className="link-white">{email}</Link>
        </Typography>
        <Typography sx={{ marginTop: '10px' }}>
          Didn’t receive an email? Make sure you entered your email correctly and check your Junk mail.
        </Typography>
        <Typography sx={{ marginTop: '10px' }}>
          Still no email? Please&nbsp;
          <Link
            href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
            target="_blank"
            className="link-white"
          >contact us
          </Link>.
        </Typography>
      </div>,
      { variant: 'success' }
    );
  }).catch(() => enqueueSnackbar(t('ERRORS.API_OFFLINE', { variant: 'error' })));
};

const handleSubmitChangePassword = async (
  event: React.FormEvent<HTMLFormElement>,
  passwordInfo: PasswordInfo,
  setConfirmPasswordHelperText: React.Dispatch<React.SetStateAction<string>>,
  registrationContext: RegInfoType,
  setPasswordHelperText: React.Dispatch<React.SetStateAction<string>>,
  navigate: NavigateFunction,
  enqueueSnackbar: any,
  t: TFunction,
  setPasswordChanged: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault();
  let inputError: boolean = false;
  if (!passwordInfo.confirm.input || passwordInfo.confirm.input !== passwordInfo.newPassword.input) {
    setConfirmPasswordHelperText('Passwords do not match');
    inputError = true;
  }
  if (!passwordInfo.allRequirementsMet) {
    setPasswordHelperText('Not strong enough');
    inputError = true;
  }
  if (inputError) {
    return;
  }
  setPasswordChanged(true);
  try {
    let response: Response = await forgotPasswordChange(
      registrationContext.jwt,
      passwordInfo.newPassword.input
    );
    if (response.status !== 200) {
      response.text().then(msg => enqueueSnackbar(msg, { variant: 'error' }));
    } else {
      navigate('/forgot-password/success');
    }
    // catch error if api down
  } catch (e) {
    enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
  }
};

const startUpChangePassword = async (
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  setStartUpDone: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  enqueueSnackbar: any,
  t: TFunction,
  theme: string
) => {
  if (!registrationContext.jwt) {
    enqueueSnackbar('No email token found in url', { variant: 'error' });
    setStartUpDone(true);
    return navigate('/');
  }
  try {
    // validate jwt before sending a request to microservice
    let sections: string[] = registrationContext.jwt.split('.');
    if (sections.length < 2) {
      navigate('/forgot-password/email-expired');
      return;
    }
    let jwt = JSON.parse(Base64.decode(sections[1]));
    if (jwt.exp === null) {
      navigate('/forgot-password/email-expired');
      return;
    }
    if (Math.floor(Date.now() / 1000) > jwt.exp) {
      navigate('/forgot-password/email-expired');
      return;
    }
    let response: Response = await jwtInfo(registrationContext.jwt);
    if (response.status !== 200) {
      // 406 old token, error from gql
      if (response.status === 406) {
        navigate('/forgot-password/email-expired');
      } else {
        // unexpected error - more helpful error message for customer
        response.text().then(msg => enqueueSnackbar(
          <>
            <Typography
              sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
              An unexpected error occurred. Please try again. If this issue persists please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white link-choices"
              >
                contact us
              </Link>
            </Typography>
          </>,
          { variant: 'error' }
        ));
      }
      // correct response
    } else {
      let completeVerifyData: CompleteVerifyEmailResponseType = await response.json();
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        email: completeVerifyData.email
      }));
    }
    // catch error if api down
  } catch (e) {
    enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
  }
  setStartUpDone(true);
};

export {handleSubmitForgotPassword, handleSubmitChangePassword, startUpChangePassword}