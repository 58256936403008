import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {handleDelete} from "../edit-address-dialogue/editAddressHelpers";
import {useAtom} from "jotai";
import {DeleteDialogProps} from "../addressesTypes";
import {ProfileInfoType} from "../../../../contexts/types/myAccount";
import {profileInfoAtom} from "../../../../jotai-state-management/profile-global-state";

export default function DeleteAddressDialog(props: DeleteDialogProps) {
  const [profileInfo] = useAtom<ProfileInfoType>(profileInfoAtom);
  const {
    openDialogue,
    setOpenDialogue,
    addressToDelete,
    setAddressToDelete,
    loadingDelete,
    setLoadingDelete,
    setOpenDeleteDialogue,
    enqueueSnackbar,
    setOpen,
    setAddressToEdit,
    setAddressBookDivs,
    theme,
    setLoadingBillingShippingUpdate
  } = props;

  const handleClose = () => {
    setOpenDialogue(false);
    setAddressToDelete(null);
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Are you sure you want to delete this address?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will not be able to undo this change
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flexCenter">
        <div className="account-steps-nav-cancel">
          <Button
            className="primaryButtonDefault"
            onClick={handleClose}
            disabled={loadingDelete}
          >
            Cancel
          </Button>
          {loadingDelete
            ?
            <LoadingButton
              disabled
              loading
              className="cancelButtonDefault"
              loadingPosition="start"
              startIcon={<SaveIcon/>}
              variant="outlined"
            >
              Deleting...
            </LoadingButton>
            :
            <Button
              autoFocus
              className="cancelButtonDefault"
              onClick={() => handleDelete(
                addressToDelete,
                setLoadingDelete,
                profileInfo,
                setOpenDeleteDialogue,
                setAddressToDelete,
                enqueueSnackbar,
                setOpen,
                setAddressToEdit,
                setAddressBookDivs,
                theme,
                setLoadingBillingShippingUpdate
              )}
            >
              Delete
            </Button>
          }
        </div>
      </DialogActions>
    </Dialog>
  )
}