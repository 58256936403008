import React, {useContext} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {RegInfoType, RegistrationPagesEnum} from "../../contexts/registration";
import {handleThemeRedirection} from "../../pages/my-account/myAccountHelpers";
import {GlobalContext} from "../../contexts/global";
import {useNavigate} from "react-router-dom";

export type AlertDialogProps = {
  openDialogue: boolean,
  setOpenDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  theme: string
}

export default function AlertDialog(props: AlertDialogProps) {
  const { globalContext } = useContext(GlobalContext);
  const navigate = useNavigate();
  const {openDialogue, setOpenDialogue, setRegistrationContext, theme} = props;
  const handleExit = async () => {
    setRegistrationContext((prev: RegInfoType) => ({
      ...prev,
      currentPage: RegistrationPagesEnum.VerifyEmailPage
    }));
    setOpenDialogue(false);
    if (globalContext.isWebview) { // Return to login screen for mobile web view
      return navigate('/');
    }
    await handleThemeRedirection(theme, globalContext.trafficType); // AMN-997 fix
  };
  const handleClose = () => {
    setOpenDialogue(false);
  };

  return (
    <Dialog
      open={openDialogue}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Are you sure you want to exit?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Leaving will require you to start over the registration process.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flexCenter">
        <div className="account-steps-nav-cancel">
          <Button
            onClick={handleExit}
            className="cancelButtonDefault"
          >
            Leave
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            className="primaryButtonDefault"
          >
            Stay
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}
