import React from 'react';
import {pushSpecificEvent} from "../../util/google-tag-manager";
import {
  LINK_MR,
  LINK_MR_DONE,
  LINK_MR_TRAVEL,
  LINK_MY_OFFERS,
  LINK_PSF,
  LINK_SOF,
  LINK_UF,
  LINK_QF
} from "../../util/constants";

const Partners = () => {
  return (
    <div className="partners">
      <a href={LINK_SOF} target="_blank" onClick={() => {
        pushSpecificEvent('nav \'Our Partners\' link clicked: Save-On-Foods');
      }}>Save-On-Foods</a>
      <a href={LINK_UF} target="_blank" onClick={() => {
        pushSpecificEvent('nav \'Our Partners\' link clicked: Urban Fare');
      }}>Urban Fare</a>
      <a href={LINK_PSF} target="_blank" onClick={() => {
        pushSpecificEvent('nav \'Our Partners\' link clicked: PriceSmart Foods');
      }}>PriceSmart Foods</a>
      <a href={LINK_QF} target="_blank" onClick={() => {
        pushSpecificEvent('nav \'Our Partners\' link clicked: Quality Foods');
      }}>Quality Foods</a>
      <a href={LINK_MR} target="_blank" onClick={() => {
        window.location.replace(`${LINK_MR_DONE}`);
      }}>More Rewards</a>
      <a href={LINK_MY_OFFERS} target="_blank" onClick={() => {
        pushSpecificEvent('nav \'Our Partners\' link clicked: My Offers');
      }}>My Offers</a>
      <a href={LINK_MR_TRAVEL} target="_blank" onClick={() => {}}>More Rewards Travel</a>
    </div>
  );
};

export default Partners;