import React, {useContext} from 'react';
import PageFooter from '../../components/footer/footer';
import {GlobalContext} from '../../contexts/global';
import {LINK_MR} from "../../util/constants";
import Link from "@mui/material/Link";
import {useNavigate} from 'react-router-dom';
import {Box} from "@mui/system";

export type CallbackPage = {
  theme: string
}

export default function CallbackPage(props: CallbackPage) {
  const { theme } = props;
  const { globalContext } = useContext(GlobalContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="root">
        <div className="page">
          <img
            className="center-img pointer"
            src={globalContext.referer === 'choices' ?
              require('../../images/choices/mobile-logo-choices.png') :
              require('../../images/global-logos/more-rewards.png')
            }
            onClick={() => window.open(`${LINK_MR}`, '_blank')}
            alt="more rewards"
          />
          <h2 className="topHeader marginB40"/>
            <Box className="registerBox">
                <div className="registerLink">
                    Already a member?
                    <Link className="link-yellow" onClick={() => navigate('/')}>
                        Login
                    </Link>
                </div>
            </Box>
        </div>
      </div>
      <div>
        <PageFooter isLogin={false} theme={theme}/>
      </div>
    </>
  )
}

