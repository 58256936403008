import React, {useEffect, useState} from 'react';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import PageFooter from '../../components/footer/footer';
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES, LINK_MR, MR_APP_REDIRECT_URI} from "../../util/constants";
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export type FiveHundredPageProps = {
  theme: string
}

export default function ErrorPage(props: FiveHundredPageProps) {
  const { theme } = props;
  const [redirectTime, setRedirectTime] = useState(10);
  const navigate = useNavigate();
  const {t, ready} = useTranslation();
  let referer = localStorage.getItem('data-analytics-banner');
  let isWebview = (localStorage.getItem('data-analytics-trafficType') && localStorage.getItem('data-analytics-trafficType') == 'app');
  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (isWebview && referer == 'morerewards') {
      window.location.replace(MR_APP_REDIRECT_URI);
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    pushRouteChangeEvent('500: Error');
  }, []);

  useEffect(() => {
    if (redirectTime <= 0) {
      if (isWebview && referer == 'morerewards') {
        window.location.replace(MR_APP_REDIRECT_URI);
      } else {
        navigate('/');
      }
    } else {
      setTimeout(() => setRedirectTime(redirectTime - 1), 1000);
    }
  }, [redirectTime]);

  return (
    <>
      <div className="root">
        <div className="page">
          <img
            className="center-img pointer"
            src={theme === 'choices' || referer === 'choices' ?
              require('../../images/choices/mobile-logo-choices.png') :
              require('../../images/global-logos/more-rewards.png')
            }
            onClick={() => window.open(`${LINK_MR}`, '_blank')}
            alt="more rewards"
          />
          <h2 className="topHeader marginB40">OOPS, SOMETHING WENT WRONG</h2>
          <p className={theme === 'choices' || referer === 'choices' ? "errorMessage500Choices" : "errorMessage500"}>
            Sorry, we ran into some trouble loading this page, please go back and try again.
            If the issue persists please&nbsp;
            <a
              href={theme === 'choices' || referer === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
              className={theme === 'choices' || referer === 'choices' ? "errorMessage500Choices" : "errorMessage500"}
              target='blank'>contact us
            </a>.
          </p>
          <div className="center-text">
            <Link onClick={() => handleSubmit()} className="clickHere link-white link-choices">TRY AGAIN NOW</Link>
          </div>
          <Typography variant="body2" component="p" className="youWillBeRedirected">
            {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.REDIRECTED_IN', {redirectTime})}
          </Typography>
        </div>
      </div>
      <div>
        <PageFooter isLogin={false} theme={theme || referer}/>
      </div>
    </>
  )
}

