import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import {checkboxOptions} from './checkboxOptions';
import {CheckboxState} from '../emailSubscriptionsTypes';
import {Checkboxes} from '../../../../contexts/types/myAccount';
import {Grid} from '@mui/material';
import '../emailSubscriptions.scss';

const generateCheckboxDivs = (
  setCheckboxDivs: React.Dispatch<React.SetStateAction<JSX.Element[]>>,
  handleCheckboxChange: Function,
  checkboxState: CheckboxState,
  setCheckboxes: React.Dispatch<React.SetStateAction<CheckboxState>>,
  loading: boolean,
  apiIsLoading: boolean | null
): void => {
  const arrayOfCheckBoxDivs: JSX.Element[] = [];
  checkboxOptions.forEach(checkboxInfo => {
    arrayOfCheckBoxDivs.push(
      <div className="checkBoxContainer" key={checkboxInfo.title}>
        <div className="checkboxAndText" key={checkboxInfo.title}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className="flex-container">
              {Object.entries(checkboxState.initialCheckboxesState).map(([key]) => {
                if (key === checkboxInfo.keyDescription) {
                  return (
                    <Checkbox
                      className="BrandsCheckBox"
                      key={checkboxInfo.keyDescription}
                      disabled={loading || apiIsLoading}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(
                        event, 
                        checkboxInfo.title, 
                        setCheckboxes
                      )}
                      checked={checkboxState.initialCheckboxesState[key as keyof Checkboxes] || false}
                      inputProps={{
                        'aria-label': checkboxInfo.title,
                      }}
                    />
                  );
                }
              })}
              <Typography className={apiIsLoading ? 'BrandsubApiLoading' : 'Brandsub'}>
                {checkboxInfo.title}
              </Typography></div>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Typography className="BrandsuboptionList">
              {checkboxInfo.youWillRecieve.first}
            </Typography>
          </Grid>
        </div>
      </div>
    );
  });
  setCheckboxDivs(arrayOfCheckBoxDivs);
};

export {generateCheckboxDivs};