import React from 'react';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './password-confirmation.scss';

const PasswordChangeConfirmation = () => {
  return (
    <div className="password-change-confirmation">
      <CheckCircleOutlineIcon className="checkmark-icon" />
      <Typography variant="h6" className="confirmation-text">
        Your password has been updated
      </Typography>
    </div>
  );
};

export default PasswordChangeConfirmation;