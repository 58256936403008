import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GlobalContextProvider} from './contexts/global';
import {Config} from '@forgerock/javascript-sdk';
import {BrowserRouter} from 'react-router-dom';
import SnackbarCloseButton from './components/snackbar-close/snackbar-close';
import {SnackbarProvider} from 'notistack';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import RegistrationContextProvider from "./contexts/registration";
import './index.css';

Config.set(
  {
    clientId: process.env.REACT_APP_WEB_OAUTH_CLIENT,
    redirectUri: `${process.env.REACT_APP_APP_URL}/callback`,
    scope: 'email',
    serverConfig: {
      baseUrl: process.env.REACT_APP_AM_URL,
      paths: {
        authenticate: 'json/morerewards/authenticate', // hard coded for testing
        authorize: 'oauth2/morerewards/authorize',
        accessToken: 'oauth2/morerewards/access_token',
        revoke: 'oauth2/morerewards/token/revoke',
        userInfo: 'oauth2/morerewards/userinfo',
        sessions: 'json/morerewards/sessions',
        endSession: 'oauth2/morerewards/connect/endSession'
      },
      timeout: 10000, // 10 second timeout in all sdk requests
    },
    realmPath: process.env.REACT_APP_REALM_PATH,
    tree: process.env.REACT_APP_JOURNEY_LOGIN
  }
);

// Create a React Query client
const queryClient = new QueryClient()

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <RegistrationContextProvider>
        <GlobalContextProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            preventDuplicate
            autoHideDuration={15000}
            action={key => <SnackbarCloseButton snackbarKey={key}/>}>
            <App/>
          </SnackbarProvider>
        </GlobalContextProvider>
      </RegistrationContextProvider>
    </QueryClientProvider>
  </BrowserRouter>, document.getElementById('root'));

/* If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
