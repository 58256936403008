import {Container} from '@mui/material';
import '../../styling/terms-and-conditions/terms.scss';
import {useEffect} from 'react';
import pushRouteChangeEvent from '../../util/google-tag-manager';

export const MoreRewardsTravelTermsConditions = () => {
  useEffect(() => {
    pushRouteChangeEvent('TOS: MoreRewardsTravelTermsConditions');
  }, []);
  return <Container className="MoreRewardsTravelTermsConditions">
    <div>
      <h4>More Rewards Travel Program Terms and Conditions</h4>
      <ul>
        <li>1. The More Rewards customer loyalty program (<b>“More Rewards”</b>) is administered by the Save-On-Foods
          Limited
          Partnership (“SOF”). The More Rewards Travel Program (the <b>“Travel Program”</b>) is a component of More
          Rewards and
          is provided by Merit Travel Group, an independent travel agency. Registration Numbers: ON.50010422/4499356,
          BC.34799, QC.7002238 (<b>“Merit”</b>).
        </li>
        <li>2. SOF operates the More Rewards website at <a
          href="https://www.morerewards.ca">www.morerewards.ca</a> (the <b>“Website”</b>).
        </li>
        <li>3. Merit will operate the Travel Program webpages within the Website, located at <a
          href="https://www.travel.morerewards.ca">www.travel.morerewards.ca</a>,
          as well as a telephone call centre (collectively the <b>“Travel Centre”</b>).
        </li>
        <li>4. <b>These More Rewards Travel Program Terms and Conditions are in addition to, and do not replace the
          general
          More Rewards Terms and Conditions. In the event of a conflict between these Travel Program Terms and those in
          the More Rewards program terms and conditions, the terms herein shall prevail.</b> The “More Rewards” name and
          design are trademarked property of SOF and may only be used by permission of SOF.
        </li>
        <li>5. These Travel Program Terms govern your Travel account and the collection and redemption of More Rewards
          Points within the Travel Program. Your More Rewards Terms also apply to the Travel Program.
        </li>
      </ul>
      <h4>Enrollment</h4>
      <ul>
        <li>6. To participate in the Travel Program, applicants must be a member of More Rewards (a <b>“Member”</b>).
          Membership
          in More Rewards is free and no initial purchase is required. Simply fill out a More Rewards application form
          and deliver it to any participating SOF store, apply at <a
            href="https://www.morerewards.ca/enroll">www.morerewards.ca/enroll</a> , or at any other location
          or process designated by SOF from time to time. <b>Please refer to the More Rewards Terms and Conditions for
            all
            More Rewards terms, excluding these Travel Program terms.</b></li>
        <li>7. If you use the Website to check your More Rewards Points balance or redeem More Rewards Points you will
          also be bound by the terms of use of the Website.
        </li>
        <li>8. Specific terms and conditions may apply to specific elements of the Travel Program and will be disclosed
          prior to any such redemption or purchase.
        </li>
        <li>9. Any travel products booked through the Travel Centre must be prepaid.</li>
      </ul>

      <h4>Redeeming More Rewards Points for Travel</h4>
      <ul>
        <li>10. More Rewards Points may be redeemed for up to 100% of the cost of any travel products booked through the
          Travel Centre. The portion of the cost made up by redemption is considered a <b>“Travel Reward”</b>.
        </li>
        <li>11. More Rewards Points may be exchanged for Travel Rewards at the rates determined by More Rewards from
          time
          to time. The number of More Rewards Points required for specific travel products and services may be changed
          at any time and without notice. When redeeming for Travel Rewards, the minimum number of More Rewards Points
          you may redeem is 100; there is no maximum redemption amount. If the cost of the travel services exceeds the
          value of Travel Rewards, the excess will be charged to your credit card (the <b>“Travel Purchase”</b>). Other
          charges
          on travel services, taxes, fees and the premiums for any insurance purchased through the Centre may be paid
          for by Travel Rewards or charged to your credit card.
        </li>
        <li>12. Some taxes and other charges may be collected locally when you are travelling, and cannot be prepaid.
          Please contact the Travel Centre for specific details.
        </li>
        <li>13. The travel services available through the Travel Centre will change from time to time and some types of
          travel services may not be available. Specific tours or services may be cancelled by travel service suppliers
          without prior notice. Suppliers may also change prices, travel itineraries, flight times and dates without
          prior notice.
        </li>
        <li>14. Non-refundable deposits and baggage restrictions may apply and vary by supplier. Please contact the
          Travel Centre for specific details.
        </li>
        <li>15. To redeem More Rewards Points for a Travel Reward your More Rewards Account must be active.</li>
        <li>16. The More Rewards Points balance displayed on the Website or known to the Travel Centre may not reflect
          very recent transactions (including purchase refunds and More Rewards Points redemptions) that have not yet
          been posted to your More Rewards account. If you have questions or concerns about your current More Rewards
          Account Points balance, you can call More Rewards at 1-800-242-9229.
        </li>
        <li>17. More Rewards Points may be redeemed for Travel Rewards by the primary More Rewards Member and any users
          authorized pursuant to the More Rewards Terms and Conditions.
        </li>
        <li>18. If you receive credit for a Travel Reward because it appeared to the Travel Centre that you had
          sufficient More Rewards Points at the time you requested the Travel Reward, but you in fact did not, you
          hereby authorize the Travel Centre, regardless of the reason for the More Rewards Points deficiency, to charge
          to your credit card the dollar value of the Travel Reward as if it had been a Travel Purchase.
        </li>
        <li>19. Travel Rewards are displayed on the Website and in any additional materials as made available from time
          to time. All Travel Rewards are subject to availability.
        </li>
        <li>20. Travel Rewards must be ordered through the Travel Centre. Travel Rewards cannot be applied against
          existing reservations, to purchases previously made, or for products or services not ordered/booked through
          the Travel Centre.
        </li>
      </ul>

      <h4>Earning More Rewards Points on Travel Purchases</h4>
      <ul>
        <li>21. One More Rewards Point will be awarded for each $1.00 (CAD) in net Travel Purchases from the Travel
          Centre.
          Points will not be awarded for partial dollars spent on a Travel Purchase. More Rewards Points are awarded on
          net Travel Purchases, less returns, and for clarity will not be awarded on taxes, fees or the amount paid for
          by
          a Travel Reward.
        </li>
        <li>22. Return of or refund for any Travel Purchases will result in the deduction of the applicable More Rewards
          Points earned on said Travel Purchase at the same rate they were earned.
        </li>
      </ul>
      <h4>More About More Rewards Travel Rewards</h4>
      <ul>
        <li>21. You are responsible for any personal expenses and for the cost of any services not expressly paid with a
          Travel Reward or with travel or other services purchased through the Travel Centre, including travel
          insurance.
        </li>
        <li>22. Once ordered, Travel Rewards are non-refundable, non-exchangeable and non-transferable unless you are
          advised otherwise.
        </li>
        <li>23. Any travel product which is packaged for two people requires double occupancy and joint travel and
          participation (as applicable).
        </li>
        <li>24. Travel products and services may be changed or substituted and the number of More Rewards Points
          required
          for specific travel products and services may be changed at any time and without notice.
        </li>
        <li>25. You and/or your guests (if applicable) are solely responsible for obtaining required travel
          documentation.
          Without appropriate documentation you may be refused boarding/entry/access.
        </li>
        <li>26. Specific terms and conditions may apply to each travel offering, and vary by supplier. Travel dates may
          be
          restricted during some periods of the year.
        </li>
      </ul>
      <h4>More About the Program</h4>
      <ul>
        <li>27. SOF, More Rewards, Merit, Travel Centre and their respective agents are not liable or responsible for
          any
          loss, injury, death, cost, damage, liability or expense you or your family members or guests may suffer or
          incur, and you release and discharge SOF, More Rewards, Merit, Travel Centre and their respective agents from
          any claims, demands, causes of action and actions which may arise, in connection with any:
        </li>
        <ul>
          <li>a. error or omission on the Website or other sources of information about the Travel Program and any
            specific travel products;
          </li>
          <li>b. error or delay in crediting or debiting your More Rewards Account;</li>
          <li>c. act or omission by the Travel Centre, including if the Travel Centre provides information which results
            in travel arrangements that have a higher cost than expected or indicated or which differ in any way from
            arrangements which may be available elsewhere;
          </li>
          <li>d. use of a Travel Reward, including while travelling on any travel paid with a Travel Reward; and</li>
          <li>e. act or omission of any travel supplier, including its failure to perform as expected or described or if
            travel services are substituted or changed in any way or cancelled in whole or part.
          </li>
          <li>This release binds your legal representatives, heirs, successors and assigns.</li>
        </ul>
        <li>28. We may amend, suspend or terminate all or any part of the Travel Program at any time.</li>
        <li>29. Independent third party service providers and suppliers may provide supplies and services for the
          Program, including the Centre. All travel services and Travel Centre operations are provided by Merit.
        </li>
        <li>30. These Travel Program Terms will be governed by and interpreted in accordance with Canadian law.</li>
        <li>31. Certain words or phrases used in these Travel Program terms have a special meanings as follows:</li>
      </ul>
      <p><b>"Account"</b> means the account in which More Rewards Points earned by a Member accumulate, based on Card
        purchases;<br/>
        <b>"Card"</b> means a More Rewards card;<br/>
        <b>"Member"</b> means a primary Cardholder or authorized user of a Card;<br/>
        <b>"including"</b> means "including, but not limited to";<br/>
        <b>"Points"</b> means the points awarded in the More Rewards program to Members as a result of purchases with a
        Card,
        which may be redeemed for various rewards including Travel Rewards;<br/>
        <b>"Taxes and Other Charges"</b> includes the following, when/as applicable: taxes of any kind, departure or
        arrival
        fees, security or other charges, fuel or other surcharges, fees for additional services, tips, port fees, or any
        other levy imposed by or with the authority of any government, airline or other third party;<br/>
        <b>"Travel Centre"</b> means the More Rewards Travel Program website and call centre operated by Merit, which
        provides
        travel and other services to Members related to the Travel Program;<br/>
        <b>"Travel Program"</b> means the More Rewards travel program operated by Merit;<br/>
        <b>“Travel Reward”</b> means the portion of travel costs paid for by redeeming Points.<br/>
        <b>"Travel Website"</b> means the website operated by the Travel Centre and which is located at <a
          href="https://www.travel.morerewards.ca">www.travel.morerewards.ca</a><br/>
        <b>"You"</b> means a primary Cardholder or Authorized User.</p>
    </div>
  </Container>;
};
export default MoreRewardsTravelTermsConditions;