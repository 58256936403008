import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {PasswordInfo, Weakness} from '../../pages/my-account/change-password/changePasswordTypes';
import React, {useEffect, useState} from 'react';
import {
  allRequirementsMet,
  determinePasswordStrength,
  findStrengthMeterColor,
  setOldPasswordHelperText
} from './helper-functions/helpers';
import {checkInputFields, passwordsMatch} from '../../util/validator';
import './passwordStrength.scss';

export type PasswordStrengthProps = {
  passwordInfo: PasswordInfo,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>,
  pageType: string
}

export const PasswordStrength = (props: PasswordStrengthProps) => {
  const {passwordInfo, setPasswordInfo, pageType} = props;

  useEffect(() => {
    if (pageType === 'Profile') {
      checkInputFields(passwordInfo, setPasswordInfo, 'password');
      passwordsMatch(passwordInfo.newPassword.input, passwordInfo.confirm.input, setPasswordInfo);
      setOldPasswordHelperText(passwordInfo, setPasswordInfo);
    }
    determinePasswordStrength(passwordInfo, setPasswordInfo);
  }, [passwordInfo.newPassword.input, passwordInfo.oldPassword.input, passwordInfo.confirm.input]);

  useEffect(() => {
    allRequirementsMet(passwordInfo.weaknesses, setPasswordInfo);
    findStrengthMeterColor(passwordInfo.passwordStrengthMeterValue, setPasswordInfo);
  }, [passwordInfo.passwordStrengthMeterValue]);

  return (
    <div className="passwordStrengthContainer">
      <Typography variant="body2" className="passwordStrengthHeading">Password
        Strength: <span>{passwordInfo.passwordStrengthInformation.strengthMeterText}</span></Typography>
      <div className="passwordInformation">
        <Box sx={{width: '85%', marginBottom: '15px'}}>
          <Stack sx={{width: '100%', color: 'grey.500'}} spacing={2}>
            <LinearProgress
              color={passwordInfo.passwordStrengthInformation.strengthMeterBarColor}
              variant="determinate"
              value={passwordInfo.passwordStrengthMeterValue}
              style={{height: '12px', borderRadius: 7, backgroundColor: '#e6e3e3', marginTop: '5px'}}
            />
          </Stack>
        </Box>
        {passwordInfo.warning && <Typography variant="body2" className="passwordContainsHeading warningMessage">Warning: {passwordInfo.warning}</Typography>}
        {passwordInfo.suggestions.length > 0 &&
          <div className="passwordContainsHeading">
            New password must be strong. <br/>Here are some tips to make a strong password:
            {passwordInfo.suggestions.map((weakness: string, index: number) => {
              return (
                <Typography
                  variant="body2"
                  key={index.toString()}
                  style={{color: 'black', fontSize: '14px', fontStyle: 'italic', marginBottom: '6px', marginTop: '10px'}}
                >
                  {weakness.replace(/\./g, '')}
                </Typography>
              );
            })}
          </div>}
      </div>
    </div>
  );
};