import React, {useContext, useEffect, useState} from 'react';
import {GlobalContext} from '../../contexts/global';
import {GlobalContextType} from '../../contexts/types/global';
import {getSessionReferer, setSessionRefer} from '../../util/referer';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/styles';
import {StyledEngineProvider} from '@mui/material';
import {moreRewardsTheme} from '../../styling/global/moreRewardsTheme';
import {urbanFareTheme} from '../../styling/global/urbanFareTheme';
import {priceSmartFoodsTheme} from '../../styling/global/priceSmartFoodsTheme';
import {choicesTheme} from '../../styling/global/choicesTheme';
import {sofTheme} from '../../styling/global/saveOnFoodsTheme';
import {qfTheme} from '../../styling/global/qualityFoodsTheme';
import {getBannerForDataLayer, getTrafficTypeFromUserClient, setDataAnalMetaData} from '../../util/google-tag-manager';

/**
 * Sets the theme and the banner throughout the app
 */
const MrCss = React.lazy(() => import('./themes/mr'));
const SofCss = React.lazy(() => import('./themes/sof'));
const ChoicesCss = React.lazy(() => import('./themes/choices'));
const UrbanCss = React.lazy(() => import('./themes/urbanfare'));
const PmfCss = React.lazy(() => import('./themes/pricesmartfoods'));
const QfCss = React.lazy(() => import('./themes/qf'));

// @ts-ignore
export const ThemeSelector = ({ children }) => {
  const { globalContext, setGlobalContext } = useContext(GlobalContext);
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  const [theme, setTheme] = useState({});
  const [themeCssComponent, setThemeCssComponent] = useState(<></>);
  const refererList = ['saveonfoods', 'morerewards', 'choices', 'urbanfare', 'pricesmartfoods', 'travel', 'qualityfoods'];

  // Sets global contexts referer property using url parameters if available and checks session storage if url changes during session
  useEffect(() => {
    // traffic type
    if (urlParams && urlParams.hasOwnProperty('traffic_type') && urlParams.traffic_type === 'app') {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        trafficType: 'app',
        isWebview: true
      }));
      sessionStorage.setItem('trafficType', urlParams.traffic_type);
    }
    // set default referer as morerewards.
    // maybe url changed, check if set in session storage
    if ((getSessionReferer() === undefined) || getSessionReferer() === 'undefined' || getSessionReferer() === null) {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        referer: 'morerewards'
      }));
      setSessionRefer('morerewards');
    } else {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        referer: getSessionReferer()
      }));
    }

    if (urlParams && urlParams.hasOwnProperty('referer') && refererList.includes(urlParams.referer)) {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        referer: urlParams.referer
      }));
      setSessionRefer(urlParams.referer);
    } else if (urlParams && urlParams.hasOwnProperty('spEntityID')) {
      refererList.forEach(ref => {
        if (urlParams.spEntityID.indexOf('myoffers') > -1) {
          setGlobalContext((prev: GlobalContextType) => ({
            ...prev,
            referer: 'myoffers'
          }));
          setSessionRefer('myoffers');
        } else if (urlParams.spEntityID.indexOf(ref) > -1) {
          setGlobalContext((prev: GlobalContextType) => ({
            ...prev,
            referer: ref
          }));
          setSessionRefer(ref);
        }
      })
    } else if (urlParams && urlParams.hasOwnProperty('goto')) {
      refererList.forEach(ref => {
        if (urlParams.goto.indexOf('QualityFoodsOAuth') > -1) {
          setGlobalContext((prev: GlobalContextType) => ({
            ...prev,
            referer: 'qualityfoods'
          }));
          setSessionRefer('qualityfoods');
        } else if (urlParams.goto.indexOf(ref) > -1) {
          setGlobalContext((prev: GlobalContextType) => ({
            ...prev,
            referer: ref
          }));
          setSessionRefer(ref);
        }
      })
    } else if (document.referrer !== undefined && document.referrer !== null) {
      refererList.forEach(ref => {
        if (document.referrer.indexOf(ref) > -1) {
          setGlobalContext((prev: GlobalContextType) => ({
            ...prev,
            referer: ref
          }));
          setSessionRefer(ref);
        }
      })
    }

    // @ts-ignore
    let standalone = window.navigator.standalone;
    let userAgent = window.navigator.userAgent.toLowerCase();
    let safari = /safari/.test(userAgent);
    let ios = /iphone|ipod|ipad/.test(userAgent);
    if (!navigator.userAgent) { // detect if there is no user agent (?)
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        isWebview: false
      }));
    } else if (userAgent.includes('wv') || (ios && !standalone && !safari)) { // detect android and iOS webview
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        isWebview: true
      }));
    } else { // detect user is on a browser (chrome, safari, edge etc...)
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        isWebview: false
      }));
    }

    // ios / other device
    if (ios) {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        isIos: true
      }));
    }

    // traffic type
    if (urlParams && urlParams.hasOwnProperty('traffic_type') && urlParams.traffic_type === 'app') {
      setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        trafficType: 'app',
        isWebview: true
      }));
      sessionStorage.setItem('trafficType', urlParams.traffic_type);
    }
  }, []);

  // Takes the global context referer property and sets theme and imports the css theme file
  useEffect(() => {
      let fav = document.getElementById("favicon");
      switch (globalContext.referer) {
        case 'saveonfoods':
          setTheme(sofTheme);
          setThemeCssComponent(<SofCss/>);
          break;
        case 'choices':
          setTheme(choicesTheme);
          setThemeCssComponent(<ChoicesCss/>);
          //@ts-ignore
          fav.href = '/files/choices_favicon.ico';
          break;
        case 'urbanfare':
          setTheme(urbanFareTheme);
          setThemeCssComponent(<UrbanCss/>);
          break;
        case 'pricesmartfoods':
          setTheme(priceSmartFoodsTheme);
          setThemeCssComponent(<PmfCss/>);
          break;
        case 'qualityfoods':
          setTheme(qfTheme);
          setThemeCssComponent(<QfCss/>);
          break;
        case 'morerewards':
          setTheme(moreRewardsTheme);
          setThemeCssComponent(<MrCss/>);
          //@ts-ignore
          fav.href = '/favicon32x32.ico';
      }
    }, [globalContext.referer]
  );

  // Set data analytics banner
  useEffect(() => {
    setDataAnalMetaData({
      banner: getBannerForDataLayer(),
      trafficType: getTrafficTypeFromUserClient()
    });
  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(theme)}>
          <React.Suspense fallback={<></>}>
            {themeCssComponent}
          </React.Suspense>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};