import {MyAccountType} from '../contexts/types/myAccount';
import {
  ALTERNATE_1_TYPE_CODE,
  ALTERNATE_2_TYPE_CODE,
  EMAIL_SUB_TYPE_FLYER_SPECIALS,
  EMAIL_SUB_TYPE_MR,
  EMAIL_SUB_TYPE_PSF_SPECIALS,
  EMAIL_SUB_TYPE_QF_SPECIALS,
  EMAIL_SUB_TYPE_SPECIAL_OFFERS_MEAL_PREP,
  EMAIL_SUB_TYPE_SURVEYS,
  EMAIL_SUB_TYPE_UF_SPECIALS,
  EMAIL_TYPE_CODE,
  PRIMARY_TYPE_CODE
} from "./constants";
import {ContactMethod, EmailSubscription, GraphQLResponse} from './types/utilTypes';

export type DateOfBirth = {
  day: string,
  month: string,
  year: string
}

export type Months = {
  '01': string,
  '02': string,
  '03': string,
  '04': string,
  '05': string,
  '06': string,
  '07': string,
  '08': string,
  '09': string,
  '10': string,
  '11': string,
  '12': string
}

// Helper function to convert date of birth
export const convertDateOfBirth = (dateOfBirth: string): DateOfBirth => {
  const months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
  };
  const words = dateOfBirth.split(/[ -]+/);
  return {
    day: words[2],
    month: months[words[1] as keyof Months],
    year: words[0]
  };
};

/* Converts GraphQLResponse to MyAccountType object myAccount Object
 * has hardcoded DOB, email subscription & points for now */
export const graphqlUserInfoToContext = (response: GraphQLResponse | void): MyAccountType => {
  if (response) {
    let myAccountData: MyAccountType = {
      email: null,
      firstName: null,
      lastName: null,
      primary: null,
      alternate1: null,
      province: null,
      homeStore: null,
      moreRewardsCard: null,
      points: null,
      dateOfBirth: null,
      dobString: null,
      publicReference: 0,
      nameChangeAttributes: null,
      emailSubscriptions: {
        moreRewardsProgram: false,
        saveOnFoodsFlyerSpecials: false,
        specialOffersMealPrep: false,
        urbanFareSpecials: false,
        priceSmartSpecials: false,
        qualityFoodsSpecials: false,
        surveys: false
      },
      addresses: [],
      linkedAccounts: null,
      googleJwt: '',
      contactMethods: null,
      biometricData: {
        deviceName: null
      }
    };

    // Populate first tine name change property
    myAccountData.nameChangeAttributes = response.data.customer.attributes;

    // Populate google pay JWT
    myAccountData.googleJwt = response.data.googlePayJwt;

    // Populate date of birth
    if (response.data.customer.dateOfBirth) {
      myAccountData.dateOfBirth = convertDateOfBirth(response.data.customer.dateOfBirth);
    }

    // Unconverted dob for updating one time name
    myAccountData.dobString = response.data.customer.dateOfBirth;

    // Populate points balance or an error message depending on what comes back from the API
    if (response.data.customer.pointsBalanceError) {
      myAccountData.points = response.data.customer.pointsBalanceError;
    } else {
      myAccountData.points = response.data.customer.pointsBalance;
    }

    // Populate mr card number + set first name and last name
    if (response.data.customer.identifications.length > 0) {
      myAccountData.moreRewardsCard = response.data.customer.identifications[0].idValue;
    } else {
      myAccountData.moreRewardsCard = 'No active More Rewards card on record';
    }

    myAccountData.firstName = response.data.customer.firstName;
    myAccountData.lastName = response.data.customer.lastName;
    myAccountData.linkedAccounts = response.data.customer.linkedAccounts;
    myAccountData.publicReference = response.data.customer.publicReference;

    // Populate addresses coming form MR side currently do not have a primary or alternate phone number
    myAccountData.addresses = response.data.customer.addresses;

    // Populate phone number and email
    const contactMethods: ContactMethod[] = response.data.customer.contactMethods;

    // Update contact methods in react app for phone numbers + email
    for (let contactMethod of contactMethods) {
      switch(contactMethod.typeCode) {
        case PRIMARY_TYPE_CODE:
          myAccountData.primary = contactMethod.value;
          break;
        case ALTERNATE_1_TYPE_CODE:
          myAccountData.alternate1 = contactMethod.value;
          break;
        case ALTERNATE_2_TYPE_CODE:
          myAccountData.alternate2 = contactMethod.value;
          break;
        case EMAIL_TYPE_CODE:
          myAccountData.email = contactMethod.value;
          break;
      }
    }

    // Handle null values
    if (!myAccountData.alternate1) {
      myAccountData.alternate1 = '';
    }
    if (!myAccountData.alternate2) {
      myAccountData.alternate2 = '';
    }
    if (!myAccountData.primary) {
      myAccountData.primary = '';
    }

    myAccountData.contactMethods = contactMethods;

    // Populate email subscriptions
    const emailSubscriptions: EmailSubscription[] = response.data.customer.emailSubscriptions;
    for (let emailSubscription of emailSubscriptions) {
      switch (emailSubscription.emailSubscriptionTypeId) {
        case EMAIL_SUB_TYPE_MR:
          myAccountData.emailSubscriptions.moreRewardsProgram = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_SPECIAL_OFFERS_MEAL_PREP:
          myAccountData.emailSubscriptions.specialOffersMealPrep = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_SURVEYS:
          myAccountData.emailSubscriptions.surveys = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_FLYER_SPECIALS:
          myAccountData.emailSubscriptions.saveOnFoodsFlyerSpecials = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_UF_SPECIALS:
          myAccountData.emailSubscriptions.urbanFareSpecials = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_PSF_SPECIALS:
          myAccountData.emailSubscriptions.priceSmartSpecials = emailSubscription.enabled;
          break;
        case EMAIL_SUB_TYPE_QF_SPECIALS:
          myAccountData.emailSubscriptions.qualityFoodsSpecials = emailSubscription.enabled;
      }
    }

    // biometric data
    myAccountData.biometricData = response.data.customer.biometricData;
    return myAccountData;
  }
};