import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';
import {GlobalContextType} from "../../contexts/types/global";
import './card-with-logo.scss'

type cardLogoProps = {
  children: JSX.Element,
  displayBannerLogo: boolean,
  globalContext: GlobalContextType
}

type CorrectBannerType = {
  globalContext: GlobalContextType
  displayBannerLogo: boolean,
}

// AMN-663 - display all relevant banner logos on the login + welcome page in the web view properly for Andres.
function CorrectBannerImage(props: CorrectBannerType) {
  const {globalContext, displayBannerLogo} = props;
  if (!displayBannerLogo) {
    return (
      <div className="logosContainerMobileWebView">
        {
          globalContext.referer == 'choices' ?
            <div className="mrContainerChoicesWebView">
              <img
                className="center-img"
                src={require('../../images/choices/logo-choices.png')}
                alt={'Choices logo'}
                width={'60%'}
              />
            </div>
            :
            <div className="mrContainer">
              <img
                src={require('../../images/global-logos/mr-id.png')}
                alt="More Rewards ID"
              />
            </div>
        }
      </div>
    );
  }
  switch (globalContext.referer) {
    case 'saveonfoods':
      return (
        <div className="logosContainer">
          <div className="banner-container">
            <img
              className="logoSof"
              src={require('../../images/sof/logo-sof.png')}
              alt={'Save-On-Foods'}
            />
          </div>
          <div className="mrContainer">
            <img
              className="logoMr"
              src={require('../../images/global-logos/mr-id.png')}
              alt="More Rewards ID"
            />
          </div>
        </div>
      )
    case 'urbanfare':
      return (
        <div className="logosContainer">
          <div className="banner-container">
            <img
              className="logoUf"
              src={require('../../images/uf/logo-uf.png')}
              alt={'Urban Fare'}
            />
          </div>
          <div className="mrContainer">
            <img
              src={require('../../images/global-logos/mr-id.png')}
              alt="More Rewards ID"
              className="logoMr"
            />
          </div>
        </div>
      );
    case 'pricesmartfoods':
      return (
        <div className="logosContainer">
          <div className="banner-container">
            <img
              className="logoPsf"
              src={require('../../images/psf/psf_transparent.png')}
              alt={'PriceSmart Foods'}
            />
          </div>
          <div className="mrContainer">
            <img
              className="logoMr"
              src={require('../../images/global-logos/mr-id.png')}
              alt="More Rewards ID"
            />
          </div>
        </div>
      );
    case 'qualityfoods':
      return (
        <div className="logosContainer">
          <div className="banner-container-qf">
            <img
              className="logoQf"
              src={require('../../images/qf/qualityfoods-logo.png')}
              alt={'Quality Foods'}
            />
          </div>
          <div className="mrContainer">
            <img
              className="logoMr"
              src={require('../../images/global-logos/mr-id.png')}
              alt="More Rewards ID"
            />
          </div>
        </div>
      );
    case 'choices':
      return (
        <div className="logosContainerChoices">
          <img
            className="logoChoices center-img brand-logo"
            src={require('../../images/choices/logo-choices.png')}
            alt={'Choices logo'}
          />
        </div>
      );
    default:
      return (
        <div className="logosContainer">
          <div className={globalContext.isWebview ? "mrContainerWebView" : "mrContainer"}>
            <img
              src={require('../../images/global-logos/mr-id.png')}
              alt="More Rewards ID"
            />
          </div>
        </div>
      );
  }
}

/**
 * Displays a card with the more rewards logo on the top
 */
function CardWithLogo(props: cardLogoProps) {
  const { children, displayBannerLogo, globalContext } = props;
  return (
    <Container className="account-pages" component="main">
      <CorrectBannerImage
        globalContext={globalContext}
        displayBannerLogo={displayBannerLogo}
      />
      {children}
    </Container>
  );
}

export default CardWithLogo;