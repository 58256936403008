import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import {styled} from "@mui/styles";
import React, {useContext, useEffect, useState} from "react";
import {OAuth2Tokens} from "@forgerock/javascript-sdk";
import {
  Divider,
  Grid,
  InputLabel
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import CardWithLogo from "../../components/card/card-with-logo";
import {GlobalContext} from "../../contexts/global";
import {Checkboxes, MyAccountType} from "../../contexts/types/myAccount";
import {AddressBookDivs} from "../my-account/addresses/addressesTypes";
import {CheckboxState} from "../my-account/email-subscriptions/emailSubscriptionsTypes";
import {handleCheckboxChange} from "../my-account/email-subscriptions/helper-functions/helpers";
import {
  fetchProfile,
  generateAddresses,
  generateEmailSubsDivs,
  continueWithLogin,
} from "./welcome-message-helpers";
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import {
  LINK_CONTACT_US,
  LINK_MR_DONE,
  LINK_MR_WIN_FREE_GROCERIES,
} from "../../util/constants";
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PageFooter from "../../components/footer/footer";
import Partners from "../../components/partners/partners";
import {CircularLoading} from "../../components/loading/circular-loading";
import './welcome-message.scss';

export type WelcomeMessageProps = {
  goto: string,
  isAuth: OAuth2Tokens,
  setIsAuth: React.Dispatch<React.SetStateAction<OAuth2Tokens>>,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  theme: string
}

// MUI styling for Accordion
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  // @ts-ignore
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

// MUI styling for the AccordionSummary
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
  // @ts-ignore
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // @ts-ignore
    marginLeft: theme.spacing(1),
  },
}));

// MUI styling for the AccordionDetails
const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  // @ts-ignore
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const checkboxObjectWelcomePage: Checkboxes = {
  moreRewardsProgram: null,
  saveOnFoodsFlyerSpecials: null,
  specialOffersMealPrep: null,
  urbanFareSpecials: null,
  priceSmartSpecials: null,
  surveys: null
};

export default function WelcomeMessagePage(props: WelcomeMessageProps): JSX.Element {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {goto, isAuth, setIsAuth, setErrorLogin, theme} = props;
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [accountData, setAccountData] = useState<MyAccountType>(null);
  const [emailSubDivs, setEmailSubDivs] = useState<JSX.Element[]>([]);
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    initialCheckboxesState: checkboxObjectWelcomePage,
    initialCheckboxesSnapshot: checkboxObjectWelcomePage,
    checkboxSnapshotsMatch: true,
    anyCheckboxesAreSelected: false
  });
  const [addressBookDivs, setAddressBookDivs] = useState<AddressBookDivs>({
    billingAddressesDivs: [],
    shippingAddressDivs: [],
    billingShippingAddressDivs: [],
    alternateAddressDivs: []
  });
  const [expanded, setExpanded] = React.useState<string | false>(false); // for Accordion
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Fetch user's profile from Customer Accounts when this page mounts
  useEffect(() => {
    const loadPage = async () => {
      try {
        await fetchProfile(
          globalContext,
          setGlobalContext,
          navigate,
          isAuth,
          setIsAuth,
          setLoading,
          setError,
          setErrorLogin,
          setAccountData,
          enqueueSnackbar,
          goto
        );
      } catch (e) {
        console.error(e);
      }
    }
    loadPage();
  }, []);

  // Generate email subscriptions data on the frontend after fetching the users account data
  useEffect(() => {
    const generateWelcomeProfile = async () => {
      if (accountData) {
        await generateAddresses(accountData, setAddressBookDivs);
        await generateEmailSubsDivs(
          setEmailSubDivs,
          handleCheckboxChange,
          checkboxState,
          accountData,
          theme,
          setCheckboxState
        );
      }
    }
    generateWelcomeProfile();
  }, [accountData]);

  // Modify the email subscription data on the frontend whenever the user makes a change
  useEffect(() => {
    if (accountData) {
      generateEmailSubsDivs(
        setEmailSubDivs,
        handleCheckboxChange,
        checkboxState,
        accountData,
        theme,
        setCheckboxState
      );
    }
  }, [checkboxState.initialCheckboxesState]);

  useEffect(() => {
    if (
      addressBookDivs.billingShippingAddressDivs.length > 0 ||
      addressBookDivs.shippingAddressDivs.length > 0 ||
      addressBookDivs.billingAddressesDivs.length > 0 ||
      addressBookDivs.alternateAddressDivs.length > 0
    ) {
      window.scrollTo(0, 0); // Anchor to the top of the page
    }
  }, [addressBookDivs.billingShippingAddressDivs]);

  return (
    <>
      <div className="page accountWrapper">
        <CardWithLogo children={null} displayBannerLogo={true} globalContext={globalContext}></CardWithLogo>
        <Paper elevation={0} className={theme === "choices" ? "paperWelcomeChoices" : "paperWelcome"}>
          {error
            ?
            <Typography
              className="errorTopHeader marginB40"
            > {error}. If you have any trouble, please
              <a
                href={LINK_CONTACT_US}
                target="_blank"
                className="link-white link-choices underline"
              >contact us
              </a>.
            </Typography>
            :
            <>
              <Typography
                variant="h2"
                component="h2"
                className={theme === "choices" ? "topHeaderChoices" : "topHeader"}
              >Confirm Your Account Information
              </Typography>
            </>
          }
          {theme === "choices"
            ?
            <>
              <Typography className="topText">
                We've made some updates to simplify your sign in experience, please take a moment to make sure your
                account details are up to date. To update, go to Account Settings.
              </Typography>
            </>
            :
            <>
              <Typography className="topText">
                We've made some updates to simplify your sign in experience, please take a moment to make sure your
                account details are up to date. To update, go to Account Settings.
              </Typography>
            </>
          }
        </Paper>
        {loading
          ?
          <div className="loadingProfile">
            <Box>
              <CircularLoading theme={theme}/>
            </Box>
          </div>
          :
          <>
            <Paper elevation={0}>
              <div className="welcomeMessageBox">
                <div className="centeredContainer welcomeMessageContainer">
                  <div className="gridOptions">
                    <Grid container rowSpacing={2} columnSpacing={{md: 5, lg: 5}}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputLabelWelcome">{'FIRST NAME'}*</InputLabel>
                        <TextField
                          disabled
                          value={accountData ? accountData.firstName : null}
                          id="outlined-basic"
                          variant="outlined"
                          className="fullWidth"
                          inputProps={{'aria-label': 'First name'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="inputLabelWelcome">{'LAST NAME'}*</InputLabel>
                        <TextField
                          value={accountData ? accountData.lastName : null}
                          disabled
                          id="outlined-basic"
                          variant="outlined"
                          className="fullWidth"
                          inputProps={{'aria-label': 'Last name'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel className="inputLabelWelcome">{'Email'}</InputLabel>
                        <TextField
                          disabled
                          value={accountData ? accountData.email.toLowerCase() : null}
                          id="outlined-basic"
                          variant="outlined"
                          className="fullWidth"
                          type="tel"
                          autoComplete="tel"
                          inputProps={{
                            maxLength: 12,
                            'aria-label': 'Home Phone'
                          }}
                          // @ts-ignore
                          FormHelperTextProps={{
                            style: {color: '#e50176', position: 'absolute', marginTop: '60px'}
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel className="inputLabelWelcome">{'Phone'}</InputLabel>
                        <TextField
                          disabled
                          value={accountData ? accountData.primary : null}
                          id="outlined-basic"
                          variant="outlined"
                          className="fullWidth"
                          type="tel"
                          autoComplete="tel"
                          inputProps={{
                            maxLength: 12,
                            'aria-label': 'Mobile phone'
                          }}
                          // @ts-ignore
                          FormHelperTextProps={{
                            style: {color: '#e50176', position: 'absolute', marginTop: '60px'}
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider className="dividerWelcomeGrid" orientation="vertical" flexItem/>
                        {(
                          addressBookDivs.billingShippingAddressDivs.length > 0 ||
                          addressBookDivs.billingAddressesDivs.length > 0 ||
                          addressBookDivs.shippingAddressDivs.length > 0 ||
                          addressBookDivs.alternateAddressDivs.length > 0
                        ) &&
                          <InputLabel
                            className="inputLabelWelcome inputLabelWelcomeLarge">
                            {'Addresses'}
                          </InputLabel>
                        }
                        <div>
                          {(addressBookDivs.billingShippingAddressDivs.length > 0) &&
                            <Grid container>
                              {addressBookDivs.billingShippingAddressDivs}
                            </Grid>
                          }
                          {
                            (
                              addressBookDivs.billingAddressesDivs.length > 0 &&
                              addressBookDivs.shippingAddressDivs.length > 0
                            ) &&
                            <>
                              <Grid container>
                                {addressBookDivs.billingAddressesDivs}
                                {addressBookDivs.shippingAddressDivs}
                              </Grid>
                            </>
                          }
                          {(addressBookDivs.billingAddressesDivs.length > 0 && addressBookDivs.shippingAddressDivs.length === 0) &&
                            <>
                              <Grid container>
                                {addressBookDivs.billingAddressesDivs}
                              </Grid>
                            </>
                          }
                          {addressBookDivs.alternateAddressDivs.length > 0 &&
                            <>
                              <Typography sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                marginBottom: '-6px',
                                marginTop: '20px',
                                marginLeft: '12px'
                              }}>
                                Alternate Addresses
                              </Typography>
                              <div className="Addressaccordion">
                                <Accordion
                                  expanded={expanded === 'panel1'}
                                  onChange={handleChange('panel1')}
                                >
                                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    {expanded
                                      ?
                                      <Typography>Hide</Typography>
                                      :
                                      <Typography>View</Typography>
                                    }
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container>
                                      {addressBookDivs.alternateAddressDivs}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>
                    {(emailSubDivs.length !== 0 && theme !== 'choices')
                      ?
                      <>
                        <Divider className="dividerWelcome" orientation="vertical" flexItem/>
                        <div className="emailSubs">
                          <InputLabel
                            className="inputLabelWelcome inputLabelWelcomeLarge">
                            {'Email Subscriptions'}
                          </InputLabel>
                          {!globalContext.isWebview
                            ?
                            <Typography className="inputLabelText emailPromo">
                              Want to win FREE groceries for a year? Subscribe to emails below to be entered into our
                              monthly draw. Conditions apply. Learn more at&nbsp;
                              <Link
                                href={LINK_MR_WIN_FREE_GROCERIES}
                                target="_blank"
                                className="link-choices"
                              >morerewards.ca/winfreegroceries</Link>

                            </Typography>
                            :
                            <Typography className="inputLabelText emailPromo">
                              Want to win FREE groceries for a year? Subscribe to emails below to be entered into our monthly draw.
                              Conditions apply. Learn more at morerewards.ca/winfreegroceries
                            </Typography>
                          }
                          <Grid container columnSpacing={{md: 5, lg: 5}}>
                            {emailSubDivs}
                          </Grid>
                        </div>
                      </>
                      :
                      <></>
                    }
                  </div>
                  <div className="buttonsContainer">
                    {loadingAPI
                      ?
                      <LoadingButton
                        disabled
                        loading
                        className="cancelButtonDefault"
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                      >
                        Saving...
                      </LoadingButton>
                      :
                      <>
                        {globalContext.isWebview ?
                          <Button
                            className="primaryButtonDefault buttonRight"
                            autoFocus
                            onClick={() => continueWithLogin(
                              checkboxState,
                              theme,
                              goto,
                              enqueueSnackbar,
                              setLoadingAPI,
                              accountData,
                              false
                            )}
                          >
                            Got it! Continue
                          </Button>
                          :
                          <>
                            <Button
                              className="primaryButtonDefault buttonRight"
                              autoFocus
                              onClick={() => continueWithLogin(
                                checkboxState,
                                theme,
                                goto,
                                enqueueSnackbar,
                                setLoadingAPI,
                                accountData,
                                true
                              )}
                            >
                              Update Now
                            </Button>
                            <Button
                              className="deleteButtonDefault buttonRight"
                              autoFocus
                              onClick={() => continueWithLogin(
                                checkboxState,
                                theme,
                                goto,
                                enqueueSnackbar,
                                setLoadingAPI,
                                accountData,
                                false
                              )}
                            >
                              Update Later
                            </Button>
                          </>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
            </Paper>
          </>
        }
      </div>
      <div className="flexCenter" onClick={() => {
        window.location.replace(`${LINK_MR_DONE}`);
      }}>
      </div>
      {globalContext.isWebview || theme === 'choices' ?
        <></>
        :
        <Partners/>
      }
      <PageFooter isLogin={theme !== 'choices'} theme={theme}/>
    </>
  );
}