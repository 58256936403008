import React, {useContext, useEffect, useState} from 'react';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import PageFooter from '../../components/footer/footer';
import {LINK_CHOICES, LINK_MR, LINK_PSF, LINK_SOF, LINK_UF} from "../../util/constants";
import './maintenance.scss';
import {GlobalContext} from "../../contexts/global";
import {Button} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export type MaintenancePageProps = {
  theme: string
}

export default function MaintenancePage(props: MaintenancePageProps) {
  const { theme } = props
  const { globalContext, } = useContext(GlobalContext);
  const [messageToDisplay, setMessageToDisplay] = useState<string>('');
  let referer = localStorage.getItem('data-analytics-banner');

  function setMessage(theme: string) {
      if (theme === 'choices') {
        setMessageToDisplay('Sorry, Choices Markets sign-in is currently under maintenance, we\'ll be back soon.');
      } else {
        setMessageToDisplay('Sorry, More Rewards ID sign-in is currently under maintenance, we\'ll be back soon.');
    }
  }

  const handleThemeRedirection = async (theme: string) => {
    switch (theme) {
      case 'choices':
        return window.location.replace(`${LINK_CHOICES}`);
      case 'saveonfoods':
        return window.location.replace(`${LINK_SOF}`);
      case 'pricesmartfoods':
        return window.location.replace(`${LINK_PSF}`);
      case 'urbanfare':
        return window.location.replace(`${LINK_UF}`);
      default:
        return window.location.replace(`${LINK_MR}`);
    }
  }

  useEffect(() => {
    pushRouteChangeEvent('Under Maintenance');
    setMessage(sessionStorage.getItem('referer'));
  }, []);

  return (
    <>
      <div className="root">
        <div className="page">
          <img
            className="center-img pointer"
            src={theme === 'choices' || referer === 'choices' ?
              require('../../images/choices/mobile-logo-choices.png') :
              require('../../images/global-logos/mr-id.png')
            }
            onClick={() => window.open(`${LINK_MR}`, '_blank')}
            alt="more rewards"
          />
          <div className={theme === 'choices' ? "maintenance-message-box-choices" : "maintenance-message-box"}>
            <h2 className="maintenance-top-header">{messageToDisplay}</h2>
            {
              globalContext.isWebview ?
              <></> :
              <Button className={theme === 'choices' ? "back-button-default-choices" : "back-button-default"} onClick={() => handleThemeRedirection(theme)}><ArrowBackIcon className={theme === 'choices' ? "back-icon-choices" : "back-icon"}/>Go back</Button>
            }
          </div>
        </div>
      </div>
      <div>
        <PageFooter isLogin={false} theme={theme || referer}/>
      </div>
    </>
  )
}

