import {useSnackbar} from "notistack";
import React, {useEffect, useMemo, useState} from 'react';
import {CheckboxState} from '../my-account/email-subscriptions/emailSubscriptionsTypes';
import SaveIcon from '@mui/icons-material/Save';
import {checkboxObject} from '../my-account/myAccount';
import {Button, Checkbox, Grid, Link, Paper, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getEmailSubs, returnLoginClick, saveClick, cancelButtonClick} from './helpers';
import {generateCheckboxDivs} from '../my-account/email-subscriptions/generate-checkbox-divs/generateCheckboxDivs';
import {
  handleCheckboxChange,
  selectOrUnselectAll,
  verifyAnyCheckboxesAreSelected
} from '../my-account/email-subscriptions/helper-functions/helpers';
import PageFooter from '../../components/footer/footer';
import "./unsubscribe.scss";
import {
  LINK_MR_DONE,
  LINK_CONTACT_US
} from '../../util/constants';
import Partners from "../../components/partners/partners";
import {CircularLoading} from "../../components/loading/circular-loading";

export type UnsubProps = {
  theme: string
}

export default function UnsubPage(props: UnsubProps) {
  const { theme } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId: string = useMemo(() => searchParams.get('id'), []);
  const [error, setError] = useState<string>('');
  const [checkboxDivs, setCheckboxDivs] = useState<JSX.Element[]>([]);
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [successfullyVerifiedEmail, setSuccessfullyVerifiedEmail] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    initialCheckboxesState: checkboxObject,
    initialCheckboxesSnapshot: checkboxObject,
    checkboxSnapshotsMatch: true,
    anyCheckboxesAreSelected: false
  });

  useEffect(() => {
    getEmailSubs(
      setApiIsLoading,
      setSuccessfullyVerifiedEmail,
      setError,
      setCheckboxState,
      userId
    );
  }, []);

  useEffect(() => {
    generateCheckboxDivs(
      setCheckboxDivs,
      handleCheckboxChange,
      checkboxState,
      setCheckboxState,
      apiIsLoading,
      apiIsLoading
    );
    verifyAnyCheckboxesAreSelected(setCheckboxState, checkboxState);
  }, [checkboxState.initialCheckboxesState, apiIsLoading, apiIsLoading]);

  return (
    <>
      <div className="page accountWrapper">
        <div className="cardLogos">
          <img
            className="logoMr"
            src={require('../../images/global-logos/mr-id.png')}
            alt="More Rewards ID"
          />
        </div>
        <div className="mainWrapper">
          <div>
            <Box>
              {apiIsLoading
                ?
                <div className="loadingProfile">
                  <Box>
                    <CircularLoading theme={theme}/>
                  </Box>
                </div>
                :
                <>
                  {successfullyVerifiedEmail
                    ?
                    <>
                      {error
                        ?
                        <>
                          <h6 className="errorMessage500">{error}
                          </h6>
                          <Link
                            href={LINK_CONTACT_US}
                            target="_blank"
                            className="link-white link-choices"
                          >contact us
                          </Link>
                        </>
                        :
                        <>
                          <Paper elevation={0}>
                            <div className="emailSubscriptions">
                              <div className="infoBar infoBarThin">
                                <div>
                                  <h3>Manage Your Email Subscriptions</h3>
                                  <p>Unsubscribe from any of the following emails</p>
                                </div>
                              </div>
                              <div className="centeredContainer emailSubscriptions">
                                <div className="innerColumn">
                                  <Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <div className="checkboxesConfirmation">
                                        <div className="checkboxAndTextManage">
                                          <Checkbox
                                            disabled={apiIsLoading || apiIsLoading}
                                            data-testid="checkboxChangeAll"
                                            checked={checkboxState.anyCheckboxesAreSelected}
                                            onChange={
                                              () => {
                                                checkboxState.anyCheckboxesAreSelected
                                                  ? selectOrUnselectAll(setCheckboxState, 'uncheckAll')
                                                  : selectOrUnselectAll(setCheckboxState, 'checkAll');
                                              }
                                            }
                                          />
                                          <div>
                                            {checkboxState.anyCheckboxesAreSelected
                                              ?
                                              <Typography>
                                                Unselect all
                                              </Typography>
                                              :
                                              <Typography>
                                                Select all
                                              </Typography>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="checkboxes">
                                        {checkboxDivs}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div className="editButtonsUnsubPage">
                                  <div>
                                    <Button
                                      className="primaryButtonDefault"
                                      disabled={submitIsLoading}
                                      sx={{ mt: 3, mb: 2, marginRight: '10px' }}
                                      onClick={
                                        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => returnLoginClick(
                                          event,
                                          'login',
                                          navigate
                                        )
                                      }
                                    >Return To Login</Button>
                                  </div>
                                  <div className="unsubContainerButtons">
                                    {submitIsLoading
                                      ?
                                      <LoadingButton
                                        loading
                                        className="primaryButtonDefault"
                                        loadingPosition="start"
                                        sx={{ mt: 3, mb: 2 }}
                                        startIcon={<SaveIcon/>}
                                        variant="outlined"
                                      >
                                        Saving...
                                      </LoadingButton>
                                      :
                                      <>
                                        <Button
                                          disabled={checkboxState.checkboxSnapshotsMatch}
                                          type="submit"
                                          className="primaryButtonDefault"
                                          variant="contained"
                                          sx={{ mt: 3, mb: 2 }}
                                          onClick={() => saveClick(
                                            checkboxState.initialCheckboxesState,
                                            setSubmitIsLoading,
                                            setCheckboxState,
                                            enqueueSnackbar,
                                            userId
                                          )}
                                        >
                                          Save
                                        </Button>
                                      </>
                                    }
                                    {!checkboxState.checkboxSnapshotsMatch
                                      ?
                                      <Button
                                        disabled={checkboxState.checkboxSnapshotsMatch || submitIsLoading}
                                        type="submit"
                                        className="cancelButtonDefault"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, marginLeft: '5px' }}
                                        onClick={() => cancelButtonClick(checkboxState, setCheckboxState)}
                                      >
                                        Cancel
                                      </Button>
                                      :
                                      <>
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Paper>
                        </>
                      }
                    </>
                    :
                    <>
                      <Typography
                        component="p"
                        variant="body2"
                        className="unsubPageText">
                        {error}&nbsp;
                        <Link
                          href={LINK_CONTACT_US}
                          target="_blank"
                          className="link-white link-choices"
                        >contact us
                        </Link>
                      </Typography>
                      <div className="returnToLoginButton">
                        <Button
                          className="primaryButtonDefault"
                          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => returnLoginClick(
                            event,
                            'login',
                            navigate
                          )}
                        >Return To Login</Button>
                      </div>
                    </>
                  }
                </>
              }
            </Box>
          </div>
        </div>
      </div>
      <div className="flexCenter" onClick={() => {
        window.location.replace(`${LINK_MR_DONE}`);
      }}>
      </div>
      <Partners/>
      <PageFooter isLogin={false} theme={theme}/>
    </>
  );
}