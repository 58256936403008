import {Button} from '@mui/material';
import React from 'react';
import {useSnackbar} from 'notistack';

export const ErrorMessageDisplay = () => {
  const {enqueueSnackbar} = useSnackbar();

  return <div>
    {/*Error message*/}
    <Button variant="outlined" onClick={() => {
      enqueueSnackbar('This is a Error message!', {variant: 'error'});
    }}>Open Error Message</Button>

    {/*Warning message*/}
    <Button variant="outlined" onClick={() => {
      enqueueSnackbar('This is a Warning message!', {variant: 'warning'});
    }}>Open Warning Message</Button>

    {/*Success Message*/}
    <Button variant="outlined" onClick={() => {
      enqueueSnackbar('This is a Success message!', {variant: 'success'});
    }}>Open Success Message</Button>
  </div>;
};