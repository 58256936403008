import React, {SyntheticEvent, useState} from 'react';
import {
  DetailedResultType,
  getDetailedCanadaPostAddress,
  searchCanadaPost,
  searchCanadaPostLastId,
  SearchResultsType
} from '../../network-requests/canada-post';
import {Autocomplete, AutocompleteRenderInputParams} from '@mui/material';
import {addressValidator, cityValidator, postalCodeValidator} from '../../util/validator';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import '../../styling/address-search/address-search.css';

export type AddressSearchProps = {
  addressLine1: string
  setAddressLine1: (arg0: string) => void,
  addressLine1HelperText: string
  setAddressLine1HelperText: (arg0: string) => void, //kept helper text as prop as will need for validation on submit in parent
  setAddressLine2?: (arg0: string) => void,
  setCity?: (arg0: string) => void,
  cityHelperText: string,
  setCityHelperText: (arg0: string) => void,
  setPostalCode?: (arg0: string) => void,
  postalCodeHelperText: string,
  setPostalCodeHelperText: (arg0: string) => void,
  setProvince?: (arg0: string) => void,
  provinceHelperText: string,
  setProvinceHelperText: (arg0: string) => void,
  setCountry?: (arg0: string) => void,
  tabIndex?: number,
  autofocus?: boolean,
  className?: string,
  required?: boolean,
  savingChange?: boolean
}

/**
 * Uses canada posts apis to display potential addresses, on selection sets setters provided in props.
 */
const AddressSearch = (props: AddressSearchProps) => {
  const { t, ready } = useTranslation();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultsType[]>([]);
  const [lastFindBeforeRetrieve, setLastFindBeforeRetrieve] = useState<SearchResultsType[]>([]);

  const onAddressFill = async (event: React.SyntheticEvent<Element, Event>, newValue: string | {id: string, desc: string, text: string, next: string}, reason: string) => {
    // check if type of { id: string, label: string }
    if (reason !== 'selectOption' || newValue === null || typeof newValue === 'string') {
      return;
    }
    if (newValue.next === 'Find') {
      setLastFindBeforeRetrieve(searchResults);
      searchCanadaPostLastId(newValue.id).then(results => setSearchResults(results)).then(() => {
        setSearchResults((prev) => {
          return [{ Id: 'Back', Next: 'Back', Text: 'Back', Description: '' }, ...prev];
        });
      });
      setSearchOpen(true);
    } else if (newValue.next === 'Retrieve') {
      setSearchOpen(false);
      let response: DetailedResultType = await getDetailedCanadaPostAddress(newValue.id);
      if (response === undefined) {
        return;
      }
      props.setAddressLine1(response.Line1);
      props.setAddressLine1HelperText(addressValidator(response.Line1, t));
      props.setAddressLine2 && props.setAddressLine2(response.Line2);
      props.setCity && props.setCity(response.City);
      props.setCityHelperText(cityValidator(response.City, t));
      props.setPostalCode && props.setPostalCode(response.PostalCode);
      props.setPostalCodeHelperText(postalCodeValidator(response.PostalCode, t));
      props.setProvince && props.setProvince(response.ProvinceCode);
      if (response.ProvinceCode.length > 0) {
        props.setProvinceHelperText('');
      }
      props.setCountry && props.setCountry(response.CountryIso2);
    } else if (newValue.next === 'Back') {
      setSearchResults(lastFindBeforeRetrieve);
    }
  };

  return (
    <Autocomplete
      disablePortal
      freeSolo
      disableClearable
      className={props.className}
      open={searchOpen}
      onBlur={() => setSearchOpen(false)}
      onOpen={() => setSearchOpen(true)}
      filterOptions={(options) => options}
      inputValue={props.addressLine1}
      // groupBy={(option) => option.group}
      options={searchResults.map(result => ({
        id: result.Id,
        text: result.Text,
        desc: result.Description,
        next: result.Next,
        group: (result.Next === 'Find' ? 'Group' : 'Individual'),
        label: result.Id // needed but not used
      }))} //.sort((a, b) => -b.group.localeCompare(a.group))
      onInputChange={(event: SyntheticEvent, newValue, reason) => {
        if (reason === 'input') {
          props.setAddressLine1(newValue);
          props.setAddressLine1HelperText(addressValidator(newValue, t));
          searchCanadaPost(newValue).then(results => setSearchResults(results));
        }
      }}
      onChange={onAddressFill}
      renderInput={(params: AutocompleteRenderInputParams) =>
        <TextField
          {...params}
          {...params}
          disabled={props.savingChange}
          autoFocus={props.autofocus}
          inputProps={{ ...params.inputProps, tabIndex: props.tabIndex, autoComplete: 'address-line1' }}
          required={props.required}
          helperText={props.addressLine1HelperText}
          // @ts-ignore
          error={props.addressLine1HelperText}
          FormHelperTextProps={{ style: { color: '#e50176' } }}
          // label={t('BASIC.ADDRESS_LINE_1')}
        />
      }
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => {
        if (option.next === 'Find') {
          return <li {...props} key={option.id}><span>{option.text}<br/><i>{option.desc}</i></span><ArrowForwardIosIcon
            className="address_group_forward_arrow"/></li>;
        } else if (option.next === 'Back') {
          return <li {...props} key={option.id}><ArrowBackIosIcon/>{`  ${option.text} ${option.desc}`}</li>;
        }
        return <li {...props} key={option.id}>{`${option.text} ${option.desc}`}</li>;
      }}
    />
  );
};

export default AddressSearch;