import React, {useEffect, useState} from 'react';
import {Dialog, DialogTitle, FormControl, InputLabel, MenuItem, TextField} from '@mui/material';
import {useAtom} from "jotai";
import {useTranslation} from 'react-i18next';
import {profileInfoAtom} from "../../../../jotai-state-management/profile-global-state";
import {removeDashesPhoneNumber} from "../../../../util/phoneFormatter";
import {trimDoubleSpace} from "../../../../util/string-util";
import {
  cityValidator,
  firstNameValidator,
  homePhoneValidator,
  lastNameValidator,
  postalCodeValidator
} from "../../../../util/validator";
import {
  convertProvinceCodeToLonghand,
  handleClose,
  provinces, updatePhoneNumbersAddress
} from './editAddressHelpers';
import AddressSearch from '../../../../components/canada-post/address-search';
import {handleBillingShippingUpdate, onClose} from "./editAddressHelpers";
import {useSnackbar} from 'notistack';
import {handleDelete, handleClickOpen} from "./editAddressHelpers";
import {HelperText, SimpleDialogProps} from '../addressesTypes';
import {ProfileInfoType, SingleAddress} from '../../../../contexts/types/myAccount';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {LoadingButton} from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import './editDialogue.css';
import {formatPostalCode} from "../helpers/postalCodeFormatter";

export function EditAddressDialog(props: SimpleDialogProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    open,
    addressToEdit,
    setOpenDeleteDialogue,
    setAddressToDelete,
    setOpen,
    setAddressToEdit,
    setAddressBookDivs,
    theme,
    setLoadingBillingShippingUpdate
  } = props;
  const [profileInfo] = useAtom<ProfileInfoType>(profileInfoAtom);
  const [selectedProvince, setSelectedProvince] = useState<string>('British Columbia'); // Always default to BC when null
  const [singleAddressDialogue, setSingleAddressDialogue] = useState<SingleAddress>({});
  const [dialogueTitle, setDialogueTitle] = useState<string>('');
  const [savingChange, setSavingChange] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<HelperText>({
    firstName: '',
    lastName: '',
    street1: '',
    cityName: '',
    postalCode: '',
    primaryPhone: ''
  });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<any>({ phone: '' });

  useEffect(() => {
    if (addressToEdit) {
      setSingleAddressDialogue(addressToEdit);
      setDialogueTitle('Edit Address')
      setSelectedProvince(addressToEdit.provinceCode);
    } else {
      setSingleAddressDialogue({
        id: '',
        firstName: '',
        lastName: '',
        street1: '',
        street2: '',
        cityName: '',
        provinceCode: '',
        postalCode: '',
        countryCode: 'CA',
        primaryPhone: '',
        alternatePhone: ''
      });
      setDialogueTitle('Create New Address');
    }
  }, [open, addressToEdit]);

  useEffect(() => {
    convertProvinceCodeToLonghand(selectedProvince, setSelectedProvince);
  }, [selectedProvince]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProvince(event.target.value as string);
  };

  useEffect(() => {
    if (singleAddressDialogue) {
      updatePhoneNumbersAddress(
        setFormattedPhoneNumber,
        singleAddressDialogue.primaryPhone ? singleAddressDialogue.primaryPhone : ''
      );
    }
  }, [singleAddressDialogue ? singleAddressDialogue.primaryPhone : null]);

  useEffect(() => {
    setHelperText((prev: HelperText) => ({
      ...prev,
      primaryPhone: homePhoneValidator(removeDashesPhoneNumber(formattedPhoneNumber.phone), t)
    }));
  }, [formattedPhoneNumber])

  return (
    <div>
      {singleAddressDialogue &&
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'md'}
      >
        <div className="cancelButtonBox" onClick={() => handleClose(
          'cancel',
          singleAddressDialogue,
          selectedProvince,
          null,
          enqueueSnackbar,
          onClose,
          setHelperText,
          setSingleAddressDialogue,
          profileInfo,
          handleClickOpen,
          addressToEdit,
          handleDelete,
          setOpenDeleteDialogue,
          setAddressToDelete,
          handleBillingShippingUpdate,
          setOpen,
          setAddressToEdit,
          setAddressBookDivs,
          theme,
          setLoadingBillingShippingUpdate
        )}>
        <Button
            type="submit"
            variant="contained"
            className="cancelButtonTop"
            sx={{ mt: 1, mb: 1 }}
            disabled={savingChange}
        >x</Button>
        </div>
        <DialogTitle>{dialogueTitle}</DialogTitle>
        <div className="editDialogueContainer">
          <Box className="addressDialogueBox">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel className="inputLabel">{t('First Name')}*</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    // @ts-ignore
                    error={helperText.firstName}
                    helperText={helperText.firstName}
                    value={singleAddressDialogue.firstName || ''}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        firstName: event.target.value
                      }));
                      setHelperText((prev: HelperText) => ({
                        ...prev,
                        firstName: firstNameValidator(trimDoubleSpace(event.target.value), t)
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel className="inputLabel">{t('Last Name')}*</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    // @ts-ignore
                    error={helperText.lastName}
                    helperText={helperText.lastName}
                    inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    value={singleAddressDialogue.lastName || ''}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        lastName: event.target.value
                      }));
                      setHelperText((prev: HelperText) => ({
                        ...prev,
                        lastName: lastNameValidator(trimDoubleSpace(event.target.value), t)
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputLabel className="inputLabel">{t('Phone')}*</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    inputProps={{ maxLength: 12 }}
                    variant="outlined"
                    value={formattedPhoneNumber.phone || ''}
                    // @ts-ignore
                    error={helperText.primaryPhone}
                    helperText={helperText.primaryPhone}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        primaryPhone: removeDashesPhoneNumber(event.target.value)
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel className="inputLabel">{t('ADDRESS LINE 1')}*</InputLabel>
                <AddressSearch
                  addressLine1={singleAddressDialogue.street1}
                  setAddressLine1={(newVal: string) => {
                    setSingleAddressDialogue((prev: SingleAddress) => ({
                      ...prev,
                      street1: newVal
                    }));
                  }}
                  addressLine1HelperText={helperText.street1}
                  setAddressLine1HelperText={(newVal: string) => {
                    setHelperText((prev: HelperText) => ({ ...prev, street1: newVal }));
                  }}
                  setCity={(newVal: string) => {
                    setSingleAddressDialogue((prev: SingleAddress) => ({
                      ...prev,
                      cityName: newVal
                    }));
                  }}
                  cityHelperText={helperText.cityName}
                  setCityHelperText={(newVal: string) => {
                    setHelperText((prev: HelperText) => ({ ...prev, cityName: newVal }));
                  }}
                  setAddressLine2={(newVal: string) => {
                    setSingleAddressDialogue((prev: SingleAddress) => ({
                      ...prev,
                      street2: newVal
                    }));
                  }}
                  setPostalCode={(newVal: string) => {
                    setSingleAddressDialogue((prev: SingleAddress) => ({
                      ...prev,
                      postalCode: newVal
                    }));
                  }}
                  postalCodeHelperText={helperText.postalCode}
                  setPostalCodeHelperText={(newVal: string) => {
                    setHelperText((prev: HelperText) => ({ ...prev, postalCode: newVal }));
                  }}
                  setCountry={(newVal: string) => {
                    setSingleAddressDialogue((prev: SingleAddress) => ({
                      ...prev,
                      countryCode: newVal
                    }));
                  }}
                  setProvince={setSelectedProvince}
                  provinceHelperText={helperText.provinceCode}
                  setProvinceHelperText={(newVal: string) => {
                    setSingleAddressDialogue((prev: any) => ({ ...prev, provinceCode: newVal }));
                  }}
                  className=""
                  savingChange={savingChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel className="inputLabel">{t('Address Line 2')}</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    value={singleAddressDialogue.street2 || ''}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        street2: event.target.value
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel required className="inputLabel">{t('City')}</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    inputProps={{ maxLength: 40 }}
                    variant="outlined"
                    // @ts-ignore
                    error={helperText.cityName}
                    helperText={helperText.cityName}
                    value={singleAddressDialogue.cityName || ''}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        cityName: event.target.value
                      }));
                      setHelperText((prev: HelperText) => ({
                        ...prev,
                        cityName: cityValidator(event.target.value, t)
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel required className="inputLabel">{t('PROVINCE')}</InputLabel>
                <Box className="provCodeBox">
                  <FormControl fullWidth>
                    <Select
                      fullWidth={true}
                      value={selectedProvince || ''}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      onChange={handleChange}
                      disabled={savingChange}
                    >
                      {provinces.map((province: string, index: number) => {
                        return (
                          <MenuItem value={province} key={index}>
                            {province}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel required className="inputLabel">{t('Postal Code')}</InputLabel>
                <div className="items">
                  <TextField
                    disabled={savingChange}
                    fullWidth={true}
                    id="outlined-basic"
                    inputProps={{ maxLength: 7 }}
                    variant="outlined"
                    // @ts-ignore
                    error={helperText.postalCode}
                    helperText={helperText.postalCode}
                    value={formatPostalCode(singleAddressDialogue.postalCode) || ''}
                    // @ts-ignore
                    FormHelperTextProps={{ style: { color: '#e50176' } }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      let input = event.target.value.toUpperCase();
                      setSingleAddressDialogue((prev: SingleAddress) => ({
                        ...prev,
                        postalCode: input
                      }));
                      setHelperText((prev: HelperText) => ({
                        ...prev,
                        postalCode: postalCodeValidator(input, t)
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel required className="inputLabel">{t('COUNTRY')}</InputLabel>
                <TextField
                  fullWidth={true}
                  disabled
                  id="outlined-basic"
                  variant="outlined"
                  value={singleAddressDialogue.countryCode}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="editButtons">
            {savingChange
              ?
              <LoadingButton
                loading
                className="primaryButtonDefault"
                loadingPosition="start"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<SaveIcon/>}
                variant="outlined"
              >
                Saving...
              </LoadingButton>
              :
              <div>
                {dialogueTitle === 'Create New Address'
                  ?
                  <Button
                    disabled={
                      helperText.firstName !== '' ||
                      helperText.lastName !== '' ||
                      helperText.street1 !== '' ||
                      helperText.primaryPhone !== '' ||
                      helperText.cityName !== '' ||
                      helperText.postalCode !== '' ||
                      helperText.firstName !== '' ||
                      helperText.lastName !== '' ||
                      !singleAddressDialogue.street1 ||
                      !singleAddressDialogue.cityName ||
                      !singleAddressDialogue.postalCode ||
                      !singleAddressDialogue.primaryPhone ||
                      !singleAddressDialogue.firstName ||
                      !singleAddressDialogue.lastName ||
                      !selectedProvince
                    }
                    type="submit"
                    className="primaryButtonDefault"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => handleClose(
                      'create',
                      singleAddressDialogue,
                      selectedProvince,
                      setSavingChange,
                      enqueueSnackbar,
                      onClose,
                      setHelperText,
                      setSingleAddressDialogue,
                      profileInfo,
                      handleClickOpen,
                      addressToEdit,
                      handleDelete,
                      setOpenDeleteDialogue,
                      setAddressToDelete,
                      handleBillingShippingUpdate,
                      setOpen,
                      setAddressToEdit,
                      setAddressBookDivs,
                      theme,
                      setLoadingBillingShippingUpdate
                    )}
                  >
                    Create Address
                  </Button>
                  :
                  <Button
                    disabled={
                      helperText.firstName !== '' ||
                      helperText.lastName !== '' ||
                      helperText.street1 !== '' ||
                      helperText.primaryPhone !== '' ||
                      helperText.cityName !== '' ||
                      helperText.postalCode !== '' ||
                      !singleAddressDialogue.street1 ||
                      !singleAddressDialogue.cityName ||
                      !singleAddressDialogue.postalCode ||
                      !singleAddressDialogue.primaryPhone ||
                      !singleAddressDialogue.firstName ||
                      !singleAddressDialogue.lastName ||
                      !selectedProvince
                    }
                    type="submit"
                    className="primaryButtonDefault"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => handleClose(
                      'save',
                      singleAddressDialogue,
                      selectedProvince,
                      setSavingChange,
                      enqueueSnackbar,
                      onClose,
                      setHelperText,
                      setSingleAddressDialogue,
                      profileInfo,
                      handleClickOpen,
                      addressToEdit,
                      handleDelete,
                      setOpenDeleteDialogue,
                      setAddressToDelete,
                      handleBillingShippingUpdate,
                      setOpen,
                      setAddressToEdit,
                      setAddressBookDivs,
                      theme,
                      setLoadingBillingShippingUpdate
                    )}
                  >
                    Save
                  </Button>
                }
              </div>
            }
            <Button
              type="submit"
              variant="contained"
              className="cancelButtonDefault"
              sx={{ mt: 3, mb: 2 }}
              disabled={savingChange}
              onClick={() => handleClose(
                'cancel',
                singleAddressDialogue,
                selectedProvince,
                null,
                enqueueSnackbar,
                onClose,
                setHelperText,
                setSingleAddressDialogue,
                profileInfo,
                handleClickOpen,
                addressToEdit,
                handleDelete,
                setOpenDeleteDialogue,
                setAddressToDelete,
                handleBillingShippingUpdate,
                setOpen,
                setAddressToEdit,
                setAddressBookDivs,
                theme,
                setLoadingBillingShippingUpdate
              )}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      }
    </div>
  );
}
