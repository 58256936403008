import Container from '@mui/material/Container';
import PageFooter from '../../components/footer/footer';
import React, {useEffect} from 'react';
import pushRouteChangeEvent from '../../util/google-tag-manager';

export type MrTravelProps = {
  theme: string
}

export const moreRewardsTravelGiftCardConditions = (props: MrTravelProps) => {
    const { theme } = props;
    useEffect(() => {
      pushRouteChangeEvent('TOS: MoreRewardsTravelGiftCardConditions');
    }, []);

    return (<>
        <Container>
          <div>
            <h4>Terms and Conditions of Use</h4>
            <p>This Terms of Use agreement (the “Agreement”) between you and the Save-On-Foods Limited Partnership doing
              business as “More Rewards Travel” sets out the terms and conditions that apply to your pre-paid More Rewards
              Travel Gift Card (the “Travel Card”) in Canada. Please keep a copy of this Agreement for your records. The
              use
              of your Travel Card by yourself or by anyone else who holds your Travel Card constitutes your acceptance of
              the
              terms and conditions contained in this Agreement.</p>

            <h4>Use of Pre-Paid Card for More Rewards Travel Purchases</h4>
            <p>Your Travel Card can only be used for the purchase of products sold through the More Rewards Travel Centre.
              In
              order to redeem your Travel Card, you must be a More Rewards member. Membership in More Rewards is free and
              no
              initial purchase is required. Simply fill out a More Rewards application form and deliver it to any
              participating SOF store, apply at <a
                href="https://www.morerewards.ca/enroll">www.morerewards.ca/enroll</a> ,
              or at any other location or process designated
              by
              SOF from time to time. Your Travel Card is pre-loaded with a set dollar amount and should be treated like
              cash.
              The amount that is on your Travel Card is a pre-payment only for products sold through the More Rewards
              Travel
              Centre. No credit card, credit line, overdraft protection, or deposit account is associated with your Travel
              Card. The amount on your Travel Card is non-refundable. Your Travel Card cannot be redeemed for cash, unless
              permitted by this Agreement or otherwise required by law. At no time shall any dividend, interest or
              earnings
              of
              any kind whatsoever, be accrued, credited or paid to you on the amounts loaded onto a Travel Card. The
              amount
              loaded onto your Travel Card is not insured by the Canadian Deposit Insurance Corporation (CDIC).</p>

            <p>More Rewards Travel reserves the right not to accept, load or re-issue any Travel Card and to otherwise
              limit
              the use of a Travel Card if More Rewards Travel reasonably believes that the card has been obtained, or its
              use
              is unauthorized, fraudulent, or otherwise unlawful.</p>

            <h4>Purchasing, and Loading Your Travel Card</h4>
            <p>You can purchase your Travel Card online through <a
              href="https://www.morerewards.ca">www.morerewards.ca</a>.
              The online component of the Travel Card
              program, including but not limited to, purchasing and card-customization functionality of Travel Cards
              purchased
              online, may be hosted, managed and administrated by a third-party affiliate of More Rewards Travel and not
              by
              More Rewards Travel directly.</p>

            <p>When a Travel Card is purchased online, it will be activated and ready for your use when it is sent to
              you.</p>

            <p>The travel services available through the More Rewards Travel Centre will change from time to time and some
              types of travel services may not be available. Specific tours or services may be cancelled by travel service
              suppliers without prior notice. Suppliers may also change prices, travel itineraries, flight times and dates
              without prior notice.</p>

            <h4>Card Service Fees and Expiration</h4>
            <p>More Rewards Travel does not charge any service fees relating to the issuance, activation or use of your
              Travel
              Card. Your Travel Card will not expire.</p>

            <h4>Card Balance</h4>
            <p>You can check the balance of your Travel Card by calling the More Rewards Travel Centre at
              1-844-320-3997.</p>

            <h4>Billing Errors, Corrections</h4>
            <p>More Rewards Travel reserves the right to adjust and correct the balance on your Travel Card in the event
              we
              believe that an administrative, accounting or billing error has occurred. If you have questions regarding
              any
              Travel Card transactions or any correction, or if you dispute any transaction or correction that has been
              assessed against your Travel Card, please call the More Rewards Travel Centre at 1-844-320-3997. More
              Rewards
              Travel shall have no liability for any billing error unless you provide us notice within sixty (60) days of
              the
              date of the transaction in question. Please monitor your transactions and card balances closely.</p>

            <h4>Liability for Unauthorized Transactions</h4>
            <p>Your Travel Card should be treated like cash. You will be responsible for all transactions relating to your
              Travel Card, whether authorized or not. You will not be entitled to a refund of any kind in the event your
              Travel Card is lost, misplaced or stolen.</p>

            <h4>Amendments to this Agreement</h4>
            <p>We may amend the terms or conditions of this Agreement at any time, including any rights or obligations you
              or
              we may have. We will post the terms and conditions of the amended Agreement on <a
                href="https://www.morerewards.ca">www.morerewards.ca</a>. As
              permitted
              by applicable law, any amendment will become effective at the time we post the amended Agreement on&nbsp;
              <a href="https://www.morerewards.ca">www.morerewards.ca</a> or as otherwise stated in the amended Agreement.
              Unless we state otherwise, the amendment
              will apply to your future and existing Travel Cards. You are deemed to accept the amendments if (1) you do
              not
              notify us to the contrary in writing within thirty (30) days of the date the notice of amendments is posted
              on&nbsp;
              <a href="https://www.morerewards.ca">www.morerewards.ca</a>, or (2) you use your Travel Card after such
              notice
              is posted.</p>

            <h4>Suspension or Termination of this Agreement</h4>
            <p>We may suspend or terminate this Agreement in whole or in part at any time and for any reason or no reason
              without notice or liability to you, including in connection with the termination of the Travel Card
              program.</p>

            <h4>Governing Law</h4>
            <p>This Agreement shall be governed by and construed in accordance with the laws of the Province of British
              Columbia, Canada, notwithstanding any conflict of law rules.</p>

            <h4>Arbitration</h4>
            <p>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS RIGHTS THAT YOU MAY OTHERWISE HAVE. IT PROVIDES FOR
              RESOLUTION
              OF
              MOST DISPUTES THROUGH ARBITRATION INSTEAD OF COURT TRIALS AND CLASS ACTIONS. ARBITRATION IS FINAL AND
              BINDING
              AND SUBJECT TO ONLY VERY LIMITED REVIEW BY A COURT. THIS ARBITRATION CLAUSE SHALL SURVIVE TERMINATION OF
              THIS
              AGREEMENT.</p>

            <h4>Binding Arbitration</h4>
            <p>This provision is intended to be interpreted broadly to encompass all disputes or claims arising out of our
              relationship. Any dispute or claim made by you against us (or against any of our subsidiary, parent or
              affiliate
              companies) arising out of or relating to this Agreement or your use of the Travel Card (whether based in
              contract, tort, statute, fraud, misrepresentation or any other legal theory) will be resolved by binding
              arbitration except that (a) you may take claims to small claims court if they qualify for hearing by such a
              court, or (b) you or we may choose to pursue claims in court if the claims relate solely to the collection
              of
              any debts you owe to us. However, even for those claims that may be taken to court, you and we both waive
              any
              claims for punitive damages and any right to pursue claims on a class or representative basis.</p>

            <h4>Arbitration Procedures</h4>
            <p>You must first present any claim or dispute to us by contacting our Customer Relations Department to allow
              us
              an
              opportunity to resolve the dispute. You may request arbitration if your claim or dispute cannot be resolved
              within sixty (60) days. The arbitration of any dispute or claim shall be conducted in accordance with the
              Arbitrations Act as modified by this Agreement. Unless you and we agree otherwise, any arbitration will take
              place in Vancouver, British Columbia, and will be conducted in the English language. An arbitrator may not
              award
              relief in excess of or contrary to what this Agreement provides, order consolidation or arbitration on a
              class
              wide or representative basis, or award punitive damages or any other damages aside from the prevailing
              party's
              actual damages, except that the arbitrator may award on an individual basis damages required by statute and
              may
              order injunctive or declaratory relief pursuant to an applicable consumer protection statute. Any
              arbitration
              shall be confidential, and neither you nor we may disclose the existence, content or results of any
              arbitration,
              except as may be required by law or for purposes of enforcement of the arbitration award. Judgment on any
              arbitration award may be entered in any court having proper jurisdiction. If any portion of this arbitration
              clause is determined by a court to be inapplicable or invalid, than the remainder shall still be given full
              force and effect.</p>

            <h4>Costs of Arbitration</h4>
            <p>All administrative fees and expenses of an arbitration will be divided equally between you and us. In all
              arbitrations, each party will bear the expense of its own counsel, experts, witnesses and preparation and
              presentation of evidence at the arbitration.</p>

            <h4>Waiver of Punitive Damage Claims and Class Actions</h4>
            <p>By entering into this Agreement, both you and More Rewards Travel are waiving certain rights to litigate
              disputes in court. If for any reason this arbitration clause is deemed inapplicable or invalid, you and More
              Rewards Travel both waive, to the fullest extent allowed by law, any claims to recover punitive or exemplary
              damages and any right to pursue any claims on a class or consolidated basis or in a representative
              capacity.</p>

            <h4>Disclaimers and Limits of Liability</h4>
            <p>MORE REWARDS TRAVEL MAKES NO REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED,
              WITH
              RESPECT TO THE MORE REWARDS TRAVEL CARD, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS
              OF
              MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, OR ANY WARRANTY ARISING
              BY
              USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE. SOME PROVINCES/JURISDICTIONS DO NOT ALLOW THE
              LIMITATION OF IMPLIED WARRANTIES, SO THIS MAY NOT BE APPLICABLE TO YOU. MORE REWARDS DOES NOT REPRESENT OR
              WARRANT THAT YOUR TRAVEL CARD WILL ALWAYS BE ACCEPTED OR THE TRAVEL CARD PROGRAM WILL ALWAYS BE ACCESSIBLE.
              IN
              THE EVENT THAT MORE REWARDS TRAVEL OR ITS AFFILIATES ARE FOUND LIABLE TO YOU, YOU SHALL ONLY BE ENTITLED TO
              RECOVER ACTUAL AND DIRECT DAMAGES AND SUCH DAMAGES SHALL NOT EXCEED THE LAST BALANCE HELD ON YOUR MORE
              REWARDSTRAVEL CARD PRIOR TO THE TIME AT WHICH SUCH DAMAGES AROSE. MORE REWARDS TRAVEL AND ITS AFFILIATES
              SHALL
              HAVE NO LIABILITY FOR ANY INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES (INCLUDING
              WITHOUT
              LIMITATION LOSS OF PROFIT, REVENUE, USE OR DATA) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT,
              WHETHER IN CONTRACT (INCLUDING FUNDAMENTAL BREACH), WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE,
              PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, EVEN IF WE OR OUR AUTHORIZED
              REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL MORE REWARDS TRAVEL
              OR
              ITS AFFILIATES HAVE ANY LIABILITY FOR UNAUTHORIZED ACCESS TO, OR ALTERATION, THEFT OR DESTRUCTION OF A MORE
              REWARDS TRAVEL CARD THROUGH ACCIDENT, MISUSE, OR FRAUDULENT MEANS OR DEVICES BY YOU OR ANY THIRD PARTY, OR
              AS
              A
              RESULT OF ANY DELAY OR MISTAKE RESULTING FROM ANY CIRCUMSTANCES BEYOND OUR CONTROL. SOME
              PROVINCES/JURISDICTIONS
              DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU.</p>

            <h4>Assignment</h4>
            <p>We may assign all or part of this Agreement without notice to you. We are then released from all liability.
              The
              assignee shall have the same rights and obligations as the assignor formerly had and shall agree in writing
              to
              be bound by the terms and conditions of this Agreement.</p>

            <h4>Entire Agreement</h4>
            <p>This Agreement is the complete and exclusive statement of agreement between you and More Rewards Travel,
              and
              supersedes and merges all prior proposals and all other agreements. In the event that any provision of this
              Agreement shall be determined to be illegal or unenforceable, that provision will be eliminated to the
              minimum
              extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable.
              Headings herein are for convenience of reference only and shall in no way affect interpretation of this
              Agreement.</p>

            <h4>Questions or Notices</h4>
            <p>If you have any questions or wish to send us any notice regarding this Agreement or your Travel Card,
              please
              visit <a href="https://www.morerewards.ca">www.morerewards.ca</a> or call the Travel Centre at
              1-844-320-3997.
            </p>
          </div>
        </Container>
        <PageFooter isLogin={false} theme={theme}/>
      </>
    );
  }
;
export default moreRewardsTravelGiftCardConditions;