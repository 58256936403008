import {GlobalContextType} from '../../../contexts/types/global';
import React from 'react';
import {NavigateFunction} from 'react-router';
import {TFunction} from 'i18next';
import {OauthStepCallBacks} from '../one-time-pass-types';
import {
  FRAuth,
  FRLoginFailure,
  FRLoginSuccess,
  FRStep,
  FRUser,
  TokenManager
} from '@forgerock/javascript-sdk';
import {
  getStep,
  continueBiometricFlowUnregisteredDevice,
  handleSSOTokenExchange, handleAMErrorsAndResetLoginFormState, resetBiometricState,
} from '../../../network-requests/authentication';
import {OAuth2Tokens} from "../../../network-requests/network-request-types";
import {
  getRecaptchaScore,
  GoogleGraphQLData
} from '../../../network-requests/resources';
import {
  RECAPTCHA_V3_KEY,
  RECAPTCHA_THRESHOLD,
  RECAPTCHA_V2_KEY,
  LINK_CHOICES_PROD,
  REACT_APP_LINK_SOF_PROD,
  REACT_APP_LINK_PSF_PROD,
  REACT_APP_LINK_UF_PROD,
  REACT_APP_LINK_MR_PROD,
  REACT_APP_LINK_MY_OFFERS_PROD,
  LINK_MR_TRAVEL,
  LINK_QF,
  BIOMETRIC_REGISTER_DEVICE,
  SIGN_IN_BIOMETRICS, EMAIL_REGEX,
  LINK_MR_OFFERS_ENV_PREFIX
} from '../../../util/constants';
import {oneTimePassValidator} from '../../../util/validator';
import {RegInfoType} from "../../../contexts/registration";

export type EnqueueSnackbar = (
  message: SnackbarMessage,
  options?: OptionsObject
) => SnackbarKey;
export type Theme =
  'choices'
  | 'saveonfoods'
  | 'pricesmartfoods'
  | 'urbanfare'
  | 'morerewards'
  | 'myoffers'
  | 'travel'
  | 'qualityfoods';
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import {LoginStateType} from "../loginTypes";
import {generateAttentionMessage} from "../../../components/notifications/errors/generate-attention-message";
import pushRouteChangeEvent, {pushSpecificEvent} from "../../../util/google-tag-manager";

declare const window: any;

/* performs safety check in case there is no valid v3 recaptcha score or success on page mount
 * if this is detected, it will grab this recaptcha score then perform a V2 check if for
 * whatever reason this has not been set on page mount for any reason at all */
const submitButton = async (
  loginState: LoginStateType,
  enqueueSnackbar: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  globalContext: GlobalContextType,
  buttonTitle?: string,
  setGlobalContext?: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  setErrorLogin?: React.Dispatch<React.SetStateAction<string>>
): Promise<void> => {
  // Handle when we want to set our login state to handle biometric login
  if (buttonTitle === BIOMETRIC_REGISTER_DEVICE || buttonTitle === SIGN_IN_BIOMETRICS) {
    pushSpecificEvent('Start biometric login');
    await getStep(setGlobalContext, globalContext, setErrorLogin);
    changeBiometricLoginState(loginState, setLoginState, true);
  } else {
    changeBiometricLoginState(loginState, setLoginState, false);
  }
  if (!loginState.recaptchaScore.score) {
    await verifyUser(
      null,
      enqueueSnackbar,
      null,
      loginState,
      setLoginState
    );
  }
  handleRememberMe(loginState, globalContext);
  setLoginState((prev: LoginStateType) => ({ ...prev, loginFlowSuccessful: true, }));
};

const setSpEntityIdFromTheme = async (
  theme: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
) => {
  switch (theme) {
    case 'saveonfoods':
      return setLoginState((prev: LoginStateType) => ({ ...prev, spEntityId: 'saveonfoods' }));
    case 'choices':
      return setLoginState((prev: LoginStateType) => ({ ...prev, spEntityId: 'choicesmarkets' }))
    case 'pricesmartfoods':
      return setLoginState((prev: LoginStateType) => ({ ...prev, spEntityId: 'pricesmartfoods' }))
    case 'urbanfare':
      return setLoginState((prev: LoginStateType) => ({ ...prev, spEntityId: 'urbanfare' }))
    case 'morerewards':
      return setLoginState((prev: LoginStateType) => ({ ...prev, spEntityId: 'morerewards' }))
  }
}

// sets the initial step in the OAuth flow
const getAmCallback = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  isAuth: void | OAuth2Tokens,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>
): Promise<FRStep | FRLoginSuccess | FRLoginFailure> => {
  try {
    if (!isAuth) {
      return await getStep(setGlobalContext, globalContext, setErrorLogin);
    }
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

const handleValidationFailure = (
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  message: string,
  enqueueSnackbar: any
) => {
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    loading: false,
    loginFlowSuccessful: false
  }));
  return enqueueSnackbar(message, { variant: 'error' });
};

const initializeSpEntityId = async (
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  theme: string,
) => {
  await setSpEntityIdFromTheme(theme, setLoginState)
}

const validationOnSubmit = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  navigate: NavigateFunction,
  setIsAuth: any,
  isAuth: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  closeSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  try {
    await setLoginState((prev: LoginStateType) => ({ ...prev, loading: true, }));

    // Destructure all of our login state variables for ease of use
    const { password } = loginState;
    const { email } = globalContext.loginInformation;
    const { hasPasswordCallbackDuringRegistration, enabled } = loginState.biometricsState;

    // If the email is not valid and the user isn't on the email + password page, do not proceed
    if (email.length === 0 && !hasPasswordCallbackDuringRegistration) {
      return handleValidationFailure(
        setLoginState,
        'Please enter a valid email address',
        enqueueSnackbar
      );
    }

    // If the password is not valid in any case do not proceed
    if (password.length === 0 && !enabled) {
      return handleValidationFailure(
        setLoginState,
        'Please enter a valid password',
        enqueueSnackbar
      );
    }

    // lower score than threshold, but not checked
    if (
      // v3 recaptcha score is less than threshold
      loginState.recaptchaScore.success === true && loginState.recaptchaScore.score < RECAPTCHA_THRESHOLD &&
      // v2 recaptcha is not successful
      loginState.recaptchaV2Response.success === null
    ) {
      return handleValidationFailure(
        setLoginState,
        'Oops, you have to check the recaptcha box first',
        enqueueSnackbar
      );
    }

    // If the user is a robot, do not proceed
    if (
      // v3 recaptcha score is less than threshold
      (loginState.recaptchaScore.success === true && loginState.recaptchaScore.score < RECAPTCHA_THRESHOLD) &&
      // v2 recaptcha is not successful
      (loginState.recaptchaV2Response.success !== null && loginState.recaptchaV2Response.success === false)
    ) {
      return handleValidationFailure(
        setLoginState,
        'You appear to be a robot',
        enqueueSnackbar
      );
    }

    // Continue biometrics flow for unregistered device after collecting the users password
    if (enabled && hasPasswordCallbackDuringRegistration) {
      // If the password is not valid in any case do not proceed
      if (password.length === 0) {
        return handleValidationFailure(
          setLoginState,
          'Please enter a valid password.',
          enqueueSnackbar
        );
      }
      return await continueBiometricFlowUnregisteredDevice(
        globalContext,
        loginState,
        setLoginState,
        setGlobalContext,
        enqueueSnackbar
      );
    }

    // If the user is on the biometrics login page, we need to register the device or authenticate them with their biometrics
    if (enabled) {
      return handleSSOTokenExchange(
        globalContext,
        setGlobalContext,
        enqueueSnackbar,
        navigate,
        setIsAuth,
        isAuth,
        setErrorLogin,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        loginState,
        setLoginState,
        'biometrics'
      );
    }

    // Otherwise if we get here we can assume the user is performing a regular login flow with email + password
    if (!enabled) {
      await handleSSOTokenExchange(
        globalContext,
        setGlobalContext,
        enqueueSnackbar,
        navigate,
        setIsAuth,
        isAuth,
        setErrorLogin,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        loginState,
        setLoginState,
        'standardAuth'
      );

      return setLoginState((prev: LoginStateType) => ({ ...prev, loginFlowSuccessful: false }));
    }

    // If we get here just assume something went wrong and reset the login state
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      loading: false,
      loginFlowSuccessful: false
    }));
  } catch (e: any) {
    console.error(e);
    enqueueSnackbar('Unexpected error: Please try again.', { variant: 'error' });
    setLoginState((prev: LoginStateType) => ({ ...prev, loading: false, }));
  }
};

// https://www.reddit.com/r/walmart/comments/kawndm/how_in_the_hell_does_walmartca_have_better_bot */
const verifyUser = (
  event: any,
  enqueueSnackbar: any,
  loginFlowRecaptchaFailed: boolean,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  try {
    if (window.grecaptcha != undefined) {
      if (!loginState.recaptchaScore.success) {
        window.grecaptcha.ready(((event: any) => {
          window.grecaptcha
            .execute(RECAPTCHA_V3_KEY, { action: 'SSOFlow' })
            .then(async (V3token: any) => {
              await getRecaptchaScore(
                V3token,
                loginFlowRecaptchaFailed,
                enqueueSnackbar,
                setLoginState
              ); // v3 recaptcha, return captcha score
            });
        }));
      }
    }
  } catch (e: any) {
    console.error(`Error: ${e}`);
    return enqueueSnackbar('Something went wrong when confirming you are not a robot. ' +
      'Please try log again later', { variant: 'error' });
  }
};

// if score is below threshold prompt the V2 checkbox, initialized after v3 score is retrieved on component mount
const verifyV2Recaptcha = async (
  recaptchaScore: GoogleGraphQLData,
  verifyCallback: Function,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  enqueueSnackbar: any
): Promise<void> => {
  if (recaptchaScore.score <= RECAPTCHA_THRESHOLD) {
    await window.grecaptcha.ready(() => {
      window.grecaptcha.render('html_element', {
        'sitekey': RECAPTCHA_V2_KEY,
        'callback': verifyCallback,
        'expired-callback':
          () => {
            // set recaptchaV2Response to null if expired
            setLoginState((prev: LoginStateType) => ({ ...prev, recaptchaV2Response: { success: null, challenge_ts: null, hostname: null } }))
            enqueueSnackbar('Verification expired. Check the checkbox again.', { variant: 'error' });
          }
      });
    });
  }
};

const verifyV3RecaptchaScore = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  verifyCallback: Function,
  navigate: NavigateFunction,
  setIsAuth: any,
  isAuth: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  closeSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  if (loginState.recaptchaScore.success && !loginState.recaptchaFlowFailedOnMount) {
    await verifyV2Recaptcha(loginState.recaptchaScore, verifyCallback, loginState, setLoginState, enqueueSnackbar);
  } else if (loginState.recaptchaScore && loginState.recaptchaFlowFailedOnMount) {
    await verifyV2Recaptcha(loginState.recaptchaScore, verifyCallback, loginState, setLoginState, enqueueSnackbar);
    await validationOnSubmit(
      globalContext,
      setGlobalContext,
      enqueueSnackbar,
      navigate,
      setIsAuth,
      isAuth,
      setErrorLogin,
      closeSnackbar,
      setRegistrationContext,
      t,
      theme,
      loginState,
      setLoginState
    );
  }
};

const populateOTPCallbacks = async (
  password: string,
  globalContext: GlobalContextType,
  oneTimePass: string,
  type: string
): Promise<any> => {
  let oauthStepCallbacks: OauthStepCallBacks = globalContext.oAuthStep.callbacks;
  const newOauthStepWithOTP: any = globalContext.oAuthStep;
  for (let i = 0; i < oauthStepCallbacks.length; i++) {
    if (oauthStepCallbacks[i].payload.type === 'PasswordCallback' || oauthStepCallbacks[i].payload.type === 'ChoiceCallback') {
      for (let j = 0; j < oauthStepCallbacks[i].payload.input.length; j++) {
        if (oauthStepCallbacks[i].payload.input[j].name === 'IDToken2' && type === 'verify') {
          newOauthStepWithOTP.payload.callbacks[i].input[j].value = oneTimePass;
        }
        if (oauthStepCallbacks[i].payload.input[j].name === 'IDToken3' && type === 'verify') {
          newOauthStepWithOTP.payload.callbacks[i].input[j].value = 0;
        }
        if (oauthStepCallbacks[i].payload.input[j].name === 'IDToken2' && type === 'resend') {
          newOauthStepWithOTP.payload.callbacks[i].input[j].value = ' ';
        }
        if (oauthStepCallbacks[i].payload.input[j].name === 'IDToken3' && type === 'resend') {
          newOauthStepWithOTP.payload.callbacks[i].input[j].value = 1;
        }
      }
    }
  }
  return newOauthStepWithOTP;
};

const handleCancelOTP = async (
  revert: any,
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  FRUser.logout().then(() => {
    TokenManager.deleteTokens().then(() => {
      revert();
    });
  });
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    password: '',
    loading: false,
    loginFlowSuccessful: false,
    OTPState: {
      ...prev.OTPState,
      displayOTP: false
    }
  }));
  const newInitialStep: any = await FRAuth.start();
  return setGlobalContext((prev: GlobalContextType) => ({
    ...prev,
    oAuthStep: newInitialStep,
    sessionTimedOut: false
  }));
};

const handleTextFieldChange = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setOneTimePass: React.Dispatch<React.SetStateAction<string>>,
  setOneTimePassHelperText: React.Dispatch<React.SetStateAction<string>>,
  t: any
): Promise<void> => {
  if (event.target.value.match(/[^\d]/)) {
    return;
  }
  setOneTimePass(event.target.value);
  setOneTimePassHelperText(oneTimePassValidator(event.target.value, t));
};

const navigateOTPErrors = (nextStep: any) => {
  for (let callback of nextStep.payload.callbacks) {
    if (callback.type === 'MetadataCallback') {
      for (let singleOutput of callback.output) {
        if (singleOutput.value.message) {
          return singleOutput.value.message;
        }
      }
    }
  }
  return null;
};

// https://stackoverflow.com/questions/67150620/react-js-loading-indicator-with-a-delay-and-anti-flickering
const handleSubmitOTP = async (
  password: string,
  oneTimePass: string,
  setOneTimePassHelperText: React.Dispatch<React.SetStateAction<string>>,
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  t: TFunction
): Promise<void> => {
  let shown;
  const showTimer = setTimeout(() => {
    shown = true;
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: true,
      }
    }))
  }, 100);
  if (oneTimePassValidator(oneTimePass, t).length !== 0) {
    clearTimeout(showTimer);
    return setOneTimePassHelperText(oneTimePassValidator(oneTimePass, t));
  }
  if (globalContext.oAuthStep?.type !== 'Step') {
    enqueueSnackbar('Something went wrong with this request', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: false,
      }
    }))
  }
  const newOauthStepWithOTP = await populateOTPCallbacks(
    password,
    globalContext,
    oneTimePass,
    'verify'
  );
  if (newOauthStepWithOTP === 'OTP callbacks invalid') {
    enqueueSnackbar('Something went wrong with this', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: false,
      }
    }))
  }
  const nextStep: FRStep | FRLoginSuccess | FRLoginFailure = await FRAuth.next(newOauthStepWithOTP);
  if (!nextStep) {
    enqueueSnackbar('Something went wrong with this request', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: false,
      }
    }))
  }
  if (nextStep.type === 'LoginSuccess') {
    clearTimeout(showTimer);
    return setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      SSOAuthenticated: true,
      oAuthStep: nextStep,
      OTPTimedOut: false
    }));
  }
  if (nextStep.type === 'LoginFailure' && nextStep.payload.message === 'Password is invalid') {
    clearTimeout(showTimer);
    return handleAMErrorsAndResetLoginFormState(
      setGlobalContext,
      nextStep,
      enqueueSnackbar,
      'OTP has expired, please click resend',
      setLoginState
    );
  }
  // @ts-ignore
  const OTPError = navigateOTPErrors(nextStep);
  if (OTPError) {
    clearTimeout(showTimer);
    return handleAMErrorsAndResetLoginFormState(
      setGlobalContext,
      nextStep,
      enqueueSnackbar,
      OTPError,
      setLoginState
    );
  }
  enqueueSnackbar('Issue verifying OTP', { variant: 'error' });
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    OTPState: {
      ...prev.OTPState,
      OTPLoading: false,
    }
  }))
};

const handleSubmitNewOTPEmail = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  password: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
) => {
  let shown;
  const showTimer = setTimeout(() => {
    shown = true;
    setLoginState((prev: LoginStateType) => ({
      ...prev, OTPState: { ...prev.OTPState, resendButtonLoading: true, }
    }))
  }, 100);
  if (globalContext.oAuthStep?.type !== 'Step') {
    enqueueSnackbar('Something went wrong with this request', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev, OTPState: { ...prev.OTPState, resendButtonLoading: false, }
    }));
  }
  const newOTPEmailSubmitStep = await populateOTPCallbacks(password, globalContext, null, 'resend');
  if (newOTPEmailSubmitStep === 'OTP callbacks invalid') {
    enqueueSnackbar('Something went wrong with this', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev, OTPState: { ...prev.OTPState, resendButtonLoading: false, }
    }));
  }
  const nextStep: FRStep | FRLoginSuccess | FRLoginFailure = await FRAuth.next(newOTPEmailSubmitStep);
  if (!nextStep) {
    enqueueSnackbar('Something went wrong with this request', { variant: 'error' });
    clearTimeout(showTimer);
    return setLoginState((prev: LoginStateType) => ({
      ...prev, OTPState: { ...prev.OTPState, resendButtonLoading: false, }
    }));
  }
  // @ts-ignore
  const OTPError = navigateOTPErrors(nextStep);
  if (OTPError) {
    clearTimeout(showTimer);
    return handleAMErrorsAndResetLoginFormState(
      setGlobalContext,
      nextStep,
      enqueueSnackbar,
      OTPError,
      setLoginState
    );
  }
  setGlobalContext((prev: GlobalContextType) => ({
    ...prev,
    oAuthStep: nextStep,
    OTPTimedOut: false
  }));
  setLoginState((prev: LoginStateType) => ({
    ...prev, OTPState: { ...prev.OTPState, resendButtonLoading: false, }
  }));
  return enqueueSnackbar('New one-time password has been sent to the email address linked ' +
    'with your More Rewards account', { variant: 'success' });
};

const disableOTPPage = async (
  enqueueSnackbar: any,
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
) => {
  setLoginState((prev: LoginStateType) => ({ ...prev, OTPState: { ...prev.OTPState, displayOTP: false } }));
  enqueueSnackbar('You took too long to enter your OTP, please log in again', { variant: 'error' });
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    password: '',
    loading: false,
    loginFlowSuccessful: false
  }));
  const newInitialStep: any = await FRAuth.start();
  return setGlobalContext((prev: GlobalContextType) => ({
    ...prev,
    oAuthStep: newInitialStep,
    sessionTimedOut: false
  }));
};

const ThemeUrls: Record<Theme, string> = {
  'choices': LINK_CHOICES_PROD,
  'saveonfoods': REACT_APP_LINK_SOF_PROD,
  'pricesmartfoods': REACT_APP_LINK_PSF_PROD,
  'urbanfare': REACT_APP_LINK_UF_PROD,
  'morerewards': REACT_APP_LINK_MR_PROD,
  'myoffers': REACT_APP_LINK_MY_OFFERS_PROD,
  'travel': LINK_MR_TRAVEL,
  'qualityfoods': LINK_QF
}

const getProductionUrlForTheme = (theme: Theme): string => {
  if (theme in ThemeUrls) {
    return ThemeUrls[theme];
  }
  throw new Error(`Unknown theme: ${theme}`);
}

const getSubdomainFromUrl = (): string => {
  const urlOnLoad = window.location.href;
  const { hostname } = new URL(urlOnLoad);
  return hostname.split(".")[1];
};

// Helper function to show the user a notification on the login page if they end up in the QA env
const showQaNotificationIfApplicable = (enqueueSnackbar: EnqueueSnackbar, theme: Theme): void => {
  try {
    const subdomain: string = getSubdomainFromUrl();
    if (['local', 'qa', 'dev'].some(value => subdomain.includes(value))) {
      const productionUrl: string = getProductionUrlForTheme(theme);
      const attentionMessage: JSX.Element = generateAttentionMessage(productionUrl);
      enqueueSnackbar(attentionMessage, { variant: 'warning', autoHideDuration: null });
    }
  } catch (e) {
    console.error(e);
  }
};

// Helper function to update biometrics state and set 'submitting' to false
const updateBiometricsSubmissionState = (
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  value: boolean
) => {
  try {
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      biometricsState: {
        ...prev.biometricsState,
        submitting: value,
      }
    }));
  } catch (e: any) {
    console.error(e);
  }
};

const handleSessionTimeout = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  isAuth: OAuth2Tokens | void,
  setIsAuth: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  navigate: NavigateFunction,
  closeSnackbar: any,
  enqueueSnackbar: any
) => {
  try {
    if (!globalContext.sessionTimedOut) {
      return; // Return early if session is not timed out
    }

    // If the user was in the middle of a biometric login flow, we need to continue that flow
    if (loginState.biometricsState.enabled) {
      return handleSSOTokenExchange(
        globalContext,
        setGlobalContext,
        enqueueSnackbar,
        navigate,
        setIsAuth,
        isAuth,
        setErrorLogin,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        loginState,
        setLoginState,
        'biometrics'
      );
    }

    // Otherwise, we can assume the user was in the middle of a standard login flow
    handleSSOTokenExchange(
      globalContext,
      setGlobalContext,
      enqueueSnackbar,
      navigate,
      setIsAuth,
      isAuth,
      setErrorLogin,
      closeSnackbar,
      setRegistrationContext,
      t,
      theme,
      loginState,
      setLoginState,
      'standardAuth'
    );
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// initial login page mount - TODO: move this to a helper file
const initialLoad = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  isAuth: OAuth2Tokens | void,
  enqueueSnackbar: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  loginState: LoginStateType,
) => {
  try {
    sessionStorage.removeItem('sentChangeEmail');
    if (localStorage.getItem('rememberMe') !== null && localStorage.getItem('rememberMe').match(EMAIL_REGEX)) {
      changeLoginInfoEmail(globalContext, setGlobalContext, localStorage.getItem('rememberMe'));
      // @ts-ignore
      document.getElementById('email').value = localStorage.getItem('rememberMe');
      setLoginState((prev: LoginStateType) => ({
        ...prev,
        rememberMe: true,
      }));
    } else if (localStorage.getItem('userLastSignedInWithBiometric') !== null && localStorage.getItem('userLastSignedInWithBiometric').match(EMAIL_REGEX)) {
      changeLoginInfoEmail(globalContext, setGlobalContext, localStorage.getItem('userLastSignedInWithBiometric'));
      // @ts-ignore
      document.getElementById('email').value = localStorage.getItem('userLastSignedInWithBiometric');
      setLoginState((prev: LoginStateType) => ({
        ...prev,
        rememberMe: false,
      }));
    } else {
      setLoginState((prev: LoginStateType) => ({
        ...prev,
        rememberMe: false,
      }));
    }
    if (localStorage.getItem('userLastSignedInWithBiometric') !== null && localStorage.getItem('userLastSignedInWithBiometric').match(EMAIL_REGEX)) {
      changeBiometricLoginState(loginState, setLoginState, true);
    } else {
      await resetBiometricState(
        globalContext,
        loginState,
        setLoginState,
        setGlobalContext,
        enqueueSnackbar,
        null
      )
    }
    await getAmCallback(globalContext, setGlobalContext, isAuth, setErrorLogin);
    await verifyUser(
      null,
      enqueueSnackbar,
      null,
      loginState,
      setLoginState
    );
  } catch (e) {
    console.error(e);
  }
};

const pageMount = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  isAuth: OAuth2Tokens | void,
  enqueueSnackbar: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  loginState: LoginStateType,
) => {
  await initialLoad(
    globalContext,
    setGlobalContext,
    isAuth,
    enqueueSnackbar,
    setLoginState,
    setErrorLogin,
    loginState
  );
  await pushRouteChangeEvent('Login: LoginPage');
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    OTPState: {
      ...prev.OTPState,
      displayOTP: false
    },
    loginFlowSuccessful: false
  }))
};

// TODO: refactor this later to be in the helpers.tsx file for this component
const handleSubmit = (
  event: React.SyntheticEvent,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  enqueueSnackbar: any,
  globalContext: GlobalContextType
) => {
  event.preventDefault();
  submitButton(
    loginState,
    enqueueSnackbar,
    setLoginState,
    globalContext
  );
};

const handleRememberMe = (
  loginState: LoginStateType,
  globalContext: GlobalContextType
) => {
  if (loginState.rememberMe || loginState.biometricsState.enabled) {
    localStorage.setItem('rememberMe', globalContext.loginInformation.email);
  } else {
    localStorage.removeItem('rememberMe');
  }
};

/* Handle when a user wants to submit the login form using a key press
 * TODO: refactor this later to be in the helpers.tsx file for this component */
const handleKeyPress = (
  event: React.KeyboardEvent<HTMLDivElement>,
  loginState: LoginStateType,
  globalContext: GlobalContextType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  errorLogin: boolean,
  enqueueSnackbar: any,
) => {
  if (
    event.key === 'Enter' && (
      !errorLogin &&
      loginState.password.length > 0 &&
      globalContext.loginInformation.email.length > 0
    )
  ) {
    handleSubmit(
      event,
      loginState,
      setLoginState,
      enqueueSnackbar,
      globalContext
    );
  }
};

const changeBiometricLoginState = (
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  value: boolean
) => {
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    biometricsState: {
      ...prev.biometricsState,
      enabled: value,
    }
  }));
}

const changeLoginInfoEmail = (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  value: string
) => {
  setGlobalContext((prev: GlobalContextType) => ({
    ...prev,
    loginInformation: {
      ...prev.loginInformation,
      email: value
    }
  }));
}

/*
* Check if the URL has the desired goto parameter
* This function is used to check if the URL has the desired goto parameter
* which is used to redirect the user to the My Offers page after login.
*
* @param { string } url - URL to check
*/
const hasDesiredGotoParam = (url: string): boolean => {
  const url_prefix = LINK_MR_OFFERS_ENV_PREFIX;
  const pattern = new RegExp(`goto=(https%3A%2F%2F|https:\/\/)${url_prefix}\\.morerewards\\.ca(%2Fmy-offers|\/my-offers)`);
  const pattern2 = new RegExp(`(https%3A%2F%2F|https:\/\/)${url_prefix}\\.morerewards\\.ca(%2Fmy-offers|\/my-offers)`);
  return pattern.test(url) || pattern2.test(url);
};

const getOffersGotoUrl = (searchParams: URLSearchParams): string => {
  const goto = searchParams.get('goto');
  if (goto) {
    const decodedGoto = decodeURIComponent(goto);
    const url = new URL(decodedGoto);

    // Append all other parameters from searchParams to the URL
    searchParams.forEach((value, key) => {
      if (key !== 'goto') {
        url.searchParams.append(key, value);
      }
    });

    return url.toString();
  }
  return '';
};

const getUrlPath = (urlString: string): string => {
  try {
    const url = new URL(urlString);
    return url.origin + url.pathname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
};

export {
  getAmCallback,
  validationOnSubmit,
  verifyUser,
  verifyV2Recaptcha,
  submitButton,
  verifyV3RecaptchaScore,
  handleSubmitOTP,
  handleCancelOTP,
  handleTextFieldChange,
  handleSubmitNewOTPEmail,
  disableOTPPage,
  setSpEntityIdFromTheme,
  showQaNotificationIfApplicable,
  updateBiometricsSubmissionState,
  handleSessionTimeout,
  initialLoad,
  pageMount,
  handleKeyPress,
  changeBiometricLoginState,
  initializeSpEntityId,
  hasDesiredGotoParam,
  getOffersGotoUrl,
  getUrlPath
};