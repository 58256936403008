// const getPreviousLocation = () => {
//   return sessionStorage.getItem('previous-window-location') || 'None';
// };
// const setPreviousLocation = (location: string) => {
//   sessionStorage.setItem('previous-window-location', location);
// };

import {getSessionReferer} from "./referer";

const prefix = 'data-analytics-';

type MetaData = {
  prid?: string,
  banner?: string,
  trafficType?: string
}
export const setDataAnalMetaData = (options: MetaData) => {
  options.hasOwnProperty('prid') && localStorage.setItem(prefix + 'prid', options.prid);
  options.hasOwnProperty('banner') && localStorage.setItem(prefix + 'banner', options.banner);
  options.hasOwnProperty('trafficType') && localStorage.setItem(prefix + 'trafficType', options.trafficType);
};

export const getDataAnalMetaData = (): MetaData => {
  return {
    prid: localStorage.getItem(prefix + 'prid'),
    banner: localStorage.getItem(prefix + 'banner'),
    trafficType: localStorage.getItem(prefix + 'trafficType')
  };
};

export const pushRouteChangeEvent = (page: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({ 'event': 'routeChange', 'page': page, ...getDataAnalMetaData() });
  // setPreviousLocation(page);
};

export const pushSpecificEvent = (event: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({ 'event': event, ...getDataAnalMetaData() });
};

// logic to detect device type
export const getTrafficTypeFromUserClient = (): string => {
  // @ts-ignore
  let standalone = window.navigator.standalone;
  let userAgent = window.navigator.userAgent.toLowerCase();
  let safari = /safari/.test(userAgent);
  let ios = /iphone|ipod|ipad/.test(userAgent);
  if (!navigator.userAgent) { // detect if there is no user agent (?)
    return 'unknown';
  } else if (userAgent.includes('wv') || (ios && !standalone && !safari)) { // detect android and iOS webview
    return 'app';
  } else { // detect user is on a browser (chrome, safari, edge etc...)
    return 'web';
  }
};

export const getBannerForDataLayer = (): string => {
  let banner: string = 'morerewards';
  if (getSessionReferer() !== undefined && getSessionReferer() !== null && getSessionReferer() !== 'undefined') {
    banner = getSessionReferer();
  }
  return banner;
}
export default pushRouteChangeEvent;