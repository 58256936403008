// @ts-ignore
import Barcode from 'react-barcode';

type PropType = {
  card: string
  ac: string
}


const getCheckDigit = (numberStr: string): number => {
  if (numberStr.length != 11) {
    return -1;
  }
  let odd = 0;
  let even = 0;
  for (let i = 0; i < numberStr.length; i++) {
    let curr = parseInt(numberStr.charAt(i));
    if (i % 2 == 1) {
      even = even + curr;
    } else {
      odd = odd + curr;
    }
  }
  let sum = (odd * 3) + even;

  return sum % 10 != 0
    ? 10 - (sum % 10)
    : 0;
};

export const CardDisplay = (props: PropType) => {
  // const barCode = useMemo(() => props.card + getCheckDigit(props.card), []);
  const barCode = props.card + getCheckDigit(props.card);

  return <div className="mrCard">
    <div className="barCode">
      <Barcode
      value={barCode}
      displayValue={false}
      height={50} />
    </div>  

    <div className="mrNumber">{props.card.substring(0, 3)} {props.card.substring(3, 7)} {props.card.substring(7, 11)}</div>
    <div className="mrAC">{props.ac}</div>
  </div>;
};

export default CardDisplay;