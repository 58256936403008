import {handleThemeRedirection} from "../../../pages/my-account/myAccountHelpers";
import {Link, Typography} from "@mui/material";
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../../util/constants";
import React from "react";

export const displayAccountLockedError = (enqueueSnackbar: any, theme: string): any => {
  setTimeout(() => {
    handleThemeRedirection(theme, null); // Redirect user back to Banner site after 10 seconds when account is locked
  }, 10000);
  return enqueueSnackbar(
    <>
      <Typography
        sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
        Account is locked. Please wait 15 minutes or&nbsp;
        <Link
          href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
          target="_blank"
          className="link-white"
        >contact us
        </Link>
      </Typography>
    </>,
    { variant: 'error' }
  )
}