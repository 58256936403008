import React from 'react';
import { Typography, Link } from '@mui/material';
import {LINK_FAQ_LOGIN_PAGE} from "../../util/constants";

type LoginExplanationProps = {
  theme: string; // Pass the referer as a prop
  isWebview: boolean; // Pass the isWebview as a prop
};

const LoginExplanation: React.FC<LoginExplanationProps> = (props: LoginExplanationProps) => {
  const { theme, isWebview } = props;

  return (
    <div>
      {theme === 'choices' ? (
        <Typography component="p" className="confirmation small-text">
          Choices Markets is part of the Pattison Food Group, and shares a sign in platform with
          morerewards.ca, saveonfoods.com, urbanfare.com, and pricesmartfoods.com. Use your same
          email and password to sign in to any of these websites.
        </Typography>
      ) : (
        <Typography component="p" className="welcome">
          More Rewards ID allows you to securely use the same email and password across all our partner websites and apps.
          <br /><br />
          {!isWebview && (
            <Link
              display="inline"
              href={LINK_FAQ_LOGIN_PAGE}
              target="_blank"
              className="need-help-link link-white link-tamewhite"
            >
              Learn more
            </Link>
          )}
        </Typography>
      )}
    </div>
  );
};

export default LoginExplanation;