import {GlobalContextType} from "../../../contexts/types/global";
import {RegInfoType} from "../../../contexts/registration";
import {TFunction} from "i18next";
import {Link, Typography} from "@mui/material";
import {resetPasswordLogin} from "../reset-password-on-notification/resetPasswordLogin";

export const displayNeedToResetPasswordError = (
  enqueueSnackbar: any,
  closeSnackbar: any,
  globalContext: GlobalContextType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string
): any => {
  return enqueueSnackbar(
    <>
      <Typography
        sx={{
          marginRight: '5px',
          marginLeft: '5px',
          cursor: 'pointer'
        }}
        onClick={() => {
          closeSnackbar();
        }}>
        You must reset your password to continue. To reset now please <Typography
        sx={{fontWeight: 'bold', textDecoration: 'underline'}}>
        <Link onClick={() => resetPasswordLogin(
          globalContext.loginInformation.email,
          enqueueSnackbar,
          setRegistrationContext,
          t,
          theme
        )} className="clickHere link-white">Click here</Link>
      </Typography>
      </Typography>
    </>,
    { variant: 'error' }
  )
}