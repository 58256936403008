import React, {useRef, useState} from 'react';
import IdleTimer from 'react-idle-timer';
import {IdeTimerContainerProps} from '../../pages/my-account/myAccountTypes';
import {Box, Button, Modal} from '@mui/material';
import {keepSessionActive, confirmDialogueLogout, onIdleInLogoutModal} from './helperFunctions/helpers';
import './idleTimer.css';

const style = {
  position: 'absolute' as 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4
};

// Container with all the client side logic for when a user goes idle
export default function IdeTimerContainer(props: IdeTimerContainerProps) {
  const { theme } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const idleTimerRef: any = useRef(null);
  const sessionTimeoutRef: any = useRef(null);
  const handleOpen = () => setModalOpen(false);
  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={5*60*1000} onIdle={() => onIdleInLogoutModal(
        handleOpen,
        sessionTimeoutRef,
        theme
      )}/>
      <div>
        <Modal
          className={"idleWidth"}
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2>You've been idle for a while!<br/> You will be logged out soon</h2>
            <div className="idleTimerButtons">
              <Button
                className="primaryButtonLogout"
                onClick={() => confirmDialogueLogout(
                  handleClose,
                  sessionTimeoutRef,
                  theme
                )}>Sign me out</Button>
              <Button
                className="primaryButtonDefault"
                onClick={() => keepSessionActive(handleClose, sessionTimeoutRef)}>Keep me signed
                in
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}