import {Navigate} from 'react-router-dom';
import React from 'react';
import {protectedRouteProps} from '../../../App';
import {CLIENT_ID} from '../../../util/constants';

export const UnProtectedRoute = (props: protectedRouteProps) => {
  const { children } = props;
  if (JSON.parse(localStorage.getItem(CLIENT_ID))) {
    return <Navigate to="/userPage" replace/>;
  }
  return children;
};