import React from 'react';
import {convertDateOfBirth} from "../../../../util/graphql";
import {updateUsersPhoneOnly, updateUsersProfile} from '../../../../network-requests/resources';
import {formatPhoneNumber} from '../../../../util/phoneFormatter';
import {
  PhoneErrors,
  ProfileResponse,
  UpdatedNumbers,
  CustomerData
} from "../../myAccountTypes";
import {PhoneNumbers} from '../profile';
import {Attribute, Months, ProfileInfoType} from '../../../../contexts/types/myAccount';
import {
  ALTERNATE_1_TYPE_CODE,
  ALTERNATE_2_TYPE_CODE,
  PHONE_REGEX,
  PRIMARY_TYPE_CODE
} from '../../../../util/constants';
import {NavigateFunction} from 'react-router';
import {GlobalContextType} from '../../../../contexts/types/global';
import {DOBFields, NameFields} from "../textFields";
import {AutocompleteValue} from "@mui/material";

// Handle when the user makes changes ot their phone numbers but clicks cancel button to revert this
const cancelChanges = async (
  event: any,
  setPhoneEditState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  phoneEditInitialState: PhoneNumbers,
  nameFields: NameFields,
  setNameFields: React.Dispatch<React.SetStateAction<NameFields>>,
  setDobFields: React.Dispatch<React.SetStateAction<DOBFields>>,
  profileInfo: ProfileInfoType
) => {
  const profileInfoDobHasData: boolean = Boolean(profileInfo.dateOfBirth?.day || profileInfo.dateOfBirth?.month  || profileInfo.dateOfBirth?.year );
  let resetDOB: boolean = true;
  if (profileInfoDobHasData) resetDOB = false; // When the users profile already has a valid DOB, don't show cancel button
  // Revert name fields state for one time name change
  setNameFields((prev: NameFields) => ({
    ...prev,
    firstName: nameFields.firstNameInitialState,
    firstNameHelperText: '',
    lastName: nameFields.lastNameInitialState,
    lastNameHelperText: ''
  }));
  // Revert DOB fields on cancel button click - only do this for profiles with no Date of Birth Already
  if (resetDOB) {
    setDobFields((prev: any) => ({
      ...prev,
      dobYear: '',
      dobMonth: '',
      dobDay: '',
      dobHelperText: ''
    }));
  }
  // Revert all permutations of phone fields when primary was empty
  if (!phoneEditInitialState.alternate1 && !phoneEditInitialState.alternate2 && !phoneEditInitialState.primary) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: '',
      alternate1: '',
      alternate2: ''
    }));
  }
  if (!phoneEditInitialState.primary && phoneEditInitialState.alternate1 && phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: '',
      alternate1: phoneEditInitialState.alternate1,
      alternate2: phoneEditInitialState.alternate2
    }));
  }
  if (!phoneEditInitialState.primary && !phoneEditInitialState.alternate1 && phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: '',
      alternate1: '',
      alternate2: phoneEditInitialState.alternate2
    }));
  }
  if (!phoneEditInitialState.primary && phoneEditInitialState.alternate1 && !phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: '',
      alternate1: phoneEditInitialState.alternate1,
      alternate2: ''
    }));
  }
  // Revert all permutations of phone fields when primary was not empty
  if (phoneEditInitialState.primary && !phoneEditInitialState.alternate1 && !phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phoneEditInitialState.primary,
      alternate1: '',
      alternate2: ''
    }));
  }
  if (phoneEditInitialState.primary && phoneEditInitialState.alternate1 && phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phoneEditInitialState.primary,
      alternate1: phoneEditInitialState.alternate1,
      alternate2: phoneEditInitialState.alternate2
    }));
  }
  if (phoneEditInitialState.primary && !phoneEditInitialState.alternate1 && phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phoneEditInitialState.primary,
      alternate1: '',
      alternate2: phoneEditInitialState.alternate2
    }));
  }
  if (phoneEditInitialState.primary && phoneEditInitialState.alternate1 && !phoneEditInitialState.alternate2) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phoneEditInitialState.primary,
      alternate1: phoneEditInitialState.alternate1,
      alternate2: ''
    }));
  }
};

const displayCancelButton = (
  phoneEditState: PhoneNumbers,
  phoneEditInitialState: PhoneNumbers,
  setShowCancelButton: React.Dispatch<React.SetStateAction<boolean>>,
  nameFields: NameFields,
  dobFields: DOBFields,
  profileInfo: ProfileInfoType
): void => {
  let dobFieldsHaveInputtedData: boolean = Boolean(dobFields.dobYear || dobFields.dobDay || dobFields.dobMonth)
  const profileInfoDobHasData: boolean = Boolean(profileInfo.dateOfBirth?.day || profileInfo.dateOfBirth?.month  || profileInfo.dateOfBirth?.year );
  if (profileInfoDobHasData) dobFieldsHaveInputtedData = false; // When the users profile already has a valid DOB, don't show cancel button
  if ((phoneEditState.primary === phoneEditInitialState.primary) &&
    (phoneEditState.alternate1 === phoneEditInitialState.alternate1) &&
    (phoneEditState.alternate2 === phoneEditInitialState.alternate2) &&
    (nameFields.firstName === nameFields.firstNameInitialState &&
      nameFields.lastName === nameFields.lastNameInitialState
    ) && (!dobFieldsHaveInputtedData)) {
    setShowCancelButton(false);
  } else {
    setShowCancelButton(true);
  }
};

/* Sort the phone numbers which come back from the API after the user edits their phone. make sure
 * primary, alternate1 and alternate2 numbers are grabbed with the correct type codes */
const sortPhoneNumbersFromAPI = (customerData: CustomerData) => {
  const updatedNumbers: PhoneNumbers = {
    primary: null,
    alternate1: null,
    alternate2: null,
  };
  for (const customer of customerData) {
    switch(customer.typeCode) {
      case PRIMARY_TYPE_CODE:
        updatedNumbers.primary = customer.value;
        break;
      case ALTERNATE_1_TYPE_CODE:
        updatedNumbers.alternate1 = customer.value;
        break;
      case ALTERNATE_2_TYPE_CODE:
        updatedNumbers.alternate2 = customer.value;
        break;
    }
  }
  if (!updatedNumbers.primary) {
    updatedNumbers.primary = '';
  }
  if (!updatedNumbers.alternate1) {
    updatedNumbers.alternate1 = '';
  }
  if (!updatedNumbers.alternate2) {
    updatedNumbers.alternate2 = '';
  }
  return updatedNumbers;
};

const updateNameChangeAttributesAfterEdit = (
  nameChangeAttributes: Attribute[],
) => {
  const updatedAttributes: Attribute[] = [];
  for (let attribute of nameChangeAttributes) {
    updatedAttributes.push({
      'id': attribute.id,
      'value': 'confirmed',
      'typeCode': attribute.typeCode
    })
  }
  return updatedAttributes;
}

const verifyNameHasChanged = (
  nameFields: NameFields,
  profileInfo: ProfileInfoType
) => {
  return nameFields.firstName !== profileInfo.firstName || nameFields.lastName !== profileInfo.lastName;
}

const handleChangeEdit = async (
  setLoadingPhoneNumberChange: React.Dispatch<React.SetStateAction<boolean>>,
  setPhoneEditState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  setPhoneEditInitialState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  updateProfileInfo: any,
  phone: any,
  updatePhone: any,
  phoneEditState: any,
  profileInfo: ProfileInfoType,
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  nameFields: NameFields,
  setNameFields: React.Dispatch<React.SetStateAction<NameFields>>,
  setOneTimeEditDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  fromDialogue: boolean,
  theme: string,
  dobFields: DOBFields | null,
  setDisableDOBFields: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoadingPhoneNumberChange(true);
  try {
    let response: ProfileResponse;
    const nameHasChanged: boolean = verifyNameHasChanged(nameFields, profileInfo);
    const profileInfoDobHasData: boolean = Boolean(
      profileInfo.dateOfBirth?.day ||
      profileInfo.dateOfBirth?.month  ||
      profileInfo.dateOfBirth?.year
    );
    const dobHasChanged: boolean = !profileInfoDobHasData && Boolean(
      dobFields?.dobYear && dobFields?.dobDay && dobFields?.dobMonth
    );
    const firstTimeNameEdit: boolean = isFirstTimeNameEdit(profileInfo.nameChangeAttributes);
    if ((firstTimeNameEdit && !fromDialogue && nameHasChanged) || (dobHasChanged && !fromDialogue)) {
      setLoadingPhoneNumberChange(false);
      return setOneTimeEditDialogue(true);
    }
    if (nameHasChanged || dobHasChanged) {
      response = await updateUsersProfile(
        phoneEditState,
        profileInfo,
        nameFields,
        theme,
        nameHasChanged,
        dobFields
      );
    } else {
      response = await updateUsersPhoneOnly(phoneEditState, profileInfo, theme);
    }
    if (!response.data) {
      setLoadingPhoneNumberChange(false);
      return enqueueSnackbar('Something went wrong updating your profile', { variant: 'error' });
    }
    const sortedPhoneNumbers: UpdatedNumbers = sortPhoneNumbersFromAPI(response.data.customer.updateContacts);
    if (nameHasChanged || dobHasChanged) {
      await updateProfileInfo((prev: ProfileInfoType) => ({
        ...prev,
        nameChangeAttributes: updateNameChangeAttributesAfterEdit(profileInfo.nameChangeAttributes),
        firstName: response.data.customer.updateCustomer.firstName.trim(),
        lastName: response.data.customer.updateCustomer.lastName.trim()
      }));
      await setNameFields((prev: NameFields) => ({
        ...prev,
        firstName: response.data.customer.updateCustomer.firstName.trim(),
        firstNameHelperText: '',
        lastName: response.data.customer.updateCustomer.lastName.trim(),
        lastNameHelperText: ''
      }));
      updateProfileInfo((prev: ProfileInfoType) => ({
        ...prev,
        dateOfBirth: convertDateOfBirth(response.data.customer.updateCustomer.dateOfBirth)
      }));
      setDisableDOBFields(true);
    }
    await updatePhone((prev: PhoneNumbers) => ({
      ...prev,
      primary: sortedPhoneNumbers.primary,
      alternate1: sortedPhoneNumbers.alternate1,
      alternate2: sortedPhoneNumbers.alternate2
    }));
    await setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: sortedPhoneNumbers.primary,
      alternate1: sortedPhoneNumbers.alternate1,
      alternate2: sortedPhoneNumbers.alternate2
    }));
    await setPhoneEditInitialState((prev: PhoneNumbers) => ({
      ...prev,
      primary: sortedPhoneNumbers.primary,
      alternate1: sortedPhoneNumbers.alternate1,
      alternate2: sortedPhoneNumbers.alternate2
    }));
    nameHasChanged || dobHasChanged ? enqueueSnackbar('Updated your profile', { variant: 'success' }) :
      enqueueSnackbar('Updated your Phone Number', { variant: 'success' });
    setLoadingPhoneNumberChange(false);
  } catch (e) {
    enqueueSnackbar('Sorry something went wrong updating your profile', { variant: 'error' });
    setLoadingPhoneNumberChange(false);
    console.error(e);
  }
};

// https://stackoverflow.com/questions/9011524/regex-to-check-whether-a-string-contains-only-numbers
export const validatePhoneNumberText = (
  phoneNumber: string
) => {
  if (!phoneNumber) {
    return true;
  }
  if (phoneNumber.length < 10 && phoneNumber.length > 0) {
    return false;
  }
  return PHONE_REGEX.test(phoneNumber); // regex to check phone only contains numbers
};

const validatePhoneFields = (
  phoneEditState: PhoneNumbers,
  setPhoneEditErrors: React.Dispatch<React.SetStateAction<PhoneErrors>>,
  type: string
) => {
  if (type !== 'register' && (!phoneEditState.primary)) { // check that the primary phone number is entered
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      primaryEmpty: {
        ...prev.primaryIncorrectlyEntered,
        value: 'Primary phone is required',
        disableSaveButton: true
      }
    }));
  }
  if (!validatePhoneNumberText(phoneEditState.primary)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      primaryIncorrectlyEntered: {
        ...prev.primaryIncorrectlyEntered,
        value: 'Invalid phone number',
        disableSaveButton: true
      }
    }));
  }
  if (!validatePhoneNumberText(phoneEditState.alternate1)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      alternate1PhoneIncorrectlyEntered: {
        ...prev.alternate1PhoneIncorrectlyEntered,
        value: 'Invalid phone number',
        disableSaveButton: true
      }
    }));
  }
  if (!validatePhoneNumberText(phoneEditState.alternate2)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      alternate2PhoneIncorrectlyEntered: {
        ...prev.alternate1PhoneIncorrectlyEntered,
        value: 'Invalid phone number',
        disableSaveButton: true
      }
    }));
  }
};

// Logic to handle when a phone field changes back to being valid, update frontend
const resetPhoneFieldsWhenValid = (
  phoneEditState: PhoneNumbers,
  setPhoneEditErrors: React.Dispatch<React.SetStateAction<PhoneErrors>>
) => {
  if (phoneEditState.primary.length !== 0) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      primaryEmpty: {
        ...prev.primaryIncorrectlyEntered,
        value: null,
        disableSaveButton: false
      }
    }));
  }
  if (validatePhoneNumberText(phoneEditState.primary)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      primaryIncorrectlyEntered: {
        ...prev.primaryIncorrectlyEntered,
        value: null,
        disableSaveButton: false
      }
    }));
  }
  if (validatePhoneNumberText(phoneEditState.alternate1)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      alternate1PhoneIncorrectlyEntered: {
        ...prev.alternate1PhoneIncorrectlyEntered,
        value: null,
        disableSaveButton: false
      }
    }));
  }
  if (validatePhoneNumberText(phoneEditState.alternate2)) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      alternate2PhoneIncorrectlyEntered: {
        ...prev.alternate1PhoneIncorrectlyEntered,
        value: null,
        disableSaveButton: false
      }
    }));
  }
};

// Hydrate the state in the user's profile with the data from Customer Accounts
const initialPageLoad = (
  setInitialLoad: React.Dispatch<React.SetStateAction<boolean>>,
  setPhoneEditInitialState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  setPhoneEditState: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  profileInfo: ProfileInfoType,
  phone: any,
  setNameFields: React.Dispatch<React.SetStateAction<NameFields>>,
  setDisableNameFields: React.Dispatch<React.SetStateAction<boolean>>,
  setDisableDOBFields: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setDisableNameFields(disableFirstTimeNameEdit(profileInfo.nameChangeAttributes));
  if (profileInfo) {
    setPhoneEditState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phone.primary,
      alternate1: phone.alternate1,
      alternate2: phone.alternate2
    }));
    setPhoneEditInitialState((prev: PhoneNumbers) => ({
      ...prev,
      primary: phone.primary,
      alternate1: phone.alternate1,
      alternate2: phone.alternate2
    }));
    setNameFields((prev: NameFields) => ({
      ...prev,
      firstName: profileInfo.firstName,
      firstNameInitialState: profileInfo.firstName,
      lastName: profileInfo.lastName,
      lastNameInitialState: profileInfo.lastName
    }));
    setInitialLoad(true);
    if (profileInfo.dateOfBirth) setDisableDOBFields(true);
  }
};

// Format all of the phone numbers stored in state with dashes as the user edits them
const updatePhoneNumbers = (
  setFormattedPhoneNumber: React.Dispatch<React.SetStateAction<PhoneNumbers>>,
  phoneEditState: PhoneNumbers
) => {
  const formattedPrimary: string = formatPhoneNumber(phoneEditState.primary);
  const formattedAlternate1: string = formatPhoneNumber(phoneEditState.alternate1);
  const formattedAlternate2: string = formatPhoneNumber(phoneEditState.alternate2);
  setFormattedPhoneNumber((prev: PhoneNumbers) => ({
    ...prev,
    primary: formattedPrimary,
    alternate1: formattedAlternate1,
    alternate2: formattedAlternate2
  }));
};

const validateEditableFields = async (
  phoneEditState: PhoneNumbers,
  setPhoneEditErrors: React.Dispatch<React.SetStateAction<PhoneErrors>>,
  type: string
) => {
  await validatePhoneFields(phoneEditState, setPhoneEditErrors, type);
  await resetPhoneFieldsWhenValid(phoneEditState, setPhoneEditErrors);
};

const isFirstTimeNameEdit = (nameChangeAttributes: Attribute[]) => {
  for (let attribute of nameChangeAttributes) {
    if (attribute.value === 'unconfirmed') {
      return true;
    }
  }
  return false;
}

// if no entry exists with 72 type code in cd.identifications for this customer, disable name edit fields
const disableFirstTimeNameEdit = (nameChangeAttributes: Attribute[]) => {
  if (nameChangeAttributes.length === 0) return true; // handle new MR accounts where this array is empty
  for (let attribute of nameChangeAttributes) { // safety check. ensure a stale access token 'hasPostMerged' status !== 'confirmed'
    if (attribute.value !== 'unconfirmed') {
      return true;
    }
  }
  return false;
}

const handleDobMonthChange = (
  select: AutocompleteValue<any, any, any, any>,
  setDobFields: React.Dispatch<React.SetStateAction<DOBFields>>
) => {
  if (select) {
    setDobFields((prev: DOBFields) => ({
      ...prev,
      dobMonth: select.label,
      dobHelperText: ''
    }));
  } else {
    setDobFields((prev: DOBFields) => ({
      ...prev,
      dobMonth: null,
      dobHelperText: ''
    }));
  }
}

// Converts DOB from DD-MONTH-YY to DD-MM-YYY
const convertDob = (dob: DOBFields): string => {
  const months: Record<string, string> = {
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12',
  };
  return `${dob.dobYear}-${months[dob.dobMonth as keyof Months]}-${dob.dobDay}`
}

export {
  cancelChanges,
  handleChangeEdit,
  displayCancelButton,
  initialPageLoad,
  updatePhoneNumbers,
  validateEditableFields,
  disableFirstTimeNameEdit,
  updateNameChangeAttributesAfterEdit,
  handleDobMonthChange,
  convertDob
};