import React, {useEffect} from 'react';
import {signOutAllSPs} from './logout';

export type MobileWebViewProps = {
  goto: string,
  theme: string
}

export default function LogoutAllSPsAndWebView(props:  MobileWebViewProps) {
  const {goto, theme} = props;

  useEffect(() => {
    signOutAllSPs(goto, theme); // take goto from url provided by inventive and feed it into cb function
  }, []);

  return (
    <>
    </>
  );
}