import React, {FormEvent, useContext, useEffect, useState} from 'react';
import RegistrationLayout from '../register/layout/registration-layout';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {emailValidator} from '../../util/validator';
import {RegistrationContext} from '../../contexts/registration';
import {GlobalContext} from '../../contexts/global';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import {InputLabel} from '@mui/material';
import {useSnackbar} from 'notistack';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import {handleSubmitForgotPassword} from "./helpers";

export type ForgotPasswordPageProps = {
  theme: string
}

/**
 * Page to email user to change their password
 * @constructor
 */
const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  const { theme } = props;
  const { globalContext, } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t, ready } = useTranslation();
  const { setRegistrationContext } = useContext(RegistrationContext);
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');

  useEffect(() => {
    pushRouteChangeEvent('Register: ForgotPasswordPage');
  }, []);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <form
        className={globalContext.referer === 'choices' ? "choicesWhiteBox" : ""}
        noValidate
        onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmitForgotPassword(
          event,
          email,
          setEmailHelperText,
          setRegistrationContext,
          setEmailIsSent,
          enqueueSnackbar,
          t,
          theme
        )}>
        <Typography variant="h2" component="h2" className={theme === "choices" ? "topHeaderChoices" : "topHeader"}>
          {t('REGISTER.HEADERS.FORGOT_PASSWORD')}
        </Typography>
        {theme === 'choices'
          ?
          <Typography
            component="p"
            variant="body2"
            className="topTextRegisterChoices">
            {t('REGISTER.FORGOT_PASSWORD.DESC_ON_CHOICES')}
          </Typography>
          :
          <Typography
            component="p"
            variant="body2"
            className="topTextRegister">
            {globalContext.isWebview ?
              t('REGISTER.FORGOT_PASSWORD.DESC_ON_APP') :
              t('REGISTER.FORGOT_PASSWORD.DESC')
            }
          </Typography>
        }
        <div className="centeredContainer">
          <div className="innerColumn">
            <div className="noBG-form">
              <InputLabel required className="inputLabel inputLabelWhite">{t('BASIC.EMAIL')}</InputLabel>
              <TextField
                className="fullWidth"
                required
                id="email"
                name="email"
                autoComplete="email"
                // autoFocus
                error={emailHelperText !== ''}
                helperText={emailHelperText}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailHelperText(emailValidator(event.target.value, t));
                  setEmailIsSent(false);
                }}
                inputProps={{ 'aria-label': 'Email Address text field', }}
              />
            </div>
          </div>
        </div>
        <div className="centeredContainer account-steps-nav-cancel">
          <Button
            type="submit"
            variant="contained"
            className="primaryButtonDefault"
            disabled={emailIsSent}
          >{t('BUTTONS.SUBMIT')}
          </Button>
          {<Button
            variant="outlined"
            className="cancelButtonDefault"
            onClick={() => {
              navigate('/')
            }}
          >{t('BUTTONS.CANCEL')}</Button>}
        </div>
      </form>
    </RegistrationLayout>
  );
};

export default ForgotPasswordPage;