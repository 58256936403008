import React from 'react';
import {Checkboxes, MyAccountType, ProfileInfoType} from '../../../../contexts/types/myAccount';
import {CheckboxState} from '../emailSubscriptionsTypes';

// logic to compare the initial checkbox snapshots state against the state of any checkbox changes
const compareCheckboxesAgainstInitalSnapshot = function (
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  checkboxState: CheckboxState
): void {
  let matching: boolean = true;
  Object.entries(checkboxState.initialCheckboxesState).map(([key]) => {
    if (
      checkboxState.initialCheckboxesState[key as keyof Checkboxes] !==
      checkboxState.initialCheckboxesSnapshot[key as keyof Checkboxes]
    ) {
      matching = false;
    }
  });
  setCheckboxState((prev: CheckboxState) => ({
    ...prev,
    checkboxSnapshotsMatch: matching
  }));
};

// logic to ensure all checkboxes are unchecked
const ensureAnyCheckboxesAreChecked = function (
  initialCheckboxesState: Checkboxes
): boolean {
  let anyCheckboxesAreChecked: boolean = false;
  Object.entries(initialCheckboxesState).map(([key]) => {
    if (initialCheckboxesState[key as keyof Checkboxes] === true) {
      anyCheckboxesAreChecked = true;
    }
  });
  return anyCheckboxesAreChecked;
};

// check that any of the checkboxes are selected
const verifyAnyCheckboxesAreSelected = function (
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  checkboxState: CheckboxState
): void {
  if (ensureAnyCheckboxesAreChecked(checkboxState.initialCheckboxesState)) {
    setCheckboxState((prev: CheckboxState) => ({
      ...prev,
      anyCheckboxesAreSelected: true
    }));
  } else {
    setCheckboxState((prev: any) => ({
      ...prev,
      anyCheckboxesAreSelected: false
    }));
  }
  compareCheckboxesAgainstInitalSnapshot(setCheckboxState, checkboxState);
};

// handle checkbox change logic for single checkboxes when clicked on
const handleCheckboxChange = function (
  event: React.ChangeEvent<HTMLInputElement>,
  title: string,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
): void {
  switch (title) {
    case 'More Rewards':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          moreRewardsProgram: event.target.checked
        }
      }));
      break;
    case 'Save-On-Foods':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          saveOnFoodsFlyerSpecials: event.target.checked
        }
      }));
      break;
    case 'Special Offers + Meal inspiration':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          specialOffersMealPrep: event.target.checked
        }
      }));
      break;
    case 'Urban Fare':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          urbanFareSpecials: event.target.checked
        }
      }));
      break;
    case 'PriceSmart Foods':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          priceSmartSpecials: event.target.checked
        }
      }));
      break;
    case 'Quality Foods':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          qualityFoodsSpecials: event.target.checked
        }
      }));
      break;
    case 'Surveys':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          surveys: event.target.checked
        }
      }));
      break;
  }
};

// logic to select or unselect all checkboxes
const selectOrUnselectAll = function (
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  type: string
): void {
  switch (type) {
    case 'checkAll':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        anyCheckboxesAreSelected: true,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          moreRewardsProgram: true,
          saveOnFoodsFlyerSpecials: true,
          specialOffersMealPrep: true,
          urbanFareSpecials: true,
          priceSmartSpecials: true,
          qualityFoodsSpecials: true,
          surveys: true
        }
      }));
      break;
    case 'uncheckAll':
      setCheckboxState((prev: CheckboxState) => ({
        ...prev,
        anyCheckboxesAreSelected: false,
        initialCheckboxesState: {
          ...prev.initialCheckboxesState,
          moreRewardsProgram: false,
          saveOnFoodsFlyerSpecials: false,
          specialOffersMealPrep: false,
          urbanFareSpecials: false,
          priceSmartSpecials: false,
          qualityFoodsSpecials: false,
          surveys: false
        }
      }));
  }
};

// set the state for the checkboxes & initial checkbox snapshot using the current myAccountContext
const setInitialCheckBoxState = (
  profileInfo: ProfileInfoType,
  updateProfileInfo: React.Dispatch<React.SetStateAction<CheckboxState>>
): void => {
  const initialCheckBoxesState = {
    moreRewardsProgram: profileInfo.emailSubscriptions.moreRewardsProgram,
    saveOnFoodsFlyerSpecials: profileInfo.emailSubscriptions.saveOnFoodsFlyerSpecials,
    specialOffersMealPrep: profileInfo.emailSubscriptions.specialOffersMealPrep,
    urbanFareSpecials: profileInfo.emailSubscriptions.urbanFareSpecials,
    priceSmartSpecials: profileInfo.emailSubscriptions.priceSmartSpecials,
    qualityFoodsSpecials: profileInfo.emailSubscriptions.qualityFoodsSpecials,
    surveys: profileInfo.emailSubscriptions.surveys
  };
  updateProfileInfo((prev: CheckboxState) => ({
    ...prev,
    initialCheckboxesState: initialCheckBoxesState,
    initialCheckboxesSnapshot: initialCheckBoxesState
  }));
};

export {
  verifyAnyCheckboxesAreSelected,
  selectOrUnselectAll,
  handleCheckboxChange,
  compareCheckboxesAgainstInitalSnapshot,
  setInitialCheckBoxState,
  ensureAnyCheckboxesAreChecked
};