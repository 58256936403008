import React from "react";
import { PasswordInfo } from "../../pages/my-account/change-password/changePasswordTypes";
import {NavigateFunction} from "react-router";
import {GlobalContextType} from "../../contexts/types/global";
import {Button, Grid, IconButton, InputAdornment, InputLabel, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {handleSubmit} from "../password-strength/helper-functions/helpers";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import PasswordChangeConfirmation from "../PasswordConfirmaton/password-confirmation";
import {PasswordStrength} from "../password-strength/passwordStrength";
import './password-input-editor.scss';

type PasswordInputEditorProps = {
  passwordInfo: PasswordInfo,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>,
  searchInput: React.MutableRefObject<any>
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  savingChanges: boolean,
  setSavingChanges: React.Dispatch<React.SetStateAction<boolean>>,
  passwordUpdated: boolean,
  enqueueSnackbar: (message: string, options?: any) => void,
  theme: string,
  setPasswordUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

const PasswordInputEditor: React.FC<PasswordInputEditorProps> = (props: PasswordInputEditorProps) => {
  const {
    passwordInfo,
    setPasswordInfo,
    searchInput,
    navigate,
    setGlobalContext,
    savingChanges,
    setSavingChanges,
    passwordUpdated,
    enqueueSnackbar,
    theme,
    setPasswordUpdated
  } = props;

  return (
    <div className="centeredContainerPassword">
      <div className="innerColumn">
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel className="inputLabel">Current Password*</InputLabel>
            <TextField
              value={passwordInfo.oldPassword.input}
              helperText={passwordInfo.oldPassword.helperText}
              // @ts-ignore
              error={passwordInfo.oldPassword.helperText}
              FormHelperTextProps={{ style: { color: '#e50176' } }}
              type={passwordInfo.showOldPassword ? 'text' : 'password'}
              id="outlined-basic"
              aria-label="Current Password*"
              variant="outlined"
              className="fullWidth"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setPasswordInfo((prev: PasswordInfo) => ({
                  ...prev,
                  oldPassword: {
                    ...prev.oldPassword,
                    input: event.target.value
                  }
                }));
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordInfo((prev: PasswordInfo) => ({
                        ...prev,
                        showOldPassword: !passwordInfo.showOldPassword
                      }))}
                      edge="end"
                    >
                      {passwordInfo.showOldPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel className="inputLabel">New Password*</InputLabel>
            <TextField
              // @ts-ignore
              error={
                passwordInfo.newPassword.helperText ||
                passwordInfo.warning ||
                (passwordInfo.passwordStrengthInformation.strengthMeterText === 'Very weak' ||
                  passwordInfo.passwordStrengthInformation.strengthMeterText === 'Weak') ||
                passwordInfo.suggestions.length > 0 && passwordInfo.newPassword.input.length > 0
              }
              value={passwordInfo.newPassword.input}
              FormHelperTextProps={{ style: { color: '#e50176' } }}
              type={passwordInfo.showNewPassword ? 'text' : 'password'}
              id="outlined-basic"
              aria-label="New Password*"
              variant="outlined"
              className="fullWidth"
              ref={searchInput}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setPasswordInfo((prev: PasswordInfo) => ({
                  ...prev,
                  newPassword: {
                    ...prev.newPassword,
                    input: event.target.value
                  }
                }));
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordInfo((prev: PasswordInfo) => ({
                        ...prev,
                        showNewPassword: !passwordInfo.showNewPassword
                      }))}
                      edge="end"
                    >
                      {passwordInfo.showNewPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Grid>
          {!passwordUpdated &&
            <PasswordStrength
              passwordInfo={passwordInfo}
              setPasswordInfo={setPasswordInfo}
              pageType={'Profile'}
            />
          }
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel className="inputLabel inputLabelTop">Confirm New Password*</InputLabel>
            <TextField
              value={passwordInfo.confirm.input}
              helperText={passwordInfo.helperText || passwordInfo.confirm.helperText}
              // @ts-ignore
              error={passwordInfo.helperText !== '' || passwordInfo.confirm.helperText}
              FormHelperTextProps={{ style: { color: '#e50176' } }}
              type={passwordInfo.showConfirmPassword ? 'text' : 'password'}
              id="outlined-basic"
              aria-label="Confirm New Password*"
              variant="outlined"
              className="fullWidth"
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setPasswordInfo((prev: PasswordInfo) => ({
                  ...prev,
                  confirm: {
                    ...prev.confirm,
                    input: event.target.value
                  }
                }));
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordInfo((prev: PasswordInfo) => ({
                        ...prev,
                        showConfirmPassword: !passwordInfo.showConfirmPassword
                      }))}
                      edge="end"
                    >
                      {passwordInfo.showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {passwordUpdated &&
              <PasswordChangeConfirmation/>
            }
          </Grid>
        </Grid>
      </div>
      <div className="editButtons">
        {savingChanges
          ?
          <LoadingButton
            loading
            className="primaryButtonDefault"
            loadingPosition="start"
            sx={{ mt: 3, mb: 2 }}
            startIcon={<SaveIcon/>}
            variant="outlined"
          >
            Changing your password...
          </LoadingButton>
          :
          <Button
            disabled={
              passwordInfo.passwordStrengthMeterValue <= 50 ||
              !passwordInfo.allRequirementsMet ||
              (
                passwordInfo.passwordStrengthInformation.strengthMeterText === 'very weak' ||
                passwordInfo.passwordStrengthInformation.strengthMeterText === 'weak'
              ) ||
              !passwordInfo.passwordsMatch ||
              passwordInfo.anyInputIsEmpty ||
              !!((
                passwordInfo.confirm.helperText ||
                passwordInfo.oldPassword.helperText ||
                passwordInfo.newPassword.helperText
              )) ||
              passwordUpdated
            }
            type="submit"
            className="primaryButtonDefault"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleSubmit(
              setPasswordInfo,
              passwordInfo,
              setSavingChanges,
              navigate,
              setGlobalContext,
              enqueueSnackbar,
              theme,
              setPasswordUpdated
            )}>
            Save
          </Button>
        }
      </div>
    </div>
  );
};

export default PasswordInputEditor;