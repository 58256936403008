import React, {useContext, useEffect} from 'react';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import PageFooter from '../../components/footer/footer';
import {GlobalContext} from '../../contexts/global';
import {LINK_MR} from "../../util/constants";

export type UnknownPageProps = {
  theme: string
}

export default function UnknownPage(props: UnknownPageProps) {
  const { theme } = props;
  const { globalContext } = useContext(GlobalContext);
  useEffect(() => {
    pushRouteChangeEvent('404: PageNotFound');
  }, []);
  return (
    <>
      <div className="root">
        <div className="page">
          <img
            className="center-img pointer"
            src={globalContext.referer === 'choices' ?
              require('../../images/choices/mobile-logo-choices.png') :
              require('../../images/global-logos/more-rewards.png')
            }
            onClick={() => window.open(`${LINK_MR}`, '_blank')}
            alt="more rewards"
          />
          <h2 className="topHeader marginB40">404 Page not found </h2>
        </div>
      </div>
      <div>
        <PageFooter isLogin={false} theme={theme}/>
      </div>
    </>
  )
}

