import React, {useContext} from 'react';
import Typography from '@mui/material/Typography';
import {Grid, Link} from '@mui/material';
import '../../styling/global/globalStyles.scss';
import '../../styling/components/footer/footer.scss';
import {
  LINK_MR,
  LINK_TERMS_AND_CONDITIONS,
  LINK_TERMS_AND_CONDITIONS_CHOICES,
  LINK_CHOICES, PRIVACY_POLICY_PFG
} from '../../util/constants';
import {GlobalContext} from "../../contexts/global";

export type FooterProps = {
  isLogin: boolean,
  theme: string
}

export default function PageFooter(props: FooterProps): JSX.Element {
  const { isLogin, theme } = props; // leave unused loginPage prop here just in case we need it in the future
  const { globalContext } = useContext(GlobalContext);
  const date = new Date();
  return (
    <>
      {globalContext.isWebview
        ?
        <>
          <footer className='footerWebView'>
          </footer>
        </>
        :
        <>
          <footer className='footer'>
            {/* Login Screen Footer */}
            <div className="centeredContainer">
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="footerLinksContainer">
                    {
                      theme === 'choices' ?
                        <Link
                          href={LINK_CHOICES}
                          target="_blank"
                          className="footerLinks"
                        >
                          Choices Markets
                        </Link>
                        :
                        <Link
                          href={LINK_MR}
                          target="_blank"
                          className="footerLinks"
                        >
                          More Rewards
                        </Link>
                    }
                    <Link
                      href={PRIVACY_POLICY_PFG}
                      target="_blank"
                      className="footerLinks"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      href={theme === 'choices' ? LINK_TERMS_AND_CONDITIONS_CHOICES : LINK_TERMS_AND_CONDITIONS}
                      target="_blank"
                      className="footerLinks">Terms
                    </Link>
                  </div>
                  <Typography
                    component="p"
                    variant="body2"
                    className="copyright"
                  >
                    © {date.getFullYear()} Pattison Food Group. All rights reserved.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </footer>
        </>
      }
    </>
  );
}
