export enum CanadianProvinces {
  'AB' = 'Alberta',
  'BC' = 'British Columbia',
  'MB' = 'Manitoba',
  'NB' = 'New Brunswick',
  'NL' = 'Newfoundland and Labrador',
  'NS' = 'Nova Scotia',
  'NT' = 'Northwest Territories',
  'NU' = 'Nunavut',
  'ON' = 'Ontario',
  'PE' = 'Prince Edward Island',
  'QC' = 'Quebec',
  'SK' = 'Saskatchewan',
  'YT' = 'Yukon',
}

export enum Countries {
  'CA' = 'Canada'
}