import React from 'react';
import {
  Callback,
  FRAuth,
  FRCallback,
  FRLoginFailure,
  FRLoginSuccess,
  FRStep,
  FRUser,
  FRWebAuthn, NameValue,
  TokenManager,
  WebAuthnStepType
} from '@forgerock/javascript-sdk';
import {GlobalContextType} from '../contexts/types/global';
import {NavigateFunction} from 'react-router';
import jwt_decode from 'jwt-decode';
import {
  pushSpecificEvent,
  setDataAnalMetaData
} from '../util/google-tag-manager';
import {getJWTJsonPayload} from '../util/jwt';
import {validateToken} from './resources';
import {logoutOauthSession} from '../pages/login/logout';
import {
  getAmCallback,
  updateBiometricsSubmissionState,
  hasDesiredGotoParam
} from '../pages/login/helpers/helpers';
import dayjs from 'dayjs';
import {Base64} from 'js-base64';
import {
  ACCOUNT_DISABLED,
  ACCOUNT_NEEDS_RESET_PW,
  ACCOUNT_NO_CARD, BIOMETRIC_INPUT_FIELD,
  CLIENT_ID,
  CONFIRMATION_CALLBACK_INPUT_FALSE,
  CONFIRMATION_CALLBACK_INPUT_TRUE,
  NAME_INPUT_FIELD,
  INCORRECT_WEB_AUTH_STEP_TYPE,
  LOGIN_FAILURE,
  PASSWORD_INPUT_FIELD,
  SESSION_HAS_TIMED_OUT,
  UN_PW_INVALID,
  UNEXPECTED_ERROR_BIOMETRIC_PASSWORD,
  UNEXPECTED_ERROR_DEVICE_REG, INVALID_BIOMETRIC_PW
} from '../util/constants';
import {RegInfoType} from "../contexts/registration";
import {TFunction} from "i18next";
import Cookies from "js-cookie";
import {OAuth2Tokens, StepOptions} from "./network-request-types";
import {displayUnexpectedError} from "../components/notifications/errors/display-unexpected-error";
import {displayLoginFailureError} from "../components/notifications/errors/display-login-failure-error";
import {displayNeedToResetPasswordError} from "../components/notifications/errors/display-need-to-reset-password-error";
import {displayInvalidUsernameOrPasswordError} from "../components/notifications/errors/display-invalid-username-or-password-error";
import {displayAccountLockedError} from "../components/notifications/errors/display-account-locked-error";
import {displayAccountNoActiveCardError} from "../components/notifications/errors/display-account-no-active-card-error";
import {LoginStateType} from "../pages/login/loginTypes";

type BiometricsValidationReturnType = {
  boolean: boolean;
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure; // Replace YourTypeHere with the actual type for nextStep
  errorMessage: string | null
};

// https://day.js.org/docs/en/display/unix-timestamp
const setSessionTimeout = (): void => {
  try {
    const isAuth: OAuth2Tokens = JSON.parse(localStorage.getItem(CLIENT_ID));
    const decoded: any = jwt_decode<any>(isAuth.accessToken);
    const currentTimeNow: number = dayjs().unix();
    const oneHourInSeconds: number = decoded.expires_in * 60;
    const sessionEndDate: number = currentTimeNow + oneHourInSeconds;
    const sessionEndDateString: string = sessionEndDate.toString();
    localStorage.setItem('sessionExpireTime', sessionEndDateString);
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

const ensureUsersOAuthStateIsCorrect = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  globalContext: GlobalContextType
): Promise<void> => {
  if (globalContext.oAuthStep?.type === 'LoginSuccess') {
    try {
      await FRUser.logout();
    } catch (e: any) {
      console.error(`Error: request for trying to ensure the user's logout state iss correct; ${e}`);
      throw e;
    }
  }
};

const submitCallBackToAM = async (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure
): Promise<FRStep | FRLoginSuccess | FRLoginFailure> => {
  if (verifyStepTypeValid(nextStep)) {
    return await FRAuth.next(nextStep); // Hit AM with the password callback
  }
}

// initial call to forgerock to set the initial step of auth
const getStep = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  globalContext: GlobalContextType,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>
): Promise<any> => {
  await ensureUsersOAuthStateIsCorrect(setGlobalContext, globalContext);
  try {
    const initialStep: FRStep | FRLoginSuccess | FRLoginFailure = await FRAuth.start();
    setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      oAuthStep: initialStep,
      appBarPageSelected: 'login',
      sessionTimedOut: false,
    }));
    return initialStep;
  } catch (e: any) {
    setErrorLogin('Error loading, please try again.');
    console.error(`Error: request for initial step; ${e}`);
    throw e;
  }
};

// return true if ForgeRock AM responds with an OTP prompt for the user to pass
const verifyOTP = async (callbacks: FRCallback[]) => {
  for (const callback of callbacks) {
    if (callback.payload.type === 'PasswordCallback') {
      for (const singleOutput of callback.payload.output) {
        if (singleOutput.value === 'Enter the OTP') {
          return true;
        }
      }
    }
  }
  return false;
};

const resetOauthStep = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  password: string,
  resetType?: string
): Promise<any> => {
  try {
    // Get the initial step in Oauth flow from AM
    const newInitialStep: any = await FRAuth.start();

    // Set the password callback value for what is needed
    if ('callbacks' in newInitialStep) {
      const passwordSetter: string = resetType === 'biometrics' ? Base64.encode(password) : '';
      newInitialStep.callbacks[0].setInputValue(globalContext.loginInformation.email);
      newInitialStep.callbacks[1].setPassword(passwordSetter);
    }

    // Set the new initial step
    return setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      oAuthStep: newInitialStep,
      sessionTimedOut: resetType !== 'incorrectPassword' // Set the session timeout to false if the user fat-fingered their password, otherwise it's true
    }));
  } catch (e: any) {
    console.error('Exception in resetOauthStep: ', e);
    throw e;
  }
};

const handlePayloadErrorMessage = (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  enqueueSnackbar: any,
  closeSnackbar: any,
  globalContext: GlobalContextType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): void => {
  switch (nextStep.payload.message) {
    case UN_PW_INVALID:
      displayInvalidUsernameOrPasswordError(
        enqueueSnackbar,
        closeSnackbar,
        globalContext,
        setRegistrationContext,
        t,
        theme
      );
      break;
    case ACCOUNT_NEEDS_RESET_PW:
      displayNeedToResetPasswordError(
        enqueueSnackbar,
        closeSnackbar,
        globalContext,
        setRegistrationContext,
        t,
        theme
      )
      break;
    case LOGIN_FAILURE:
      displayLoginFailureError(enqueueSnackbar, theme);
      break;
    case ACCOUNT_DISABLED:
      displayAccountLockedError(enqueueSnackbar, theme);
      break;
    case ACCOUNT_NO_CARD:
      displayAccountNoActiveCardError(enqueueSnackbar, theme);
      break;
    case SESSION_HAS_TIMED_OUT:
      resetBiometricLoginFormState(setLoginState);
      enqueueSnackbar('Oops, you went idle for too long, please log in again', { variant: 'error' });
      break;
    case INVALID_BIOMETRIC_PW:
      enqueueSnackbar('Oops, you entered an incorrect password, please try again', { variant: 'error' });
      break;
    default:
      enqueueSnackbar(nextStep.payload.message, { variant: 'error' });
  }
}

const handleSSOErrors = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  encodedPassword: string,
  enqueueSnackbar: any,
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  navigate: NavigateFunction,
  closeSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<any> => {
  try {
    await resetOauthStep(
      globalContext,
      setGlobalContext,
      encodedPassword,
      'incorrectPassword'
    );
    handlePayloadErrorMessage(
      nextStep,
      enqueueSnackbar,
      closeSnackbar,
      globalContext,
      setRegistrationContext,
      t,
      theme,
      setLoginState
    );
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      loading: false,
      loginFlowSuccessful: false
    }))
  } catch (e) {
    console.error('Exception in handleSSOErrors: ', e);
    throw e;
  }
};

// https://stackoverflow.com/questions/47015693/how-to-fix-throw-of-exception-caught-locally
const checkStep = async (nextStep: FRStep | FRLoginSuccess | FRLoginFailure) => {
  if (!nextStep) {
    throw Error('Error getting the SSO token');
  }
};

export const checkTokens = async (tokens: OAuth2Tokens | void) => {
  if (!tokens) {
    throw Error('Error getting the access token');
  }
};

const breakSSOIfChoicesLogin = async (
  spEntityIds: string[],
  spEntityId: string
) => {
  if (spEntityIds.includes(spEntityId)) {
    localStorage.setItem('spEntityId', spEntityId);
  }
}

export const verifyTheWebAuthnStepType = async (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure
): Promise<WebAuthnStepType> => {
  try {
    if (verifyStepTypeValid(nextStep)) {
      return FRWebAuthn.getWebAuthnStepType(nextStep);
    }
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

const populateBiometricsNewDevicePasswordStep = async (
  oAuthStep: FRStep | FRLoginSuccess | FRLoginFailure,
  password: string
): Promise<FRStep> => {
  try {
    // Ensure the step type is valid
    if (verifyStepTypeValid(oAuthStep)) {
      const callbacks: Callback[] = oAuthStep.payload.callbacks;

      // Loop through the callbacks and find the PasswordCallback
      for (const callback of callbacks) {
        if (callback.type === 'PasswordCallback') {
          const passwordInput: NameValue = callback.input.find( // Set the password in the PasswordCallback
            (input: NameValue) => input.name === 'IDToken2'
          );
          if (passwordInput) {
            passwordInput.value = encodePassword(password);
          }
        }
      }

      return oAuthStep; // Return the updated step
    }
    throw new Error('Invalid step type.');
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

const validateBiometricsNewDevicePasswordStep = async (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure
): Promise<BiometricsValidationReturnType> => {
  try {
    const callbacks: Callback[] = nextStep.payload.callbacks;

    if (nextStep.payload.reason === "Unauthorized") return { boolean: false, nextStep, errorMessage: null };

    if (!nextStep || !nextStep.payload || !nextStep.payload.callbacks) {
      return { boolean: false, nextStep, errorMessage: null }; // Message not found or step doesn't have callbacks
    }

    for (const callback of callbacks) {
      for (const output of callback.output) {
        const message: string = (output.value as any).message;

        switch (message) {
          case UN_PW_INVALID:
            (output.value as any).message = INVALID_BIOMETRIC_PW; // Rename error msg to be more contextual
            return { boolean: false, nextStep, errorMessage: message }; // Message found and updated, password is invalid
          case ACCOUNT_DISABLED:
          case SESSION_HAS_TIMED_OUT:
          case LOGIN_FAILURE:
            return { boolean: false, nextStep, errorMessage: message };
        }
      }
    }
    nextStep.payload.callbacks = callbacks; // Update the callbacks
    return { boolean: true, nextStep, errorMessage: null } ;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const verifyConsentTextOutPutCallback = (
  oAuthStep: FRStep | FRLoginSuccess | FRLoginFailure
) => {
  try {
    const callbacks: Callback[] = oAuthStep.payload.callbacks;
    return callbacks.some((callback: Callback) => {
      if (callback.type === 'TextOutputCallback') {
        return callback.output.some((output: NameValue) => {
          return output.value === "<b>Consent to biometrics</b>";
        });
      }
      return false;
    });
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

const fillInConfirmationCallback = async (
  oAuthStep: FRStep | FRLoginSuccess | FRLoginFailure,
  newDeviceConfirmationCallbackSelection: boolean
): Promise<FRStep | FRLoginSuccess | FRLoginFailure> => {
  try {
    const callbacks: Callback[] = oAuthStep.payload.callbacks;
    for (let callback of callbacks) {
      if (callback.type !== 'ConfirmationCallback') continue;

      const input: NameValue = callback.input.find((input: NameValue) => {
        return input.name === 'IDToken2';
      });

      if (!input) continue;

      input.value = newDeviceConfirmationCallbackSelection ?
        CONFIRMATION_CALLBACK_INPUT_TRUE :
        CONFIRMATION_CALLBACK_INPUT_FALSE
    }
    return oAuthStep;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

const populateBiometricConfirmationCallbacks = async (
  oAuthStep: FRStep | FRLoginSuccess | FRLoginFailure
): Promise<FRStep | FRLoginSuccess | FRLoginFailure> => {
  try {
    if (verifyStepTypeValid(oAuthStep) && verifyConsentTextOutPutCallback(oAuthStep)) {
      oAuthStep = await fillInConfirmationCallback(
        oAuthStep,
        true
      );
      return oAuthStep;
    } // Set the password callback value
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

const resetBiometricState = async (
  globalContext: GlobalContextType,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  message: string | null
) => {
  try {
    await resetOauthStep(
      globalContext,
      setGlobalContext,
      loginState.password,
      'incorrectPassword'
    );
    await resetBiometricLoginFormState(setLoginState);
    localStorage.removeItem('userLastSignedInWithBiometric');
    if (message) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

const handleBiometricDeviceRegAfterPassword = async (
  globalContext: GlobalContextType,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  encodedPassword: string,
  navigate: NavigateFunction,
  closeSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string
) => {
  try {
    let nextStep: any = await populateBiometricsNewDevicePasswordStep(
      globalContext.oAuthStep,
      loginState.password
    );

    nextStep = await submitCallBackToAM(nextStep);
    const passwordValidated = await validateBiometricsNewDevicePasswordStep(nextStep);

    if (!passwordValidated) {
      return await handleSSOErrors(
        globalContext,
        setGlobalContext,
        encodedPassword,
        enqueueSnackbar,
        nextStep,
        navigate,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        setLoginState
      );
    }

    nextStep = await handleWebAuthStep(nextStep, enqueueSnackbar);

    if (nextStep.type !== 'LoginSuccess' && verifyStepTypeValid(nextStep) && await verifyOTP(nextStep.callbacks) !== true) {
      // Check if the user has already registered their biometrics on this device
      const stepType: WebAuthnStepType = await verifyTheWebAuthnStepType(nextStep);
      if (stepType === WebAuthnStepType.Registration) {
        // cancel registration
        setLoginState((prev: LoginStateType) => ({
          ...prev,
          loading: false,
          loginFlowSuccessful: false
        }));
      }
    }

    if (nextStep.type === "LoginSuccess") {
      return await checkSSOSuccessAndGetAccessToken(nextStep, setGlobalContext);
    }

    // If we get here reset the biometric steps and start over
    throw 'Error: unexpected error during biometric registration flow';
  } catch (e: any) {
    await resetBiometricState(
      globalContext,
      loginState,
      setLoginState,
      setGlobalContext,
      enqueueSnackbar,
      UNEXPECTED_ERROR_DEVICE_REG
    );
    console.error(e);
    throw(e);
  }
}

const resetBiometricLoginFormState = (
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
) => {
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    loading: false,
    loginFlowSuccessful: false,
    biometricsState: {
      ...prev.biometricsState,
      submitting: false,
      showNewDeviceConfirmation: false,
      hasPasswordCallbackDuringRegistration: false,
      newDeviceRegisteredWithBiometrics: false,
      enabled: false,
      hideBiometric: false,
    }
  }));
}

const handleResetMetadataCallbacksDeviceReg = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
) => {
  try {
    await setLoginState((prev: LoginStateType) => ({ ...prev, loading: false, }));
    await setGlobalContext((prev: GlobalContextType) => ({ ...prev, oAuthStep: nextStep, }));
  } catch (e: any) {
    console.error(e);
    throw e;
  }
}

const handleAMErrorsAndResetLoginFormState = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  enqueueSnackbar: any,
  OTPError: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
): Promise<void> => {
  await setGlobalContext((prev: GlobalContextType) => ({
    ...prev,
    oAuthStep: nextStep,
    OTPTimedOut: false,
    OTPEmailResendTimedOut: false
  }));
  setLoginState((prev: LoginStateType) => ({
    ...prev,
    loading: false,
    OTPState: {
      ...prev.OTPState,
      OTPLoading: false,
      resendButtonLoading: false,
    }
  }))
  return enqueueSnackbar(OTPError, { variant: 'error' });
};


const continueBiometricFlowUnregisteredDevice = async (
  globalContext: GlobalContextType,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any
): Promise<any> => {
  try {
    pushSpecificEvent('Start registering biometric device');
    let nextStep: FRStep | FRLoginSuccess | FRLoginFailure = await populateBiometricsNewDevicePasswordStep(
      globalContext.oAuthStep,
      loginState.password
    );

    // Hit AM with the password callback
    nextStep = await submitCallBackToAM(nextStep);
    const passwordValidated: BiometricsValidationReturnType = await validateBiometricsNewDevicePasswordStep(nextStep);
    nextStep = passwordValidated.nextStep;
    const errorMessage: string | null = passwordValidated.errorMessage;

    if (!passwordValidated.boolean) {
      enqueueSnackbar(errorMessage, { variant: 'error' });
      return await handleResetMetadataCallbacksDeviceReg(
        setGlobalContext,
        nextStep,
        setLoginState
      )
    }

    setLoginState((prev: LoginStateType) => ({
      ...prev,
      biometricsState: {
        ...prev.biometricsState,
        passwordVerified: true
      }
    }));

    // Continue with biometric registration
    nextStep = await handleWebAuthStep(nextStep, enqueueSnackbar);

    if (nextStep.type !== 'LoginSuccess' && verifyStepTypeValid(nextStep) && await verifyOTP(nextStep.callbacks) !== true) {
      // Check if the user has already registered their biometrics on this device
      const stepType: WebAuthnStepType = await verifyTheWebAuthnStepType(nextStep);
      if (stepType === WebAuthnStepType.Registration) {
        // cancel registration
        setLoginState((prev: LoginStateType) => ({
          ...prev,
          loading: false,
          loginFlowSuccessful: false
        }));
      }
    }

    // Check if the user is being prompted for an OTP
    if (nextStep.type === 'Step' && await verifyOTP(nextStep.callbacks)) { // kick off OTP when needed
      setGlobalContext((prev: GlobalContextType) => ({ ...prev, oAuthStep: nextStep }));
      // Remember the user's preference for biometric login
      await rememberBiometricSignInForUser('biometric', globalContext.loginInformation.email);
      return setLoginState((prev: LoginStateType) => ({
        ...prev,
        OTPState: {
          ...prev.OTPState,
          displayOTP: true
        }
      }));
    }

    // Remember the user's preference for biometric login
    await rememberBiometricSignInForUser('biometric', globalContext.loginInformation.email);

    // Successfully acquired SSO token and proceed with Access token exchange
    await checkSSOSuccessAndGetAccessToken(nextStep, setGlobalContext);
  } catch (e: any) {
    await resetBiometricState(
      globalContext,
      loginState,
      setLoginState,
      setGlobalContext,
      enqueueSnackbar,
      UNEXPECTED_ERROR_BIOMETRIC_PASSWORD
    )
    console.error(e);
    throw e;
  }
};

// Verify SSO login was a success with biometrics and exchange an SSO token for an Access token
const checkSSOSuccessAndGetAccessToken = async (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
) => {
  try {
    if (nextStep.type === 'LoginSuccess') {
      return setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        SSOAuthenticated: true,
        oAuthStep: nextStep,
        sessionTimedOut: false
      }));
    }
  } catch (e: any) {
    console.log(e);
  }
}

/* handle biometric registration or login based on if the users device is already registered for biometrics or not.
 * if the step type is not registration, register biometrics for this user on this new device
 * otherwise authenticate and get challenge from server for biometric login of existing device for this user */
const handleWebAuthStep = async (
  nextStep: FRStep | FRLoginSuccess | FRLoginFailure,
  enqueueSnackbar: any
): Promise<FRStep | FRLoginSuccess | FRLoginFailure> => {
  try {
    if (!verifyStepTypeValid(nextStep)) throw Error('Invalid next step type');

    // Check if the user has already registered their biometrics on this device
    const stepType: WebAuthnStepType = await verifyTheWebAuthnStepType(nextStep);

    if (stepType === WebAuthnStepType.Registration) {
      try {
        nextStep = await FRWebAuthn.register(nextStep);
        pushSpecificEvent('Registered biometric device successfully');
      } catch (e: any) {
        return nextStep;
      }
    } else if (stepType === WebAuthnStepType.Authentication) {
      nextStep = await FRWebAuthn.authenticate(nextStep);
      pushSpecificEvent('Signed in with biometric device');
    } else if (stepType === WebAuthnStepType.None) {
      throw INCORRECT_WEB_AUTH_STEP_TYPE;
    }

    return FRAuth.next(nextStep); // Hit AM again to handle JS challenge response
  } catch (e: any) {
    if (!e.message.startsWith('The operation either timed out or was not allowed') && !e.message.startsWith('A request is already pending')) {
      throw e;
    } else {
      return nextStep;
    }
  }
}

/* Handle if the user has not registered their biometrics yet and they need to fill in their password
 * in the UI to continue registering biometrics on their device */
const handlePasswordCallback = (nextStep: FRStep): boolean => {
  try {
    let callbackType: string;

    for (const callback of nextStep.callbacks) {
      if (callback.getType() === 'PasswordCallback') {
        callbackType = callback.getType();
        break;
      }
    }

    return callbackType === 'PasswordCallback'; // Return true if the callback type is a PasswordCallback
  } catch (e: any) {
    console.error(e);
  }
}

// Handle if the user has not registered their biometrics yet and they need to fill in their password
const handleBiometricsRegistrationPasswordCallback = (
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
) => {
  try {
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      loading: false,
      biometricsState: {
        ...prev.biometricsState,
        hasPasswordCallbackDuringRegistration: true
      }
    }));
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

const updateNextStepForBiometricsRegistration = (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  nextStep: FRStep,
) => {
  try {
    setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      oAuthStep: nextStep
    }));
  } catch (e: any) {
    console.log(e);
  }
}

// Function to verify if the step type is valid
export const verifyStepTypeValid = (step: FRStep | FRLoginSuccess | FRLoginFailure): step is FRStep => {
  return step instanceof FRStep;
};

// Reset the Oauth step if the user encounters an unexpected error during the SSO flow
const handleSSOErrorAndResetState = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  isAuth: any,
  enqueueSnackbar: any,
  theme: string,
) => {
  try {
    await getAmCallback(globalContext, setGlobalContext, isAuth, setErrorLogin);
    displayUnexpectedError(enqueueSnackbar, theme);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      loading: false,
      loginFlowSuccessful: false
    }));
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};

// If a user uses biometrics to login, we'll want to remember that for the next time they sign in
const rememberBiometricSignInForUser = async (loginType: string, email: string) => {
  if (loginType === 'biometric') {
     return localStorage.setItem('userLastSignedInWithBiometric', email);
  }
  return localStorage.removeItem('userLastSignedInWithBiometric');
}

const encodePassword = (password: string): string => {
  return Base64.encode(password);
}

const handleSSOTokenExchange = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  navigate: NavigateFunction,
  setIsAuth: any,
  isAuth: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  closeSnackbar: any,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  t: TFunction,
  theme: string,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
  exchangeType: string
): Promise<void> => {
  try {
    const oAuthStep: any = globalContext.oAuthStep;
    const encodedPassword: string = encodePassword(loginState.password); // Encode the password for the request
    const spEntityIds: string[] = [
      "saveonfoods",
      "pricesmartfoods",
      "urbanfare",
      "morerewards",
      "choicesmarkets"
    ];

    // handle when this breaks from AM's side gracefully
    if (!oAuthStep) {
      return resetOauthStep(globalContext, setGlobalContext, loginState.password);
    }

    // handle and nuke iPlanetDirectoryPro cookie in browser if stuck - this is now in the correct spot
    if (oAuthStep.type === 'LoginSuccess') {
      await logoutOauthSession(
        setGlobalContext,
        navigate,
        setIsAuth,
        'desktop',
        null
      );
      return resetOauthStep(
        globalContext,
        setGlobalContext,
        loginState.password,
        'incorrectPassword'
      );
    }

    // always set the email callback
    await oAuthStep.callbacks[NAME_INPUT_FIELD].setInputValue(globalContext.loginInformation.email);

    // set the email and password callbacks for standard SSO exchange or the input value for biometrics
    if (exchangeType === 'biometrics') {
      await oAuthStep.callbacks[PASSWORD_INPUT_FIELD].setPassword(' ');
      await oAuthStep.callbacks[BIOMETRIC_INPUT_FIELD].setInputValue(1);
    } else {
      await oAuthStep.callbacks[PASSWORD_INPUT_FIELD].setPassword(encodedPassword);
    }

    // Extra step options to break SSO for choices login.
    const StepOptions: StepOptions = { query: { clientId: loginState.spEntityId } };

    // Get the next step in the auth flow
    let nextStep: FRStep | FRLoginSuccess | FRLoginFailure = await FRAuth.next(oAuthStep, StepOptions);
    await checkStep(nextStep);
    await breakSSOIfChoicesLogin(spEntityIds, loginState.spEntityId); // Remember if a choices account was just logged in with to break SSO later

    // Check if the users AM session timed out
    if (nextStep.payload.message === 'Session has timed out') {
      return resetOauthStep(
        globalContext,
        setGlobalContext,
        loginState.password,
        exchangeType
      );
    }

    // Continue with the Oauth flow and exchange the SSO token for an Access token for email + password login
    if (nextStep.type === 'LoginSuccess') {
      await rememberBiometricSignInForUser('standard', ''); // Remember the user's preference for non-biometric login
      return setGlobalContext((prev: GlobalContextType) => ({
        ...prev,
        SSOAuthenticated: true,
        oAuthStep: nextStep,
        sessionTimedOut: false,
        OTPTimedOut: false
      }));
    }

    // Check if the user is being prompted for an OTP
    if (nextStep.type === 'Step' && await verifyOTP(nextStep.callbacks)) { // kick off OTP when needed
      setGlobalContext((prev: GlobalContextType) => ({ ...prev, oAuthStep: nextStep }));
      return setLoginState((prev: LoginStateType) => ({
        ...prev,
        OTPState: {
          ...prev.OTPState,
          displayOTP: true
        }
      }));
    }

    // Handle all potential errors from AM
    if (nextStep.payload.message === UN_PW_INVALID ||
      nextStep.payload.message === ACCOUNT_DISABLED ||
      nextStep.payload.message === ACCOUNT_NO_CARD ||
      nextStep.payload.message === LOGIN_FAILURE ||
      nextStep.payload.message === ACCOUNT_NEEDS_RESET_PW
    ) {
      return await handleSSOErrors(
        globalContext,
        setGlobalContext,
        encodedPassword,
        enqueueSnackbar,
        nextStep,
        navigate,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        setLoginState
      );
    }

    // If we make it here we can assume we're performing a biometric login and can continue with that flow
    if (!verifyStepTypeValid(nextStep)) {
      return resetOauthStep(
        globalContext,
        setGlobalContext,
        loginState.password,
        'incorrectPassword'
      );
    }

    // Check if the user needs to fill in their password in the UI to continue registering biometrics on their device
    const passwordCallback: boolean = handlePasswordCallback(nextStep);

    // Handle password callback and render UI if needed
    if (passwordCallback) {
      updateBiometricsSubmissionState(setLoginState, false);
      handleBiometricsRegistrationPasswordCallback(setLoginState);
      return updateNextStepForBiometricsRegistration(setGlobalContext, nextStep);
    }

    // Continue with biometric registration
    nextStep = await handleWebAuthStep(nextStep, enqueueSnackbar); // Continue with biometric registration

    // Check if the user is being prompted for an OTP
    if (nextStep.type === 'Step' && await verifyOTP(nextStep.callbacks)) { // kick off OTP when needed
      setGlobalContext((prev: GlobalContextType) => ({ ...prev, oAuthStep: nextStep }));
      // Remember the user's preference for biometric login
      await rememberBiometricSignInForUser('biometric', globalContext.loginInformation.email);
      return setLoginState((prev: LoginStateType) => ({
        ...prev,
        OTPState: {
          ...prev.OTPState,
          displayOTP: true
        }
      }));
    }

    if (nextStep.type === 'Step' && await verifyOTP(nextStep.callbacks) !== true) {
      await resetBiometricState(
        globalContext,
        loginState,
        setLoginState,
        setGlobalContext,
        enqueueSnackbar,
        null
      );

      if (globalContext.isIos === true) {
        enqueueSnackbar('Login with Face ID / Touch ID has been cancelled. To continue, please enter your password or refresh the page.', { variant: 'info' });
      }

      return setLoginState((prev: LoginStateType) => ({
        ...prev,
        loading: false,
        biometricsState: {
          ...prev.biometricsState,
          hideBiometric: true
        }
      }));
    }

    // Remember the user's preference for biometric login
    await rememberBiometricSignInForUser('biometric', globalContext.loginInformation.email);

    // Successfully acquired SSO token and proceed with Access token exchange
    await checkSSOSuccessAndGetAccessToken(nextStep, setGlobalContext);
  } catch (e: any) {
    if (exchangeType === 'biometrics') {
      await resetBiometricState(
        globalContext,
        loginState,
        setLoginState,
        setGlobalContext,
        enqueueSnackbar,
        null
      )

      if (globalContext.isIos === true) {
        enqueueSnackbar('Login with Face ID / Touch ID has been cancelled. To continue, please enter your password or refresh the page.', { variant: 'info' });
      }

      return setLoginState((prev: LoginStateType) => ({
        ...prev,
        loading: false,
        biometricsState: {
          ...prev.biometricsState,
          hideBiometric: true
        }
      }));
    }
    await handleSSOErrorAndResetState(
      globalContext,
      setGlobalContext,
      setLoginState,
      setErrorLogin,
      isAuth,
      enqueueSnackbar,
      theme
    );
    throw e;
  }
};

/* get the access token if the user is authenticated, has a 10 minute auto logout
 * if getting the access token times out, revoke the iPlanetDirectoryPro
 * cookie + reset Oauth Step from 'LoginSuccess' to 'Step' */
const fetchAccessToken = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  navigate: NavigateFunction,
  globalContext: GlobalContextType,
  enqueueSnackbar: any,
  setIsAuth: any,
  isAuth: void | OAuth2Tokens,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  goto: any,
  theme: string,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  try {
    const tokens: OAuth2Tokens | void = await TokenManager.getTokens();
    await checkTokens(tokens);
    // @ts-ignore
    let jwtInfo: any = getJWTJsonPayload(tokens.accessToken);
    setDataAnalMetaData({ prid: jwtInfo.publicReferenceId });
    Cookies.set('PRID', jwtInfo.publicReferenceId, {
      path: '/',
      domain: '.morerewards.ca',
      expires: 365,
      sameSite: 'None',
      secure: true
    });
    pushSpecificEvent('Successful login');
    setGlobalContext((prev: GlobalContextType) => ({ ...prev, appBarPageSelected: 'userAccount' }));
    setSessionTimeout();
    if (jwtInfo.hasPostMerged === 'prompt_screen') {
      return navigate('/confirm-account-details');
    }
    const allowedRoutes: string[] = [
      '/userPage/home',
      '/userPage/card',
      '/userPage/manageAddresses',
      '/userPage/changeEmail',
      '/userPage/changePassword',
      '/userPage/emailSubscriptions',
      '/userPage/household',
      '/userPage/deleteAccount'
    ];
    if (goto) {
      if (allowedRoutes.includes(goto)) {
        navigate(goto);
      } else if (hasDesiredGotoParam(goto)) {
        await window.location.replace(goto);
      } else {
        await window.location.replace(goto);
      }
    } else {
      navigate('/userPage/home');
    }
  } catch (e: any) {
    await logoutOauthSession(setGlobalContext, navigate, setIsAuth, 'desktop', null);
    await getAmCallback(globalContext, setGlobalContext, isAuth, setErrorLogin);
    displayUnexpectedError(enqueueSnackbar, theme);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      password: '',
      loading: false,
      OTPState: {
        ...prev.OTPState,
        OTPLoading: false,
        resendButtonLoading: false,
        displayOTP: false
      }
    }));
  }
};

const startRefreshTokens = async () => {
  let tokens: OAuth2Tokens = JSON.parse(localStorage.getItem(CLIENT_ID));
  if (tokens) {
    let accessToken = getJWTJsonPayload(tokens.accessToken);
    // @ts-ignore
    let expireIn = accessToken.expires_in;
    setTimeout(async () => {
      await refreshTokens(expireIn * 1000);
    }, expireIn * 1000);
  }
};

const refreshTokens = async (timeoutTime: number): Promise<string> => {
  try {
    let tokens: OAuth2Tokens = JSON.parse(localStorage.getItem(CLIENT_ID));
    if (tokens) {
      let refreshToken = getJWTJsonPayload(tokens.refreshToken);
      // @ts-ignore
      if (Math.floor(Date.now() / 1000) < refreshToken.exp) {
        const newTokens: OAuth2Tokens | void = await TokenManager.getTokens({ 'forceRenew': true });
        if (newTokens) {
          setTimeout(async () => {
            await refreshTokens(timeoutTime);
          }, timeoutTime);
        }
      }
    }
  } catch (e: any) {
    console.log(e);
    return 'errorState';
  }
};

/* comment out the code in this function for local development
 * uncomment them back before pushing to QA otherwise you will break single sign out */
const validateiPlanetSession = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  navigate: NavigateFunction,
  globalContext: GlobalContextType,
  setIsAuth: any,
  isAuth: void | OAuth2Tokens,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  goto: string
) => {
  try {
    await setLoading(true);
    let validateResponse: any = await validateToken(); // hit ForgeRock AM to check if session is valid
    let validResponseJson = JSON.parse(validateResponse);
    if (!validResponseJson.valid) { // if the session is no longer valid
      await logoutOauthSession(setGlobalContext, navigate, setIsAuth, 'desktop', goto); // revoke the users access token and punt them off AM
      await getAmCallback(globalContext, setGlobalContext, isAuth, setErrorLogin); // reset OAuthStep to default
      await setLoading(false);
      return false; // session is NOT valid
    }
    return true; // session is valid
  } catch (e: any) {
    enqueueSnackbar('Something went wrong verifying your session, please try log back in. ' +
      'If this issue keeps happening please contact Customer Service', { variant: 'error' });
    await logoutOauthSession(setGlobalContext, navigate, setIsAuth, 'desktop', null);
    await getAmCallback(globalContext, setGlobalContext, isAuth, setErrorLogin);
    await setLoading(false);
  }
};

export {
  getStep,
  fetchAccessToken,
  refreshTokens,
  startRefreshTokens,
  validateiPlanetSession,
  continueBiometricFlowUnregisteredDevice,
  handleSSOTokenExchange,
  submitCallBackToAM,
  verifyOTP,
  updateNextStepForBiometricsRegistration,
  resetOauthStep,
  handlePasswordCallback,
  checkSSOSuccessAndGetAccessToken,
  validateBiometricsNewDevicePasswordStep,
  handleWebAuthStep,
  populateBiometricsNewDevicePasswordStep,
  handleBiometricDeviceRegAfterPassword,
  resetBiometricState,
  encodePassword,
  handleAMErrorsAndResetLoginFormState
};