import {useSnackbar} from 'notistack';
import * as React from 'react';
import {IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';

// @ts-ignore
function SnackbarCloseButton({snackbarKey}) {
  const {closeSnackbar} = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close/>
    </IconButton>
  );
}

export default SnackbarCloseButton;
