import React, {useContext, useEffect, useState} from 'react';
import {RegInfoType, RegistrationContext, RegistrationPagesEnum} from "../../../contexts/registration";
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import {Link} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import { GlobalContext } from '../../../contexts/global';

export type VerifyEmailExpiredPageProps = {
  theme: string
}

/**
 * Page that says Email jwt is expired
 * @constructor
 */
const VerifyEmailExpiredPage = (props: VerifyEmailExpiredPageProps) => {
  const {theme} = props;
  const {setRegistrationContext} = useContext(RegistrationContext);
  const {globalContext,} = useContext(GlobalContext);
  const navigate = useNavigate();
  const {t, ready} = useTranslation();
  const [redirectTime, setRedirectTime] = useState(10);

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setRegistrationContext((prev: RegInfoType) => ({
      ...prev,
      currentPage: RegistrationPagesEnum.VerifyEmailPage
    }));
    navigate('/');
  };

  useEffect(() => {
    pushRouteChangeEvent('Register: VerifyEmailExpiredPage');
  }, []);

  useEffect(() => {
    if (redirectTime <= 0) {
      navigate('/');
    } else {
      setTimeout(() => setRedirectTime(redirectTime - 1), 1000);
    }
  }, [redirectTime]);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <form
        className={globalContext.referer === 'choices' ? "choicesWhiteBoxWide":""}
        onSubmit={handleSubmit}
        noValidate
      >
        <Typography variant="h2" component="h2" className="topHeader">
          Expired Link
        </Typography>
        <Typography variant="h3" component="h3" className="topHeader topHeader-smaller">
          {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.DESC')}
        </Typography>
        <Typography variant="body2" component="p" className="youWillBeRedirected">
          {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.REDIRECTED_IN', {redirectTime})}
        </Typography>
        <div className="center-text">
          <Link onClick={() => handleSubmit()} className="clickHere link-white link-choices">Click here</Link>
          <Typography
            component="p"
            className="redirectNow">
            {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.REDIRECT_NOW')}
          </Typography>
        </div>
      </form>
    </RegistrationLayout>
  );
};

export default VerifyEmailExpiredPage;