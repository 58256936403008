export const titleCase = (str: any) => {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

/**
 * Trims double spaces and staring space
 */
export const trimDoubleSpace = (inp: string) => {
  return inp.replace(/ +(?= )|^ /g, '');
};