import React, {useContext, useEffect, useState} from 'react';
import {
  LINK_CONTACT_US,
  LINK_CONTACT_US_CHOICES, LINK_DISCLAIMER, LINK_TERMS_AND_CONDITIONS,
  LINK_TERMS_AND_CONDITIONS_CHOICES,
  PRIVACY_POLICY_PFG
} from "../../../util/constants";
import {DeleteAccountProps} from '../myAccountTypes';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Link,
  Typography
} from '@mui/material';
import {useAtom} from 'jotai';
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ProfileInfoType} from "../../../contexts/types/myAccount";
import {NavigateFunction} from "react-router";
import {GlobalContextType} from "../../../contexts/types/global";
import {useSnackbar} from "notistack";
import {GlobalContext} from "../../../contexts/global";
import {sendAccountDeletionRequest} from "../../../network-requests/resources";
import './deleteAccount.scss';

export default function DeleteAccount(props: DeleteAccountProps) {
  const {loading, setSelectedTab, theme} = props;
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [profileInfo] = useAtom(profileInfoAtom);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const email = profileInfo.email.toLowerCase();

  const handleSubmitDeletionRequest = async (
    email: string,
    profileInfo: ProfileInfoType,
    t: any,
    navigate: NavigateFunction,
    setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
    enqueueSnackbar: any
  ): Promise<void> => {
    try {
      setApiIsLoading(true);
      const response: string = await sendAccountDeletionRequest(email);
      if (response !== null && response === 'success') {
        enqueueSnackbar(t('MESSAGES.ACCOUNT_DELETION_SUCCESS'), {variant: 'success'});
        await setTimeout(() => {
          setSelectedTab(0);
          navigate('/logout');
          setApiIsLoading(false);
        }, 5000);
      } else {
        enqueueSnackbar(t('MESSAGES.ACCOUNT_DELETION_FAILURE'), {variant: 'error'});
        setApiIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        <>
          <Typography
            sx={{marginRight: '5px', marginLeft: '5px', cursor: 'pointer'}}>
            An unexpected error occurred deleting your account. Please try again. If this issue persists please&nbsp;
            <Link
              href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
              target="_blank"
              className="link-white"
            >contact us
            </Link>
          </Typography>
        </>,
        {variant: 'error'}
      );
    }
  }

  useEffect(() => {
    pushRouteChangeEvent('Profile: DeleteAccount');
    setSelectedTab(7);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    handleSubmitDeletionRequest(
      email,
      profileInfo,
      t,
      navigate,
      setGlobalContext,
      enqueueSnackbar
    )
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be undone. You will miss out on savings and free stuff!
          </DialogContentText>
        </DialogContent>
        <DialogActions className="actions">
          <Button className="primaryButtonDefault" onClick={handleClose}>Go back</Button>
          <Button className="deleteButtonDefault" onClick={handleSubmit} autoFocus>
            Delete my account
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Paper elevation={0}>
          {loading || apiIsLoading
            ?
            <div className="loadingProfile">
              <Box>
                <CircularProgress className="circularProgressSpinner"/>
              </Box>
            </div>
            :
            <>
              <div className="infoBar infoBarThin">
                <div className="info"><h3>Delete Account</h3></div>
              </div>
              <div className="deleteAccount">
                <div className="centeredContainer">
                  {globalContext.referer === 'choices' ?
                    <div className="innerColumn">
                      <h3>What does this mean?</h3>
                      <ul className="list">
                        <li>Your Choices Markets and shared More Rewards ID online profile will be deactivated and you
                          will no longer be able to sign in to any of the following websites until you register again:
                        </li>
                        <ul>
                          <li>choicesmarkets.com</li>
                          <li>morerewards.ca</li>
                          <li>saveonfoods.com</li>
                          <li>urbanfare.com</li>
                          <li>pricesmartfoods.com</li>
                        </ul>
                        <li>You will cease to have access to any promotions through More Rewards, such as My Offers.
                        </li>
                      </ul>
                      <h3>What happens now?</h3>
                      <ol className="list">
                        <li>Your account will be instantly deleted.</li>
                        <li>You won't be able to place any online orders.</li>
                        <li>You will be signed out from all the above websites.</li>
                      </ol>
                      If you change your mind, you can always register again.<br/><br/> For more details check out our <a href={PRIVACY_POLICY_PFG} target="">Privacy Policy</a>. If you have any questions please&nbsp;<Link href={LINK_CONTACT_US_CHOICES} target="_blank">Contact Us</Link>.<br/><br/>
                      <Link href={LINK_TERMS_AND_CONDITIONS_CHOICES} target="_blank"> Terms of Use</Link>&nbsp;|&nbsp;<Link href={LINK_DISCLAIMER} target="_blank">Disclaimer</Link><br/><br/>
                    </div>
                    :
                    <div className="innerColumn">
                      <h3>What does this mean?</h3>
                      <ul className="list">
                        <li>You will no longer be able to redeem points in-store or online.</li>
                        <li>Your More Rewards ID will be deactivated and you will no longer be able to sign in to any of
                          the following websites until you register again:
                        </li>
                        <ul>
                          <li>morerewards.ca</li>
                          <li>saveonfoods.com</li>
                          <li>urbanfare.com</li>
                          <li>pricesmartfoods.com</li>
                          <li>choicesmarkets.com</li>
                          <li>qualityfoods.com</li>
                        </ul>
                        <li>You will no longer have access to bonus points and digital coupons from My Offers.</li>
                      </ul>
                      <h3>What happens now?</h3>
                      <ol className="list">
                        <li>Your account will be instantly deleted.</li>
                        <li>You won't be able to place any online orders.</li>
                        <li>You will be signed out from all the above websites.</li>
                      </ol>
                      If you change your mind, you can always register again.<br/><br/> For more details check out our <a href={PRIVACY_POLICY_PFG} target="_blank">Privacy Policy</a>. If you have any questions please&nbsp;<Link href={LINK_CONTACT_US} target="_blank">Contact Us</Link>.<br/><br/>
                      <Link href={LINK_TERMS_AND_CONDITIONS} target="_blank">Terms of Use</Link>&nbsp;|&nbsp;<Link href={LINK_DISCLAIMER} target="_blank">Disclaimer</Link><br/><br/>
                    </div>
                  }
                  <div className="centeredContainer account-steps-nav-delete">
                    <Button
                      variant="contained"
                      className="primaryButtonDefault"
                      onClick={() => {
                        navigate('/userPage/home');
                        setSelectedTab(0);
                      }}>{t('BUTTONS.CANCEL')}
                    </Button>
                    <Button
                      id="nextStepBtn"
                      type="submit"
                      variant="contained"
                      className="deleteButtonDefault"
                      onClick={() => handleClickOpen()}>{t('Delete')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          }
        </Paper>
      </Box>
    </div>
  );
}