import React from 'react';
import Grid from "@mui/material/Grid";

type BrandLogoComponentProps = {
  referer: string;
}

const BrandLogoComponent = (props: BrandLogoComponentProps) => {
  const { referer } = props;

  return (
    <Grid className="brandBG" item xs={12} sm={12} md={7} lg={7}>
      {(() => {
        switch (referer) {
          case 'saveonfoods':
            return (
              <img
                className="brand-logo"
                src={require('../../images/sof/logo-sof.png')}
                alt={'Save-On-Foods logo'}
              />
            );
          case 'urbanfare':
            return (
              <img
                className="brand-logo"
                src={require('../../images/uf/logo-uf.png')}
                alt={'Urban Fare logo'}
              />
            );
          case 'pricesmartfoods':
            return (
              <img
                className="brand-logo"
                src={require('../../images/psf/logo-psf.png')}
                alt={'Price Smart Foods logo'}
              />
            );
          case 'choices':
            return (
              <img
                className="brand-logo negative16"
                src={require('../../images/choices/logo-choices.png')}
                alt={'Choices logo'}
              />
            );
          case 'myoffers':
            return (
              <img
                className="brand-logo-myoffers"
                src={require('../../images/mr/logo-mr.png')}
                alt={'More Rewards logo'}
              />
            );
          case 'qualityfoods':
            return (
              <>
                <img
                  className={sessionStorage.getItem('display') === 'true' ? "brand-logo-qf-message" : "brand-logo-qf"}
                  src={require('../../images/qf/logo-qf.png')}
                  alt={'Quality Foods logo'}
                />
                <img
                  className="angus-qf"
                  src={require('../../images/qf/black-angus.png')}
                  alt={'Black Angus'}
                />
                <img
                  className="brand-logo-mobile-qf"
                  src={require('../../images/qf/mobile-logo-qf.png')}
                  alt={'Quality Foods logo'}
                />
              </>
            );
          default:
            return (
              <img
                className="brand-logo"
                src={require('../../images/mr/logo-mr.png')}
                alt={'More Rewards logo'}
              />
            );
        }
      })()}
    </Grid>
  );
};

export default BrandLogoComponent;