import React from 'react';
import {GlobalContextType} from "../../../contexts/types/global";
import {
  dobValidator, firstNameValidator,
  firstNameValidatorNoRegex, lastNameValidator,
  lastNameValidatorNoRegex,
} from '../../../util/validator';
import {RegInfoType, RegistrationPagesEnum} from '../../../contexts/registration';
import {PasswordInfo} from '../../my-account/change-password/changePasswordTypes';
import {TFunction} from 'i18next';
import {PhoneNumbers} from '../../my-account/profile/profile';
import {PhoneErrors} from "../../my-account/myAccountTypes";
import {RegisterFields} from './4-validation';

const handleSubmit = (
  event: React.FormEvent<HTMLFormElement>,
  passwordInfo: PasswordInfo,
  phoneEditState: PhoneNumbers,
  phoneEditErrors: PhoneErrors,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  setPhoneEditErrors: React.Dispatch<React.SetStateAction<PhoneErrors>>,
  registerFields: RegisterFields,
  setRegisterFields: React.Dispatch<React.SetStateAction<RegisterFields>>,
  globalContext: GlobalContextType,
  existingAccount: boolean,
  t: TFunction,
) => {
  event.preventDefault();
  let inputError = false;
  let dob = registerFields.dobYear + '-' + registerFields.dobMonth + '-' + registerFields.dobDay;
  if (phoneEditErrors.primaryEmpty.value) {
    inputError = true;
  }
  if (passwordInfo.passwordStrengthMeterValue <= 50) {
    inputError = true;
  }
  if (passwordInfo.warning || passwordInfo.warning) {
    inputError = true;
  }
  if (
    !registerFields.confirmPassword ||
    registerFields.confirmPassword !== registerFields.password
  ) {
    setRegisterFields((prev: any) => ({
      ...prev,
      confirmPasswordHelperText: t('VALIDATOR.PASSWORD.NOT_MATCH')
    }));
    inputError = true;
  }
  if (registerFields.password.length <= 1) {
    setRegisterFields((prev: any) => ({
      ...prev,
      passwordHelperText: t('VALIDATOR.PASSWORD.REQUIRED')
    }));
    inputError = true;
  } else if (!passwordInfo.allRequirementsMet) {
    setRegisterFields((prev: any) => ({
      ...prev,
      passwordHelperText: t('Not strong enough')
    }));
    inputError = true;
  }
  if (existingAccount) {
    if (firstNameValidatorNoRegex(registerFields.fName, t).length !== 0) {
      setRegisterFields((prev: any) => ({
        ...prev,
        fNameHelperText: firstNameValidatorNoRegex(registerFields.fName, t)
      }));
      inputError = true;
    }
    if (lastNameValidatorNoRegex(registerFields.lName, t).length !== 0) {
      setRegisterFields((prev: any) => ({
        ...prev,
        lNameHelperText: lastNameValidatorNoRegex(registerFields.lName, t)
      }));
      inputError = true;
    }
  } else {
    if (firstNameValidator(registerFields.fName, t).length !== 0) {
      setRegisterFields((prev: any) => ({
        ...prev,
        fNameHelperText: firstNameValidator(registerFields.fName, t)
      }));
      inputError = true;
    }
    if (lastNameValidator(registerFields.lName, t).length !== 0) {
      setRegisterFields((prev: any) => ({
        ...prev,
        lNameHelperText: lastNameValidator(registerFields.lName, t)
      }));
      inputError = true;
    }
  }
  if (phoneEditErrors.primaryIncorrectlyEntered.value || phoneEditErrors.alternate1PhoneIncorrectlyEntered.value) {
    inputError = true;
  }

  if (phoneEditState.primary.length === 0 && phoneEditState.alternate1.length === 0) {
    setPhoneEditErrors((prev: PhoneErrors) => ({
      ...prev,
      primaryEmpty: {
        ...prev.primaryIncorrectlyEntered,
        value: 'Primary phone is required',
        disableSaveButton: true
      }
    }));
    inputError = true;
  }
  if (dobValidator(dob, t).length !== 0) {
    setRegisterFields((prev: any) => ({
      ...prev,
      dobHelperText: dobValidator(dob, t)
    }));
    inputError = true;
  }
  // this is hidden on the choices theme so don't check for it if that theme is loaded
  if (registerFields.receiveEmails === null && globalContext.referer !== 'choices') {
    setRegisterFields((prev: any) => ({
      ...prev,
      receiveEmailsHelperText: t('REGISTER.VALIDATION.EMAIL_PREF_REQUIRED')
    }));
    inputError = true;
  }
  if (inputError) return;
  setRegistrationContext((prev: RegInfoType) => ({
    ...prev,
    currentPage: RegistrationPagesEnum.AddressPage,
    password: registerFields.password,
    fName: registerFields.fName,
    lName: registerFields.lName,
    homePhone: phoneEditState.primary.replace(/-/g, ''),
    mobilePhone: phoneEditState.alternate1.replace(/-/g, ''),
    sendEmails: registerFields.receiveEmails,
    dob: dob.toString()
  }));
};

export { handleSubmit }