import React, {useContext, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {PasswordInfo} from './changePasswordTypes';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {useNavigate} from 'react-router-dom';
import {GlobalContext} from '../../../contexts/global';
import {useSnackbar} from 'notistack';
import PasswordInputEditor from "../../../components/password-input-editor/password-input-editor";
import DeviceInfo from "../../../components/manage-device-biometrics/manage-device-biometrics";
import './changePassword.scss';
import {useAtom} from "jotai";
import {profileInfoAtom} from "../../../jotai-state-management/profile-global-state";

export type ChangePasswordProps = {
  theme: string
}

export default function ChangePassword(props: ChangePasswordProps) {
  const { theme } = props;
  const { enqueueSnackbar } = useSnackbar();
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const { globalContext, setGlobalContext } = useContext(GlobalContext);
  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  const [profileInfo] = useAtom(profileInfoAtom);
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    oldPassword: {
      input: '',
      helperText: ''
    },
    newPassword: {
      input: '',
      helperText: ''
    },
    confirm: {
      input: '',
      helperText: ''
    },
    weaknesses: [],
    helperText: '',
    anyInputIsEmpty: true,
    passwordsMatch: false,
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    allRequirementsMet: false,
    passwordStrengthInformation: {
      strengthMeterBarColor: 'error',
      strengthMeterText: 'weak'
    },
    passwordStrengthMeterValue: 0,
    warning: '',
    suggestions: []
  });
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (
      (passwordInfo.oldPassword.input ||
        passwordInfo.newPassword.input ||
        passwordInfo.confirm.input) &&
      passwordUpdated
    ) {
      setPasswordUpdated(false);
    }
  }, [passwordInfo, passwordUpdated]);

  useEffect(() => {
    pushRouteChangeEvent('Profile: ChangePassword');
  }, []);

  return (
    <div>
      <div>
        <Box>
          <Paper elevation={0}>
            {
              globalContext.referer === 'qualityfoods' ? <></> :
                <>
                  <div className="infoBar infoBarThin hideFromDesktop">
                    <div className="info"><h3> {globalContext.isIos?"Face ID / Touch ID":"Manage Your Biometric devices"}</h3></div>
                  </div>
                  <div className="password-accordion hideFromDesktop">
                    <DeviceInfo
                      profileInfo={profileInfo}
                      globalContext={globalContext}
                      enqueueSnackbar={enqueueSnackbar}
                    />
                  </div>
                </>
            }
            <div className="infoBar infoBarThin">
              <div className="info"><h3>Manage Your Password</h3></div>
            </div>
            <div className="password-accordion">
              <PasswordInputEditor
                passwordInfo={passwordInfo}
                setPasswordInfo={setPasswordInfo}
                searchInput={searchInput}
                navigate={navigate}
                setGlobalContext={setGlobalContext}
                savingChanges={savingChanges}
                setSavingChanges={setSavingChanges}
                passwordUpdated={passwordUpdated}
                theme={theme}
                setPasswordUpdated={setPasswordUpdated}
                enqueueSnackbar={enqueueSnackbar}
              />
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  );
}