import React, {useContext, useEffect} from 'react';
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useTranslation} from 'react-i18next';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {GlobalContext} from '../../../contexts/global';
import {LINK_CONTACT_US} from "../../../util/constants";

export type ContactCustomerServicePageProps = {
  theme: string
}

/**
 * Page that says "buddy something is wrong UwU"
 * @constructor
 */
const ContactCustomerServicePage = (props: ContactCustomerServicePageProps) => {
  const { theme } = props;
  const { globalContext, } = useContext(GlobalContext);
  const { t, ready } = useTranslation();

  useEffect(() => {
    pushRouteChangeEvent('Register: ContactCustomerServicePage');
  }, []);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <form
        className={globalContext.referer === 'choices' ? "choicesWhiteBox" : ""}
        noValidate>
        <Typography variant="h2" component="h2" className="topHeader">
          <a className="link-white link-choices" href={LINK_CONTACT_US}
             target="_blank">{t('REGISTER.HEADERS.CONTACT_CUSTOMER_SERVICE')}</a>
        </Typography>
        <div className={theme === "choices" ? "topTextRegisterChoices" : "topTextRegister"}>
          You have answered one or more questions incorrectly. To protect your account we have locked your More Rewards
          card. To complete your registration and unlock your card please&nbsp;
          <a
            className="link-white link-choices"
            href={LINK_CONTACT_US}
            target="_blank">contact us
          </a>.
        </div>
      </form>
    </RegistrationLayout>
  );
};

export default ContactCustomerServicePage;