// set the state for the checkboxes & initial checkbox snapshot using the current myAccountContext
import React from 'react';
import {NavigateFunction} from "react-router";
import {Checkboxes} from "../../contexts/types/myAccount";
import {CheckboxState} from '../my-account/email-subscriptions/emailSubscriptionsTypes';
import {getUsersUnsubOptions, updateUsersEmailSubscriptionsUnsub} from '../../network-requests/resources';

const setInitialCheckBoxStateUnsub = (
  data: any,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
): void => {
  setCheckboxState((prev: CheckboxState) => ({
    ...prev,
    initialCheckboxesState: data,
    initialCheckboxesSnapshot: data
  }));
};

const getEmailSubs = async (
  setApiIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccessfullyVerifiedEmail: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  userId: string
) => {
  if (!userId) {
    setError('It does not look like you should be here, please return to login');
    return setApiIsLoading(false);
  }
  setApiIsLoading(true);
  const data: any = await getUsersUnsubOptions(userId);
  if (data.length > 0 && data[0].errorType === 'ValidationError') {
    setApiIsLoading(false);
    return setError(`Error with validating who you are. If this is an issue please`);
  }
  if (data === 'Not a valid encrypted userID') {
    setApiIsLoading(false);
    return setError(`It doesn't appear you are validated to be here. If this is an issue please`);
  }
  if (data.type === 'invalid-json' || data.code === 'ENOTFOUND') {
    setApiIsLoading(false);
    return setError('Something went wrong loading this page, please go back to try again. ' +
      'If the issue persists please');
  }
  if (data === "JWT not valid") {
    setError('Unable to verify who you are, please try click on the email link again');
    setSuccessfullyVerifiedEmail(false);
    return setApiIsLoading(false);
  }
  setSuccessfullyVerifiedEmail(true);
  setInitialCheckBoxStateUnsub(data, setCheckboxState);
  setApiIsLoading(false);
};

const returnLoginClick = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  clickType: string,
  navigate: NavigateFunction
) => {
  navigate('/');
};

const cancelButtonClick = async (
  checkboxState: CheckboxState,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
) => {
  setCheckboxState((prev: any) => ({
    ...prev,
    initialCheckboxesState: checkboxState.initialCheckboxesSnapshot
  }));
};

const saveClick = async (
  checkboxState: Checkboxes,
  setSubmitIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  enqueueSnackbar: any,
  id: string
) => {
  await setSubmitIsLoading(true);
  try {
    const response: any = await updateUsersEmailSubscriptionsUnsub(checkboxState, id);
    setCheckboxState((prev: any) => ({
      ...prev,
      initialCheckboxesState: response,
      initialCheckboxesSnapshot: response
    }));
    enqueueSnackbar('Your email subscription settings have been updated', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Something went wrong when trying to do this. Please try again.', { variant: 'Error' });
  }
  await setSubmitIsLoading(false);
}

export {getEmailSubs, returnLoginClick, saveClick, cancelButtonClick}