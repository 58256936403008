import React from 'react';
import {
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {LoginStateType} from '../../pages/login/loginTypes';
import {NavigateFunction} from "react-router";
import {GlobalContextType} from "../../contexts/types/global";

type PasswordInputProps = {
  loginState: LoginStateType;
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>;
  theme: string;
  handleKeyPress: Function;
  navigate: NavigateFunction,
  biometricPrompt: boolean,
  globalContext: GlobalContextType,
  errorLogin: any,
  enqueueSnackbar: (message: string, options?: any) => void
};

const PasswordInput: React.FC<PasswordInputProps> = (props: PasswordInputProps) => {
  const { t } = useTranslation();
  const {
    loginState,
    setLoginState,
    theme,
    handleKeyPress,
    navigate,
    biometricPrompt,
    globalContext,
    errorLogin,
    enqueueSnackbar
  } = props;

  const visuallyHidden = { display: 'none' };

  return (
    <>
      <InputLabel
        required
        className="inputLabel inputLabelWhite inputLabelTop"
      >
        Password
      </InputLabel>
      <FormControl fullWidth>
        <InputLabel sx={visuallyHidden} htmlFor="password">
          Password field
        </InputLabel>
        <TextField
          className="password-field"
          margin="normal"
          required
          fullWidth
          autoComplete="current-password"
          variant="outlined"
          type={loginState.showPassword ? 'text' : 'password'}
          name="password"
          id="password"
          value={loginState.password}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setLoginState((prev: LoginStateType) => ({
              ...prev,
              password: event.target.value,
              loading: false,
            }));
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(
            event,
            loginState,
            globalContext,
            setLoginState,
            errorLogin,
            enqueueSnackbar
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className="show-password">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setLoginState((prev: LoginStateType) => ({
                      ...prev,
                      showPassword: !loginState.showPassword,
                    }));
                  }}
                  edge="end"
                >
                  {loginState.showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {biometricPrompt &&
        <div className="linksContainerLogin">
          <div className="rememberMeContainer">
            <FormControlLabel
              className={
                theme === 'choices'
                  ? 'link-tamewhite-remember-me-choices'
                  : 'link-tamewhite-remember-me'
              }
              id="rememberMeBtn"
              label={t('LOGIN.REMEMBER_ME') as string}
              control={
                <Checkbox
                  checked={loginState.rememberMe}
                  defaultChecked
                  sx={
                    theme === 'choices'
                      ? {
                        color: '#872046',
                        '&.Mui-checked': {
                          color: '#872046',
                        },
                      }
                      : {
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      }
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLoginState((prev: LoginStateType) => ({
                      ...prev,
                      rememberMe: event.target.checked,
                    }));
                  }}
                />
              }
            />
          </div>
          <div className="links">
            <Link
              className="link-tamewhite link-choices"
              onClick={() => navigate('/forgot-password')}
            >
              Forgot password?
            </Link>
          </div>
        </div>
      }
    </>
  );
};

export default PasswordInput;