import {Link, Typography} from "@mui/material";
import React from 'react';
import {CompleteAccountResponseType, createAccount} from '../../../network-requests/register';
import {RegInfoType, RegistrationPagesEnum} from '../../../contexts/registration';
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../../util/constants";
import pushRouteChangeEvent, {setDataAnalMetaData} from '../../../util/google-tag-manager';
import {AddressValidationFields} from "./addresses-types";
import {address2Validator, addressValidator, cityValidator, postalCodeValidator} from '../../../util/validator';
import {IS_PRODUCTION} from '../../../util/constants';

const finalSubmit = async (
  setAddressFields: React.Dispatch<React.SetStateAction<AddressValidationFields>>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  enqueueSnackbar: any,
  t: any,
  theme: string
) => {
  setAddressFields((prev: AddressValidationFields) => ({ ...prev, submitting: true }));
  try {
    let response: Response = await createAccount(registrationContext, theme);
    if (response.status !== 200) {
      let msg: any = await response.text();
      if (response.status === 503 && msg === 'Wallet services unavailable') {
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          currentPage: RegistrationPagesEnum.RegisterCompletePage
        }));
      } else if (!IS_PRODUCTION && response.status === 500 && msg === 'ECONNRESET') {
        enqueueSnackbar(
          <>
            <Typography
              sx={{marginRight: '5px', marginLeft: '5px', cursor: 'pointer'}}>
              Failed due to connection reset error. Please try again later.
            </Typography>
          </>,
          {variant: 'error'}
        );
      } else {
        enqueueSnackbar(
          <>
            <Typography
              sx={{marginRight: '5px', marginLeft: '5px', cursor: 'pointer'}}>
              An unexpected error occurred. Please try again. If this issue persists please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white"
              >
                contact us
              </Link>
            </Typography>
          </>,
          {variant: 'error'}
        );
      }
    } else {
      let resultJSON: CompleteAccountResponseType = await response.json();
      setDataAnalMetaData({ prid: resultJSON.publicReference.toString() });
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        currentPage: RegistrationPagesEnum.RegisterCompletePage,
        wallet: resultJSON.wallet,
        moreRewardsCard: resultJSON.card.number,
        moreRewardsActivationCode: resultJSON.card.ac
      }));
    }
    // catch error if api down
  } catch (e) {
    enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
  }
  // set submit flag to false to send again if error
  setRegistrationContext((prev: RegInfoType) => ({
    ...prev,
    submit: false
  }));
  setAddressFields((prev: AddressValidationFields) => ({ ...prev, submitting: false }));
};

const onSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  addressFields: AddressValidationFields,
  setAddressFields: React.Dispatch<React.SetStateAction<AddressValidationFields>>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  enqueueSnackbar: any,
  t: any
) => {
  event.preventDefault();
  let inputError: boolean = false;
  if (addressValidator(addressFields.addressLine1, t).length !== 0) {
    setAddressFields((prev: AddressValidationFields) => ({
      ...prev,
      addressLine1HelperText: addressValidator(addressFields.addressLine1, t)
    }));
    inputError = true;
  }
  if (address2Validator(addressFields.addressLine2, t).length !== 0) {
    setAddressFields((prev: any) => ({
      ...prev,
      addressLine2HelperText: address2Validator(addressFields.addressLine2, t)
    }));
    inputError = true;
  }
  if (postalCodeValidator(addressFields.postalCode, t).length !== 0) {
    setAddressFields((prev: AddressValidationFields) => ({
      ...prev,
      postalCodeHelperText: postalCodeValidator(addressFields.postalCode, t)
    }));
    inputError = true;
  }
  if (cityValidator(addressFields.city, t).length !== 0) {
    setAddressFields((prev: any) => ({
      ...prev,
      cityHelperText: cityValidator(addressFields.city, t)
    }));
    inputError = true;
  }
  if (!addressFields.province) {
    setAddressFields((prev: any) => ({
      ...prev,
      provinceHelperText: t('VALIDATOR.PROVENCE.REQUIRED')
    }));
    inputError = true;
  }
  if (!addressFields.isAgreeTOS) {
    setAddressFields((prev: any) => ({
      ...prev,
      isAgreeTOSHelperText: t('You must agree to the Privacy Policy and Terms of Use.')
    }));
    inputError = true;
  }
  if (inputError) {
    return;
  }
  await setRegistrationContext((prev: RegInfoType) => ({
    ...prev,
    addressLine1: addressFields.addressLine1,
    addressLine2: addressFields.addressLine2,
    city: addressFields.city,
    postalCode: addressFields.postalCode.replace(/[ -]/, '').toUpperCase(),
    province: addressFields.province,
    country: addressFields.country,
    submit: true
  }));
};

const setFieldsOnMount =  (
  setAddressFields: React.Dispatch<React.SetStateAction<AddressValidationFields>>,
  addressFields: AddressValidationFields,
  registrationContext: RegInfoType
) => {
  pushRouteChangeEvent('Register: AddressPage');
  setAddressFields((prev: AddressValidationFields) => ({ // Set autofilled address from existing card on component mount if it exists
    ...prev,
    addressLine1: registrationContext.addressLine1 ? registrationContext.addressLine1 : '',
    addressLine2: registrationContext.addressLine2 ? registrationContext.addressLine1 : '',
    postalCode: registrationContext.postalCode ? registrationContext.postalCode : '',
    province: registrationContext.province ? registrationContext.province : '',
    city: registrationContext.city ? registrationContext.city : ''
  }));
}

export {finalSubmit, onSubmit, setFieldsOnMount}