import React from "react";
import {emailValidator} from "../../../util/validator";
import {sendVerifyEmail} from "../../../network-requests/register";
import {handleThemeRedirection} from "../../my-account/myAccountHelpers";
import {RegInfoType} from "../../../contexts/registration";
import {TFunction} from "i18next";
import {pushSpecificEvent} from "../../../util/google-tag-manager";
import {GlobalContextType} from "../../../contexts/types/global";
import { NavigateFunction } from "react-router-dom";
import {displayUnexpectedError} from "../../../components/notifications/errors/display-unexpected-error";
import {
  displaySendVerificationEmailConfirmation
} from "../../../components/notifications/success/send-email-confirmation-success";
import {displayCustomError} from "../../../components/notifications/errors/display-custom-error";
import {HTTP_STATUS_OK, HTTP_STATUS_TOO_MANY_REQUESTS, REDIRECT_TIMEOUT} from "../../../util/constants";

const handleSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  email: string,
  setEmailHelperText: React.Dispatch<React.SetStateAction<string>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  enqueueSnackbar: any,
  setEmailIsSent: React.Dispatch<React.SetStateAction<boolean>>,
  t: TFunction,
  theme: string,
  globalContext: GlobalContextType,
  navigate: NavigateFunction
) => {
  event.preventDefault();
  if (emailValidator(email, t).length !== 0) { // check if email is valid if not stop process
    setEmailHelperText(emailValidator(email, t));
    return;
  }
  // disable the submit button
  setEmailIsSent(true);
  sendVerifyEmail(email, theme).then((r: Response) => { // if not successful display error response and stop
    if (r.status !== HTTP_STATUS_OK) {
      if (r.status === HTTP_STATUS_TOO_MANY_REQUESTS) {
        r.text().then((msg: string) => { displayCustomError(enqueueSnackbar, theme, msg) });
        return;
      }
      r.text().then(() => displayUnexpectedError(enqueueSnackbar, theme));
      return;
    }
    setRegistrationContext((prev: RegInfoType) => ({ ...prev, email: email }));
    pushSpecificEvent('Register: VerifyEmailPage - Email Sent');
    displaySendVerificationEmailConfirmation(enqueueSnackbar, theme, email);
    setEmailHelperText('');
    if (globalContext.isWebview) {
      navigate('/'); // If the user logged in on a mobile web view, take them back to the login page preserving the 'goto'
    } else {
      setTimeout(() => handleThemeRedirection(theme, globalContext.trafficType), REDIRECT_TIMEOUT); // Otherwise punt them back to the relevant banner on desktop
    }
  }).catch(() => enqueueSnackbar(t('ERRORS.API_OFFLINE'), {variant: 'error'}));
};

export {handleSubmit}