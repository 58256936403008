import React from 'react';
import {getRecaptchaScore, getRecaptchaV2} from '../../../network-requests/resources';
import {RECAPTCHA_V3_KEY} from '../../../util/constants';
import {GlobalContextType} from '../../../contexts/types/global';
import {OAuth2Tokens} from "../../../network-requests/network-request-types";
import {LoginStateType} from "../loginTypes";

declare const window: any;

// declare our custom types we need to use
export type recaptchaScoreV3 = {
  success?: boolean,
  challenge_ts?: string,
  hostname?: string,
  score?: number,
  action?: string
}

const calculateRecaptchaOnMount = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  isAuth: OAuth2Tokens | void,
  enqueueSnackbar: any,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
) => {
  if (document.getElementById('recaptcha-v3') === null) {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + RECAPTCHA_V3_KEY;
    // @ts-ignore
    script.addEventListener('load', getInitialRecaptchaScoreOnLoad(
      enqueueSnackbar,
      loginState,
      setLoginState
    ));
    script.id = 'recaptcha-v3';
    document.body.appendChild(script);
  }
};

// Callback function to get the inital recaptcha score on load
const getInitialRecaptchaScoreOnLoad = async (
  enqueueSnackbar: any,
  loginState: LoginStateType,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>
): Promise<void> => {
  if (window.grecaptcha !== undefined && loginState.recaptchaScore.success !== null) {
    window.grecaptcha.ready(((event: any) => {
      window.grecaptcha
        .execute(RECAPTCHA_V3_KEY, { action: 'SSOFlow' })
        .then(async (token: any) => {
          await getRecaptchaScore(
            token,
            null,
            enqueueSnackbar,
            setLoginState
          );
        });
    }));
  }
};

export {
  getInitialRecaptchaScoreOnLoad,
  getRecaptchaScore,
  getRecaptchaV2,
  calculateRecaptchaOnMount
};