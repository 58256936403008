import {Route, Routes, useSearchParams} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import ForgotPasswordPage from './pages/forgot-password/forgot-password';
import {ProtectedRoute} from './pages/my-account/protected-routes/protectedRoutes';
import {UnProtectedRoute} from './pages/my-account/unprotected-routes/unprotectedRoutes';
import {createBrowserHistory} from 'history';
import {ThemeSelector} from './components/theme-selector/themeSelector';
import ChangePasswordPage from './pages/forgot-password/change-password';
import VerifyEmailExpiredPage from './pages/register/email-verification-expired/email-verification-expired';
import {TermsAndConditionsList} from './pages/terms-and-conditions/terms-and-conditions-list';
import {ErrorMessageDisplay} from './pages/testing/error-message-display';
import {Provider} from 'jotai';
import SharedLoginPage from './pages/login/login';
import RegistrationPageRouter from './pages/register/0-register-router';
import MyAccountPage from './pages/my-account/myAccount';
import UnsubPage from './pages/unsubscribe-page/unsubscribe';
import MoreRewardsTravelGiftCard from './pages/terms-and-conditions/more-rewards-travel-gift-card-conditions';
import MoreRewardsTravelTermsConditions from './pages/terms-and-conditions/more-rewards-travel-terms-conditions';
import UnknownPage from './pages/404/404';
import ErrorPage from './pages/500/500';
import MaintenancePage from './pages/maintenance/maintenance';
import CallbackPage from './pages/login/callback';
import LogoutAllSPsAndWebView from './pages/login/logoutAllSPsAndWebView';
import ChangeEmailConfirmation from './pages/my-account/change-email-confirmation-screen/changeEmailConfirmation';
import HandlePrid from './pages/login/handle-prid';

// @ts-ignore
import {Helmet} from 'react-helmet';
import {OAuth2Tokens} from "./network-requests/network-request-types";
import {Theme} from "./pages/login/helpers/helpers";
import WelcomeMessagePage from "./pages/welcome-message/welcome-message";
import ChangePasswordSuccessPage from './pages/forgot-password/change-password-success';
import {getSessionReferer} from "./util/referer";
import {CLIENT_ID} from './util/constants';
import './styling/global/globalStyles.scss';
import './App.css';
import './i18n';
import {getMessage} from "./network-requests/resources";
import PersonIcon from "@mui/icons-material/Person";

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: 'loginReplatform' });

export type loginProps = {
  isAuth: OAuth2Tokens | void,
  openSnackBarLogin: boolean,
  setOpenSnackBarLogin: React.Dispatch<React.SetStateAction<boolean>>,
  errorLogin: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  successfulEmailChange: string
  setOpenSnackBarEmailSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  openSnackBarEmailSuccess: boolean,
  setIsAuth: any,
  goto: any,
  theme: Theme
}

export type protectedRouteProps = {
  isAuth: OAuth2Tokens | void,
  setGoto: React.Dispatch<React.SetStateAction<string>>,
  children: React.ReactElement

}

export type HandlePridProps = {
  goto: string,
  setGoTo: React.Dispatch<React.SetStateAction<string>>
}

export type MyAccountPagesProps = {
  rehydrateApplication: boolean
  setRehydrateApplication: React.Dispatch<React.SetStateAction<boolean>>,
  isAuth: OAuth2Tokens | void,
  setIsAuth: any,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  goto: string,
  theme: string
}

export type RegisterPageRouterProps = {
  theme: string
}

// top level component of component tree
function App() {
  const [searchParams] = useSearchParams();
  const [goto, setGoto] = useState<string>(
    useMemo(() => searchParams.get('goto') ?? '', [])
  );
  const [rehydrateApplication, setRehydrateApplication] = useState<boolean>(false);
  const [openSnackBarLogin, setOpenSnackBarLogin] = useState<boolean>(false);
  const [openSnackBarEmailSuccess, setOpenSnackBarEmailSuccess] = useState<boolean>(false);
  const [errorLoginPage, setErrorLoginPage] = useState<string>(null);
  const [successfulEmailChange] = useState<string>('');
  const [isAuth, setIsAuth] = useState<OAuth2Tokens>(null);
  const [theme, setTheme] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(null);
  const [messageToDisplay, setMessageToDisplay] = useState(null);
  const [messageColor, setMessageColor] = useState(null);
  const [messageBackground, setMessageBackground] = useState(null);

  /* set authentication state when spa is mounted
   * would like to implement a callback function here to use the ForgeRock SDK
   * here to verify a users iPlanet Session/Cookie */
  useEffect(() => {
    const verifyAccessToken = async () => {
      if (!isAuth) {
        setIsAuth(JSON.parse(localStorage.getItem(CLIENT_ID)));
      }
    };
    verifyAccessToken();
    const theme: string = getSessionReferer();
    setTheme(theme);
    const displayMessage = async () => {
      let resMessage: any = await getMessage();
      if (resMessage.status === 299) {
        let messageJson = await resMessage.json();
        if (messageJson.display === true) {
          sessionStorage.setItem('message', messageJson.message);
          sessionStorage.setItem('display','true')
          if (messageJson.background) sessionStorage.setItem('messageBackground', messageJson.background);
          if (messageJson.color) sessionStorage.setItem('messageColor', messageJson.color);
        }
      } else {
        sessionStorage.removeItem('display');
        sessionStorage.removeItem('message');
        sessionStorage.removeItem('messageColor');
        sessionStorage.removeItem('messageBackground');
      }
      setDisplayMessage(sessionStorage.getItem('display'));
      setMessageToDisplay(sessionStorage.getItem('message'));
      setMessageColor(sessionStorage.getItem('messageColor'));
      setMessageBackground(sessionStorage.getItem('messageBackground'));
    }
    displayMessage();
  }, [])

  return (
    <ThemeSelector>
      <div data-testid="app">
        {(displayMessage && messageToDisplay && displayMessage === 'true') ?
          <div className={(messageColor) ? 'top-message-blank' : 'top-message'} style={{backgroundColor: messageBackground ? messageBackground : '', color: messageColor ? messageColor : ''}}>{messageToDisplay}</div>
          :
          <></>
        }
        <Routes>
          <Route
            path="/"
            element={
              <>
                {(() => {
                  switch (theme) {
                    case 'choices':
                      return <Helmet>
                        <title>Sign In | Choices Markets</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'saveonfoods':
                      return <Helmet>
                        <title>Sign In | Save-On-Foods</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'urbanfare':
                      return <Helmet>
                        <title>Sign In | Urban Fare</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'pricesmartfoods':
                      return <Helmet>
                        <title>Sign In | Price Smart Foods</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'qualityfoods':
                      return <Helmet>
                        <title>Sign In | Quality Foods</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    default:
                      return <Helmet>
                        <title>Sign In | More Rewards</title>
                        <meta name="description" content="Sign in with your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                  }})()}
                <UnProtectedRoute isAuth={isAuth} setGoto={setGoto}>
                  <SharedLoginPage
                    isAuth={isAuth}
                    openSnackBarLogin={openSnackBarLogin}
                    setOpenSnackBarLogin={setOpenSnackBarLogin}
                    errorLogin={errorLoginPage}
                    setErrorLogin={setErrorLoginPage}
                    successfulEmailChange={successfulEmailChange}
                    setOpenSnackBarEmailSuccess={setOpenSnackBarEmailSuccess}
                    openSnackBarEmailSuccess={openSnackBarEmailSuccess}
                    setIsAuth={setIsAuth}
                    goto={goto}
                    theme={theme}
                  />
                </UnProtectedRoute>
              </>
            }/>
          <Route
            path="/register/*"
            element={
              <>
                {(() => {
                  switch (theme) {
                    case 'choices':
                      return <Helmet>
                        <title>Register | Choices Markets</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'saveonfoods':
                      return <Helmet>
                        <title>Register | Save-On-Foods</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'urbanfare':
                      return <Helmet>
                        <title>Register | Urban Fare</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'pricesmartfoods':
                      return <Helmet>
                        <title>Register | Price Smart Foods</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    case 'qualityfoods':
                      return <Helmet>
                        <title>Register | Quality Foods</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                    default:
                      return <Helmet>
                        <title>Register | More Rewards</title>
                        <meta name="description" content="Register your More Rewards ID to manage your profile or shop online at applicable Pattison Food Group websites"/>
                      </Helmet>
                  }})()}
                <UnProtectedRoute isAuth={isAuth} setGoto={setGoto}>
                  <RegistrationPageRouter theme={theme}/>
                </UnProtectedRoute>
              </>
            }/>
          <Route path="/userPage/*" element={
            <ProtectedRoute isAuth={isAuth} setGoto={setGoto}>
              <Provider>
                <MyAccountPage
                  isAuth={isAuth}
                  rehydrateApplication={rehydrateApplication}
                  setRehydrateApplication={setRehydrateApplication}
                  setIsAuth={setIsAuth}
                  setErrorLogin={setErrorLoginPage}
                  goto={goto}
                  theme={theme}
                />
              </Provider>
            </ProtectedRoute>
          }/>
          <Route path="/unsubscribePage/*" element={<div><UnsubPage theme={theme}/></div>}/>
          <Route
            path="/userPage/change-email/verify-email"
            element={<ChangeEmailConfirmation setIsAuth={setIsAuth} theme={theme}/>}
          />
          <Route path="/terms-and-conditions-all" element={<TermsAndConditionsList theme={theme}/>}/>
          <Route path="/more-rewards-travel-gift-card-terms-conditions" element={<MoreRewardsTravelGiftCard theme={theme}/>}/>
          <Route path="/more-rewards-travel-terms-conditions" element={<MoreRewardsTravelTermsConditions/>}/>
          <Route path="*" element={<UnknownPage theme={theme}/>}/>
          <Route path="/500" element={<ErrorPage theme={theme}/>}/>
          <Route path="/maintenance" element={<MaintenancePage theme={theme}/>}/>
          <Route path="/callback" element={<CallbackPage theme={theme}/>}/>
          <Route path="/handle-prid" element={<HandlePrid goto={goto} setGoTo={setGoto}/>}/>
          <Route path="/error-message-testing" element={<ErrorMessageDisplay/>}/>
          <Route path="/logout" element={<LogoutAllSPsAndWebView goto={goto} theme={theme}/>}/>
          <Route path="/forgot-password" element={<>
            {(() => {
              switch (theme) {
                case 'choices':
                  return <Helmet>
                    <title>Forgot Password | Choices Markets</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
                case 'saveonfoods':
                  return <Helmet>
                    <title>Forgot Password | Save-On-Foods</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
                case 'urbanfare':
                  return <Helmet>
                    <title>Forgot Password | Urban Fare</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
                case 'pricesmartfoods':
                  return <Helmet>
                    <title>Forgot Password | Price Smart Foods</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
                case 'qualityfoods':
                  return <Helmet>
                    <title>Forgot Password | Quality Foods</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
                default:
                  return <Helmet>
                    <title>Forgot Password | More Rewards</title>
                    <meta name="description" content="Reset your More Rewards ID password to manage your profile or shop online at applicable Pattison Food Group websites"/>
                  </Helmet>
              }})()}
            <ForgotPasswordPage theme={theme}/>
          </>}/>
          <Route path="/forgot-password/verify-email" element={<>
            {theme === 'choices' ?
              <Helmet><title>Change Password | Choices Markets</title></Helmet>
              :
              <Helmet><title>Change Password | More Rewards ID</title></Helmet>
            }
            <ChangePasswordPage theme={theme}/>
          </>}/>
          <Route path="/forgot-password/success" element={<>
            {theme === 'choices' ?
              <Helmet><title>Password Changed | Choices Markets</title></Helmet>
              :
              <Helmet><title>Password Changed | More Rewards ID</title></Helmet>
            }
            <ChangePasswordSuccessPage theme={theme}/>
          </>}/>
          <Route path="/forgot-password/email-expired" element={<>
            {theme === 'choices' ?
              <Helmet><title>Change Password Email Expired | Choices Markets</title></Helmet>
              :
              <Helmet><title>Change Password Email Expired | More Rewards ID</title></Helmet>
            }
            <VerifyEmailExpiredPage theme={theme}/>
          </>}/>
          <Route path="/confirm-account-details" element={
            <>
              {theme === 'choices' ?
                <Helmet><title>Confirm Account | Choices Markets</title></Helmet>
                :
                <Helmet><title>Confirm Account | More Rewards ID</title></Helmet>
              }
              <WelcomeMessagePage
                goto={goto}
                isAuth={isAuth}
                setIsAuth={setIsAuth}
                setErrorLogin={setErrorLoginPage}
                theme={theme}
              />
            </>
          }/>
          <Route path="/register/email-expired" element={<>
            <Helmet><title>Registration Email Expired | More Rewards ID</title></Helmet>
            <VerifyEmailExpiredPage theme={theme}/>
          </>}/>
        </Routes>
      </div>
    </ThemeSelector>
  );
}

export default App;