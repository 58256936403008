import React, {useContext, useEffect, useState} from 'react';
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../../util/constants";
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import {RegistrationContext} from '../../../contexts/registration';
import {Button, Card, Grid, Link} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import CardDisplay from '../../../components/card-display/card-display';
import pushRouteChangeEvent, {pushSpecificEvent} from '../../../util/google-tag-manager';
import {GlobalContext} from '../../../contexts/global';
import {appleWalletWithJwt, googlePayWithJwt} from "../../../network-requests/resources";
import {handleThemeRedirection} from "../../my-account/myAccountHelpers";
import './complete-registration-success.scss';

export type CompleteRegistrationSuccess = {
  theme: string
}

// attempting to redeploy and fix the copy on this page in QA
const CompleteRegistrationSuccess = (props: CompleteRegistrationSuccess) => {
  const {theme} = props;
  const {globalContext,} = useContext(GlobalContext);
  const navigate = useNavigate();
  const {t, ready} = useTranslation();
  const {registrationContext, setRegistrationContext} = useContext(RegistrationContext);
  const [displayGooglePay] = useState(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1);
  const [displayApplePay] = useState(
    !(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) &&
    navigator.userAgent.indexOf('Safari') !== -1
  );

  useEffect(() => {
    pushRouteChangeEvent('Register: RegisterCompletePage');
    pushSpecificEvent('Successful registration');
  }, []);

  const handleGooglePayClick = async (mrNumber: string) => {
    try {
      let wallet: Response = await googlePayWithJwt(mrNumber, registrationContext.jwt);
      if (wallet.status != 200) {
        console.error('Error in generating google pay', wallet.text());
        return;
      }
      let jwt: string = await wallet.json();
      window.open(`https://pay.google.com/gp/v/save/${jwt}`, '_blank');
      pushSpecificEvent('Add to Google wallet');
    } catch (e) {
      console.error('Error Downloading Google Wallet', e);
    }
  };

  const appleWalletDownload = async (mrNumber: string) => {
    let wallet: Response = await appleWalletWithJwt(mrNumber, registrationContext.jwt);
    if (wallet.status !== 200) {
      console.error('Error in downloading apple wallet', wallet.text());
      return;
    }
    let walletJson: any = await wallet.json();
    let element: HTMLAnchorElement = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/vnd.apple.pkpass;base64,' +
      encodeURIComponent(walletJson.appleFile)
    );
    element.setAttribute('download', 'apple-wallet.pkpass');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    pushSpecificEvent('Add to Apple wallet');
  };
  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <div className={theme === 'choices' ? "choicesWhiteBoxWide" : ""}>
        <Typography variant="h2" component="h2" className={
          theme === "choices" ?
            "topHeaderChoices" :
            "topHeader"
        }>
          {t('REGISTER.HEADERS.REGISTRATION_COMPLETE')}
        </Typography>
        <Card className="wide-white-box">
          <div className="centeredContainerRegistration">
            <div className="innerColumnRegisterComplete">
              {/* If user registered with a Choices account (we now always Send a Mail card in background) */}
              {(theme === 'choices') &&
                <form
                  noValidate>
                  <>
                    <div
                      dangerouslySetInnerHTML={{__html: t('REGISTER.CREATE_ACCOUNT.CHOICES.TOP_TEXT-CREATE')}}/>
                    <>
                      If you have any questions please&nbsp;
                      <Link
                        href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                        target="_blank"
                        className="link-choices"
                      >
                        Contact us
                      </Link>
                    </>
                  </>
                </form>
              }
              {/* If user did create the account with a mail card and it's not a choices account */}
              {(registrationContext.needMrCard && theme !== 'choices') &&
                <Grid container columnSpacing={{md: 4, lg: 4}}>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <form noValidate>
                      <div dangerouslySetInnerHTML={{__html: t('REGISTER.COMPLETE.WITHOUT_CARD')}}/>
                    </form>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <h2 className="NewCard">Your new card</h2>
                    <CardDisplay
                      card={registrationContext.moreRewardsCard}
                      ac={registrationContext.moreRewardsActivationCode}
                    />
                  </Grid>
                </Grid>
              }
              {/* If user registered a non-choices account & has their own card, don't display the card */}
              {(!registrationContext.needMrCard && theme !== 'choices') &&
                <Grid container columnSpacing={{md: 4, lg: 4}}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <form noValidate>
                      <div dangerouslySetInnerHTML={{__html: t('REGISTER.COMPLETE.WITH_CARD')}}/>
                    </form>
                  </Grid>
                </Grid>
              }
              {globalContext.isWebview ?
                <></>
                :
                <>
                  <div className="walletButtons paddingB0 choicesHide">
                    {(displayApplePay && theme !== 'choices') &&
                      <a
                        onClick={() => appleWalletDownload(registrationContext.moreRewardsCard)}
                        className="addToPayButton"
                      >
                        <img src={require('../../../images/apple-wallet.png')} alt="Add to Apple Wallet"/>
                      </a>
                    }
                    {(displayGooglePay && theme !== 'choices') &&
                      <a
                        onClick={() => handleGooglePayClick(registrationContext.moreRewardsCard)}
                        className="addToPayButton"
                      >
                        <img
                          className="addToPayImage"
                          src={require('../../../images/enCA_add_to_google_wallet_add-wallet-badge.png')}
                          alt="Add to Google Button"
                        />
                      </a>
                    }
                  </div>
                </>
              }
            </div>
          </div>
          <div className="centeredContainer account-steps-nav borderTop">
            <Button
              type="submit"
              variant="contained"
              className="primaryButtonDefault"
              onClick={() => {handleThemeRedirection(theme, globalContext.trafficType);}}
            >{t('BUTTONS.VIEW-EDIT')}</Button>
          </div>
        </Card>
      </div>
    </RegistrationLayout>
  );
};

export default CompleteRegistrationSuccess;
