import {Link, Typography} from "@mui/material";
import {LINK_CONTACT_US, LINK_CONTACT_US_CHOICES} from "../../../util/constants";
import React from "react";

export const displaySendVerificationEmailConfirmation = (
  enqueueSnackbar: any,
  theme: string,
  email: string
): any => {
  return enqueueSnackbar(
    <div className="flexMessage">
      <Typography sx={{ marginRight: '10px', cursor: 'pointer' }}>
        An email has been sent to:&nbsp;<Link className="link-white">{email}</Link>
      </Typography>
      <Typography sx={{ marginTop: '10px' }}>
        Didn't receive an email? Make sure you entered your email correctly and check your Junk mail.
      </Typography>
      <Typography sx={{ marginTop: '10px' }}>
        Still no email? Please&nbsp;
        <Link
          href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
          target="_blank"
          className="link-white"
        >contact us
        </Link>.
      </Typography>
    </div>,
    { variant: 'success' }
  );
}