import React, {useContext, useEffect, useMemo, useState} from 'react';
import RegistrationLayout from '../register/layout/registration-layout';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {RegInfoType, RegistrationContext} from '../../contexts/registration';
import {InputAdornment, InputLabel} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../components/loading/circular-loading';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {handleSubmitChangePassword, startUpChangePassword} from "./helpers";
import {PasswordStrength} from '../../components/password-strength/passwordStrength';
import {useSnackbar} from 'notistack';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import {PasswordInfo} from '../my-account/change-password/changePasswordTypes';
import {GlobalContext} from '../../contexts/global';
import {LINK_CONTACT_US} from "../../util/constants";
import './forgot-password.scss';

export type ChangePasswordProps = {
  theme: string
}

/**
 * Page to change password
 * @constructor
 */
const ChangePasswordPage = (props: ChangePasswordProps) => {
  const { theme } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let jwt = useMemo(() => searchParams.get('jwt'), []);
  const { globalContext, } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    newPassword: {
      input: ''
    },
    oldPassword: {
      input: ''
    },
    confirm: {
      input: ''
    },
    weaknesses: [],
    helperText: '',
    allRequirementsMet: false,
    passwordStrengthInformation: {
      strengthMeterBarColor: 'error',
      strengthMeterText: 'weak'
    },
    passwordStrengthMeterValue: 1,
    warning: '',
    suggestions: []
  });
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState<string>('');
  const [showPass, setShowPass] = useState<boolean>(false);
  const [startUpDone, setStartUpDone] = useState<boolean>(false);
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  useEffect(() => {
    pushRouteChangeEvent('Register: ChangePasswordPage');
  }, []);

  useEffect(() => {
    const checkMatchingPassword = () => {
      passwordInfo.newPassword.input !== passwordInfo.confirm.input ?
        setConfirmPasswordHelperText('Passwords do not match') :
        setConfirmPasswordHelperText('')
    }
    checkMatchingPassword();
  });

  useEffect(() => {
    if (!registrationContext.jwt) {
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        jwt: jwt
      }));
    }
  }, [jwt]);

  // take url jwt and validate, if valid, render this page with email populated
  useEffect(() => {
    if (ready) {
      startUpChangePassword(
        registrationContext,
        setRegistrationContext,
        setStartUpDone,
        navigate,
        enqueueSnackbar,
        t,
        theme
      );
    }
  }, [ready]);

  if (!ready || !startUpDone) {
    return (
      <RegistrationLayout>
        <div className="loadingSpinnerContainer">
         <CircularLoading theme={theme}/>
        </div>
      </RegistrationLayout>
    )
  }

  // @ts-ignore
  return (
    <RegistrationLayout>
      <form
        className={globalContext.referer === 'choices' ? "choicesWhiteBox" : ""}
        noValidate
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmitChangePassword(
          event,
          passwordInfo,
          setConfirmPasswordHelperText,
          registrationContext,
          setPasswordHelperText,
          navigate,
          enqueueSnackbar,
          t,
          setPasswordChanged
        )}>
        <Typography variant="h2" component="h2" className="topHeader">
          {t('REGISTER.HEADERS.CHANGE_PASSWORD')}</Typography>
        {<Typography
          component="p"
          variant="body2"
          className={theme === "choices" ? "topTextRegisterChoices" : "topTextRegister"}>
          Please enter and confirm your new password.<br/>If you have any trouble, please&nbsp;<a
          className='link-white-changePassword link-choices'
          href={LINK_CONTACT_US}
          target='_blank'>contact us</a>.
        </Typography>}
        <div className="centeredContainer">
          <div className="innerColumn">
            <div className="noBG-form">
              <InputLabel
                required
                className="inputLabel inputLabelWhite">{t('REGISTER.CHANGE_PASSWORD.NEW_PASS')}
              </InputLabel>
              <TextField
                id="chgPass"
                autoComplete="new-password"
                className="fullWidth password-field"
                required
                type={showPass ? 'text' : 'password'}
                // autoFocus
                error={passwordHelperText !== '' ||
                  passwordInfo.passwordStrengthMeterValue <= 50 ||
                  !passwordInfo.allRequirementsMet
                }
                helperText={passwordHelperText}
                onChange={(event) => {
                  setPasswordInfo((prev: PasswordInfo) => ({
                    ...prev,
                    newPassword: {
                      ...prev.newPassword,
                      input: event.target.value
                    }
                  }));
                  if (confirmPasswordHelperText) {
                    setConfirmPasswordHelperText(event.target.value ===
                      passwordInfo.confirm.input ? '' :
                        t('VALIDATOR.PASSWORD.NOT_MATCH')
                    );
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" className="show-password">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass(!showPass)}
                        edge="end"
                      >
                        {showPass ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                }}/>
              <div className="centeredContainer whiteBG marginB16">
                <div className="innerColumn">
                  <PasswordStrength
                    passwordInfo={passwordInfo}
                    setPasswordInfo={setPasswordInfo}
                    pageType={'Register'}
                  />
                </div>
              </div>
              <InputLabel
                required
                className="inputLabel inputLabelWhite">{t('REGISTER.CHANGE_PASSWORD.CONFIRM_PASS')}
              </InputLabel>
              <TextField
                autoComplete="new-password"
                className="fullWidth password-field"
                required
                type={showPass ? 'text' : 'password'}
                error={confirmPasswordHelperText !== ''}
                helperText={confirmPasswordHelperText}
                onChange={(event) => {
                  setPasswordInfo((prev: PasswordInfo) => ({
                    ...prev,
                    confirm: {
                      ...prev.confirm,
                      input: event.target.value
                    }
                  }));
                  setConfirmPasswordHelperText(
                    event.target.value === passwordInfo.newPassword.input ? '' :
                      t('VALIDATOR.PASSWORD.NOT_MATCH')
                  );
                  if (event.target.value === passwordInfo.newPassword.input) {
                    setPasswordChanged(false);
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" className="show-password">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPass(!showPass)}
                        edge="end"
                      >
                        {showPass ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                }}/>
            </div>
          </div>
        </div>
        <div className="centeredContainer account-steps-nav-cancel">
          <Button
            id="nextStepBtn"
            type="submit"
            variant="contained"
            className="primaryButtonDefault"
            // @ts-ignore
            disabled={
              passwordInfo.passwordStrengthMeterValue <= 50 ||
              !passwordInfo.allRequirementsMet ||
              passwordInfo.anyInputIsEmpty ||
              confirmPasswordHelperText ||
              passwordChanged
            }
          >{t('BUTTONS.SAVE')}
          </Button>
          <Button
            variant="outlined"
            className="cancelButtonDefault"
            onClick={() => {
              navigate('/');
            }}
          >{t('BUTTONS.CANCEL')}</Button>
        </div>
      </form>
    </RegistrationLayout>
  );
};

export default ChangePasswordPage;