import PageFooter from '../../components/footer/footer';
import React, {useEffect} from 'react';
import Container from '@mui/material/Container';
import {Link} from '@mui/material';
import pushRouteChangeEvent from '../../util/google-tag-manager';

export type TermsAndConditionsList = {
  theme: string
}

export const TermsAndConditionsList = (props: TermsAndConditionsList) => {
  const { theme } = props;
  useEffect(() => {
    pushRouteChangeEvent('TOS: TermsAndConditionsList');
  }, []);
  return <>
    <Container>
      <h2>Terms & Conditions</h2>
      <ul>
        <li><Link href="/more-rewards-travel-gift-card-terms-conditions">More Rewards Travel Gift Card Terms &
          Conditions</Link></li>
        <li><Link href="/more-rewards-travel-terms-conditions">More Rewards Travel Terms & Conditions</Link></li>
        <li><Link href="/files/terms-and-conditions/841.2-More%20Rewards%20TC%20(2021)_0.pdf">More Rewards® Program
          Terms and Conditions</Link></li>
      </ul>
    </Container>
    <PageFooter isLogin={false} theme={theme}/>
  </>;
};