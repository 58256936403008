import {CANADA_POST_DETAILED_ADDRESS_URL, CANADA_POST_KEY, CANADA_POST_SEARCH_URL} from '../util/constants';

/**
 * Searches AddressComplete API for a search term and returns search results.
 */
export const searchCanadaPost = async (searchTerm: string): Promise<SearchResultsType[]> => {
  if (searchTerm.length == 0) {
    return [];
  }
  let url = CANADA_POST_SEARCH_URL;
  let params = '';
  params += '&Key=' + encodeURIComponent(CANADA_POST_KEY);
  params += '&SearchTerm=' + encodeURIComponent(searchTerm);
  params += '&LastId=' + encodeURIComponent('');
  params += '&Country=' + encodeURIComponent('CAN');
  params += '&LanguagePreference=' + encodeURIComponent('en');
  params += '&MaxSuggestions=' + encodeURIComponent(7);

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }, body: params
  });

  if (response.status == 200) {
    let responseJSON = await response.json();
    // Test for an error
    if (responseJSON.Items.length == 1 && typeof (responseJSON.Items[0].Error) != 'undefined') {
    } else {
      // Check if there were any items found
      if (responseJSON.Items.length == 0) {
      } else {
        console.debug(responseJSON.Items);
        return responseJSON.Items;
      }
    }
  }
  return [];
};

/**
 * Searches AddressComplete API for a search term and returns search results.
 * @param lastId "id" param in SearchResultsType
 */
export const searchCanadaPostLastId = async (lastId: string): Promise<SearchResultsType[]> => {
  if (lastId.length == 0) {
    return [];
  }
  let url = CANADA_POST_SEARCH_URL;
  let params = '';
  params += '&Key=' + encodeURIComponent(CANADA_POST_KEY);
  params += '&SearchTerm=' + encodeURIComponent('');
  params += '&LastId=' + encodeURIComponent(lastId);
  params += '&Country=' + encodeURIComponent('CAN');
  params += '&LanguagePreference=' + encodeURIComponent('en');
  params += '&MaxSuggestions=' + encodeURIComponent(20);

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }, body: params
  });

  if (response.status == 200) {
    let responseJSON = await response.json();
    // Test for an error
    if (responseJSON.Items.length == 1 && typeof (responseJSON.Items[0].Error) != 'undefined') {
    } else {
      // Check if there were any items found
      if (responseJSON.Items.length == 0) {
      } else {
        return responseJSON.Items;
      }
    }
  }
  return [];
};
/**
 * Searches AddressComplete Interactive Retrieve API and returns a detailed address.
 * @param id the id param in SearchResultsType
 */
export const getDetailedCanadaPostAddress = async (id: string): Promise<DetailedResultType | undefined> => {
  let url = CANADA_POST_DETAILED_ADDRESS_URL;
  let params = '';
  params += '&Key=' + encodeURIComponent(CANADA_POST_KEY);
  params += '&Id=' + encodeURIComponent(id);

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }, body: params
  });

  if (response.status == 200) {
    let responseJSON = await response.json();
    // Test for an error
    if (responseJSON.Items.length == 1 && typeof (responseJSON.Items[0].Error) != 'undefined') {
      // Show the error message
      console.error('Error in getDetailedCanadaPostAddress:', responseJSON.Items[0].Description);
    } else {
      // Check if there were any items found
      if (responseJSON.Items.length == 0) {
        console.error('No results in getDetailedCanadaPostAddress');
      } else {
        console.debug(responseJSON.Items);
        return responseJSON.Items.find((item: DetailedResultType) => item.Language == 'ENG');
      }
    }
  }
};

export type SearchResultsType = {
  Id: string
  Text: string,
  Description: string,
  Next: string
}
export type DetailedResultType = {
  Id: string;
  DomesticId: string;
  Language: string;
  LanguageAlternatives: string;
  Department: string;
  Company: string;
  SubBuilding: string;
  BuildingNumber: string;
  BuildingName: string;
  SecondaryStreet: string;
  Street: string;
  Block: string;
  Neighbourhood: string;
  District: string;
  City: string;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Line5: string;
  AdminAreaName: string;
  AdminAreaCode: string;
  Province: string;
  ProvinceName: string;
  ProvinceCode: string;
  PostalCode: string;
  CountryName: string;
  CountryIso2: string;
  CountryIso3: string;
  CountryIsoNumber: string;
  SortingNumber1: string;
  SortingNumber2: string;
  Barcode: string;
  POBoxNumber: string;
  Label: string;
  Type: string;
  DataLevel: string;
  AcRbdi: string;
  AcMua: string;
}