import {CheckboxOptions} from '../emailSubscriptionsTypes';

export const checkboxOptions: CheckboxOptions = [
  {
    keyDescription: 'moreRewardsProgram',
    title: 'More Rewards',
    details: 'find great ways to earn points to use towards great rewards',
    youWillRecieve: {
      first: 'Get weekly points and redemption offers, reminders to load your digital coupons in My Offers, partner promotions, and more!'
    }
  },
  {
    keyDescription: 'saveOnFoodsFlyerSpecials',
    title: 'Save-On-Foods',
    youWillRecieve: {
      first: 'Get savings tips & inspiration; including weekly Darrell’s Deals, Hot Offers, new product announcements, contests, and more!',
    }
  },
  {
    keyDescription: 'urbanFareSpecials',
    title: 'Urban Fare',
    youWillRecieve: {
      first: 'Exclusive content to inspire your inner chef, including recipe ideas, in-store specials and the weekly Feature Fare.'
    }
  },
  {
    keyDescription: 'priceSmartSpecials',
    title: 'PriceSmart Foods',
    youWillRecieve: {
      first: 'Receive exclusive ways to save and access to seasonal promotions such as Lunar New Year and Mid-Autumn Festival.'
    }
  },
  {
    keyDescription: 'qualityFoodsSpecials',
    title: 'Quality Foods',
    youWillRecieve: {
      first: 'Receive savings and promotions straight to your inbox including the weekly Quality Foods flyers, BOGO sales, and other special events.'
    }
  }
];