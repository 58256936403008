import React, {useContext} from 'react';
import {
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
} from '@mui/material';
import {GlobalContext} from "../../contexts/global";
import {GlobalContextType} from "../../contexts/types/global";


type EmailInputProps = {
  visuallyHidden: React.CSSProperties;
};

const EmailInput: React.FC<EmailInputProps> = (props: EmailInputProps) => {
  const { globalContext, setGlobalContext} = useContext(GlobalContext);
  const { visuallyHidden } = props;

  return (
    <>
      <InputLabel required className="inputLabel inputLabelWhite">
        Email
      </InputLabel>
      <FormControl fullWidth>
        <InputLabel sx={visuallyHidden} htmlFor="email">
          Email field
        </InputLabel>
        <TextField
          margin="normal"
          required
          fullWidth
          autoComplete="email"
          variant="outlined"
          id="email"
          name="email"
          value={globalContext.loginInformation.email}
          // autoFocus
          // @ts-ignore
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setGlobalContext((prev: GlobalContextType) => ({
              ...prev,
              loginInformation: {
                ...prev.loginInformation,
                email: event.target.value.trim(),
              },
            }));
            event.target.value = event.target.value.trim();
          }}
          aria-describedby="email-helper-text"
          InputProps={{
            'aria-label': 'Email field',
          }}
        />
        <FormHelperText id="email-helper-text" sx={visuallyHidden}>
          email field.
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default EmailInput;