import React, {useContext, useEffect, useState} from 'react';
import RegistrationLayout from '../register/layout/registration-layout';
import Typography from '@mui/material/Typography';
import {GlobalContext} from '../../contexts/global';
import {Link} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import pushRouteChangeEvent from '../../util/google-tag-manager';
import './forgot-password.scss';

export type ChangePasswordSuccessPageProps = {
  theme: string
}

/**
 * @constructor
 */
const ChangePasswordSuccessPage = (props: ChangePasswordSuccessPageProps) => {
  const { theme } = props;
  const {globalContext,} = useContext(GlobalContext);
  const navigate = useNavigate();
  const {t, ready} = useTranslation();
  const [redirectTime, setRedirectTime] = useState(10);

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    navigate('/');
  };

  useEffect(() => {
    pushRouteChangeEvent('Register: ChangePasswordSuccessPage');
  }, []);

  useEffect(() => {
    if (redirectTime <= 0) {
      navigate('/');
    } else {
      setTimeout(() => setRedirectTime(redirectTime - 1), 1000);
    }
  }, [redirectTime]);

  if (!ready) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <RegistrationLayout>
      <form
        className={globalContext.referer === 'choices' ? "choicesWhiteBox" : ""}
        onSubmit={handleSubmit}
        noValidate
      >
        <Typography variant="h2" component="h2" className="topHeader">
          {t('REGISTER.CHANGE_PASSWORD_SUCCESS.DESC')}
        </Typography>
        <Typography variant="body2" component="p" className="youWillBeRedirected">
          {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.REDIRECTED_IN', {redirectTime})}
        </Typography>
        <div className="center-text">
          <Link onClick={() => handleSubmit()} className={
            globalContext.referer === "choices" ?
              "clickHere link-choices" :
              "clickHere link-white"
          }>Click here</Link>
          <Typography
            component="p"
            className="redirectNow">
            {t('REGISTER.EMAIL_VERIFICATION_EXPIRED.REDIRECT_NOW')}
          </Typography>
        </div>
      </form>
    </RegistrationLayout>
  );
};

export default ChangePasswordSuccessPage;