// Format postal code with a space on the frontend
const formatPostalCode = (postalCodeValue: string): string => {
  if (!postalCodeValue) {
    return postalCodeValue;
  }
  const postalCode: string = postalCodeValue.trim().replace(/[^\d|a-zA-Z]/g, '');
  const postalCodeLength: number = postalCode.length;
  if (postalCodeLength < 4) {
    return postalCode;
  } else if (postalCodeLength < 7) {
    return `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`;
  }
  return `${postalCode.slice(0, 3)} ${postalCode.slice(3, 6)}`;
};

const removeSpacePostalCode = (postalCodeValue: string): string => {
  return postalCodeValue.trim().replace(/[- ]/g, '');
}

export {formatPostalCode, removeSpacePostalCode};