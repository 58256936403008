import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {AddressBookDivs, AddressBookStyles, WindowDimensions} from "./addressesTypes";
import {AddressProps} from '../myAccountTypes';
import {ProfileInfoType, SingleAddress} from '../../../contexts/types/myAccount';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {useAtom} from 'jotai';
import DeleteAddressDialog from "./delete-alert-dialogue/delete-alert-dialogue";
import {
  handleBillingShippingUpdate,
  handleClickOpen
} from "./edit-address-dialogue/editAddressHelpers";
import {generateAddresses} from './generated-addresses/generateAddresses';
import {EditAddressDialog} from './edit-address-dialogue/editDialogue';
import {CircularProgress, Grid} from '@mui/material';
import './addresses.css';

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

/* helper function which perfectly scales the vertical margin height of the loading spinner
 * to scale based on how many alternate addresses exist in the address book :) */
export const calculateAddressSpinnerHeight = (
  alternateAddressesLength: number,
  billingAddressesLength: number,
  shippingAddressesLength: number,
  setAddressBookSpinnerStyle: React.Dispatch<React.SetStateAction<AddressBookStyles>>,
  theme: string
) => {
  let color: string;
  theme === "choices" ? color = "#872046" : color = "#0c60a0";
  const windowDimensions: WindowDimensions = getWindowDimensions();
  switch (alternateAddressesLength) {
    case 0: // calculate perfect margin height of spinner for when there are no alternate addresses
      if (windowDimensions.width < 900 && (billingAddressesLength > 0 && shippingAddressesLength > 0)) {
        return setAddressBookSpinnerStyle((prev: AddressBookStyles) => ({
          ...prev, // for the mobile view, when there is a billing + shipping addr but no alternate, scale this correctly
          minWidth: '150px',
          minHeight: '150px',
          marginTop: '252px',
          marginBottom: '327px',
          marginRight: '400px',
          marginLeft: '400px',
          color
        }))
      } else {
        return setAddressBookSpinnerStyle((prev: AddressBookStyles) => ({
          ...prev,
          minWidth: '150px',
          minHeight: '150px',
          marginTop: '150px',
          marginBottom: '146px',
          marginRight: '400px',
          marginLeft: '400px',
          color
        }))
      }
    default: // otherwise just use this margin height which looks decent enough for all other sizes
      return setAddressBookSpinnerStyle((prev: AddressBookStyles) => ({
        ...prev,
        minWidth: '150px',
        minHeight: '150px',
        marginTop: '251px',
        marginBottom: '327px',
        marginRight: '400px',
        marginLeft: '400px',
        color
      }))
  }
}

export default function Addresses(props: AddressProps): JSX.Element {
  const {loading, theme, isWebview} = props;
  const {enqueueSnackbar} = useSnackbar();
  const [profileInfo] = useAtom<ProfileInfoType>(profileInfoAtom);
  const [addressToEdit, setAddressToEdit] = useState<SingleAddress>(null);
  const [addressToDelete, setAddressToDelete] = useState<SingleAddress>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingBillingShippingUpdate, setLoadingBillingShippingUpdate] = useState<boolean>(false);
  const [addressBookDivs, setAddressBookDivs] = useState<AddressBookDivs>({
    billingAddressesDivs: [],
    shippingAddressDivs: [],
    billingShippingAddressDivs: [],
    alternateAddressDivs: []
  });
  const [addressBookSpinnerStyle, setAddressBookSpinnerStyle] = useState<AddressBookStyles>({
    minWidth: '150px',
    minHeight: '150px',
    marginTop: '150px',
    marginBottom: '126px',
    marginRight: '400px',
    marginLeft: '400px',
    color: '#0c60a0'
  });

  useEffect(() => {
    pushRouteChangeEvent('Profile: ManageAddresses');
  }, []);

  useEffect(() => {
    if (profileInfo.addresses) {
      generateAddresses(
        profileInfo,
        handleClickOpen,
        setOpenDeleteDialogue,
        setAddressToDelete,
        handleBillingShippingUpdate,
        enqueueSnackbar,
        setOpen,
        setAddressToEdit,
        setAddressBookDivs,
        theme,
        setLoadingBillingShippingUpdate
      );
    }
  }, [profileInfo.addresses]);

  useEffect(() => {
    calculateAddressSpinnerHeight(
      addressBookDivs.alternateAddressDivs.length,
      addressBookDivs.billingAddressesDivs.length,
      addressBookDivs.shippingAddressDivs.length,
      setAddressBookSpinnerStyle,
      theme
    );
  }, [
    loadingDelete,
    loadingBillingShippingUpdate,
    addressBookDivs.alternateAddressDivs.length,
    addressBookDivs.billingAddressesDivs.length,
    addressBookDivs.shippingAddressDivs.length
  ]);

  return (
    <div>
      <DeleteAddressDialog
        openDialogue={openDeleteDialogue}
        setOpenDialogue={setOpenDeleteDialogue}
        addressToDelete={addressToDelete}
        setAddressToDelete={setAddressToDelete}
        loadingDelete={loadingDelete}
        setLoadingDelete={setLoadingDelete}
        setOpenDeleteDialogue={setOpenDeleteDialogue}
        enqueueSnackbar={enqueueSnackbar}
        setOpen={setOpen}
        setAddressToEdit={setAddressToEdit}
        setAddressBookDivs={setAddressBookDivs}
        theme={theme}
        setLoadingBillingShippingUpdate={setLoadingBillingShippingUpdate}
      />
      <Box>
        <Paper elevation={0}>
          <div className="infoBar infoBarThin">
            <div className="info"><h3>Manage Your Addresses</h3></div>
          </div>
          {loading || loadingBillingShippingUpdate
            ?
            <div className="loadingProfile">
              <Box>
                {addressBookSpinnerStyle &&
                  <CircularProgress style={addressBookSpinnerStyle}/>
                }
              </Box>
            </div>
            :
            <div className="centeredContainer">
              <div className="innerColumn">
                {(addressBookDivs.billingShippingAddressDivs.length > 0) &&
                  <Grid container>
                    {addressBookDivs.billingShippingAddressDivs}
                  </Grid>
                }
                {
                  (
                    addressBookDivs.billingAddressesDivs.length > 0 &&
                    addressBookDivs.shippingAddressDivs.length > 0
                  ) &&
                  <>
                    <Grid container>
                      {addressBookDivs.billingAddressesDivs}
                      {addressBookDivs.shippingAddressDivs}
                    </Grid>
                  </>
                }
                {(
                    addressBookDivs.billingAddressesDivs.length > 0 &&
                    addressBookDivs.shippingAddressDivs.length === 0
                  ) &&
                  <>
                    <Grid container>
                      {addressBookDivs.billingAddressesDivs}
                    </Grid>
                  </>
                }
                {(
                    addressBookDivs.shippingAddressDivs.length > 0 &&
                    addressBookDivs.billingAddressesDivs.length === 0
                  ) &&
                  <>
                    <Grid container>
                      {addressBookDivs.shippingAddressDivs}
                    </Grid>
                  </>
                }
                {addressBookDivs.alternateAddressDivs.length > 0 &&
                  <>
                    <Typography sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      marginBottom: '-20px',
                      marginTop: '35px',
                      marginLeft: '27px'
                    }}>
                      Alternate Addresses
                    </Typography>
                    <Grid container>
                      {addressBookDivs.alternateAddressDivs}
                    </Grid>
                  </>
                }
              </div>
              <div>
                <EditAddressDialog
                  open={open}
                  addressToEdit={addressToEdit}
                  setAddressToDelete={setAddressToDelete}
                  setOpenDeleteDialogue={setOpenDeleteDialogue}
                  setOpen={setOpen}
                  setAddressToEdit={setAddressToEdit}
                  setAddressBookDivs={setAddressBookDivs}
                  theme={theme}
                  isWebview={isWebview}
                  setLoadingBillingShippingUpdate={setLoadingBillingShippingUpdate}
                />
                <div>
                  <div className="addNewAddressButton">
                    <Button
                      disabled={profileInfo.addresses && profileInfo.addresses.length >= 6}
                      type="submit"
                      className="primaryButtonDefault"
                      variant="contained"
                      onClick={() => handleClickOpen(
                        null,
                        setAddressToEdit,
                        setOpen,
                        'new'
                      )}
                    >
                      Add a new Address
                    </Button>
                    {profileInfo.addresses && profileInfo.addresses.length >= 6
                      ?
                      <Typography className="error-font">You cannot have more than 6 addresses.
                        Delete an address before adding another</Typography>
                      :
                      <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </Paper>
      </Box>
    </div>
  );
}