import React from 'react';
import { Typography, Link } from '@mui/material';
import {
  LINK_TERMS_AND_CONDITIONS,
  LINK_TERMS_AND_CONDITIONS_CHOICES,
  PRIVACY_POLICY_PFG
} from "../../util/constants";

type TermsAndConditionsProps = {
  theme: string; // Pass the referer as a prop
};

const TermsAndConditions: React.FC<TermsAndConditionsProps> = (props: TermsAndConditionsProps) => {
  const { theme } = props;
  const termsLink = theme === 'choices' ? LINK_TERMS_AND_CONDITIONS_CHOICES : LINK_TERMS_AND_CONDITIONS;

  return (
    <div>
      <Typography component="p" className="confirmation small-text">
        By clicking "Sign in" you agree to the&nbsp;
        <br />
        <Link className={theme === 'choices' ? 'link-choices' : 'link-white'} href={termsLink} target="_blank">
          Terms of Use
        </Link>{' '}
        and&nbsp;
        <Link className={theme === 'choices' ? 'link-choices' : 'link-white'} href={PRIVACY_POLICY_PFG} target="_blank">
          Privacy Policy
        </Link>
      </Typography>
    </div>
  );
};

export default TermsAndConditions;