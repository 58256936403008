import React from 'react';
import {Grid} from "@mui/material";
import {AddressesArray, ProfileInfoType, SingleAddress} from '../../../../contexts/types/myAccount';
import {formatPhoneNumber} from "../../../../util/phoneFormatter";
import {AddressBookDivs, OrganizedAddressBook} from "../addressesTypes";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../addresses.css';
import {formatPostalCode} from "../helpers/postalCodeFormatter";

const organizeAddressBook = async (addresses: AddressesArray) => {
  let organizedAddressBook: OrganizedAddressBook = {
    billingAddresses: [],
    shippingAddress: [],
    billingShippingAddress: [],
    alternateAddress: []
  }
  for (let address of addresses) {
    if (address && (address.defaultBillingAddress && address.defaultShippingAddress)) {
      organizedAddressBook.billingShippingAddress.push(address);
      continue;
    }
    if (address && address.defaultBillingAddress) {
      organizedAddressBook.billingAddresses.push(address);
      continue;
    }
    if (address && address.defaultShippingAddress) {
      organizedAddressBook.shippingAddress.push(address);
      continue;
    }
    if (address && (!address.defaultShippingAddress && !address.defaultShippingAddress)) {
      organizedAddressBook.alternateAddress.push(address);
    }
  }
  return organizedAddressBook;
}

const organizeAddressDivs = async (
  enqueueSnackbar: any,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setAddressToDelete: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  handleClickOpen: Function,
  handleBillingShippingUpdate: Function,
  profileInfo: ProfileInfoType,
  organizedAddressDivs: AddressBookDivs,
  type: string,
  addressesToAdd: SingleAddress[],
  theme: string,
  setLoadingBillingShippingUpdate: React.Dispatch<React.SetStateAction<boolean>>
): Promise<any> => {
  let organizedAddressDivType: JSX.Element[];
  switch (type) {
    case 'billingAddresses':
      organizedAddressDivType = organizedAddressDivs.billingAddressesDivs;
      break;
    case 'shippingAddress':
      organizedAddressDivType = organizedAddressDivs.shippingAddressDivs;
      break;
    case 'billingShippingAddress':
      organizedAddressDivType = organizedAddressDivs.billingShippingAddressDivs;
      break;
    case 'alternateAddress':
      organizedAddressDivType = organizedAddressDivs.alternateAddressDivs;
      break;
  }

  addressesToAdd.forEach((addressToAdd: SingleAddress) => {
    organizedAddressDivType.push(
      <Grid item xs={12} sm={12} md={6} lg={6} key={addressToAdd.id}>
        <div className="addressesList" key={addressToAdd.id}>
          <div className="addressContainer">
            <div>
              {type === 'billingAddresses' &&
                <Typography sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                  Billing Address
                </Typography>
              }
              {type === 'shippingAddress' &&
                <Typography  sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                  Shipping Address
                </Typography>
              }
              {type === 'billingShippingAddress' &&
                <Typography  sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                  Billing and Shipping Address
                </Typography>
              }
              <div className="nameContainer">
                {addressToAdd.firstName ?
                  <Typography className="firstNameMargin">
                    {addressToAdd.firstName}
                  </Typography>
                  :
                  <></>
                }
                {addressToAdd.lastName ?
                  <Typography>
                    {addressToAdd.lastName}
                  </Typography>
                  :
                  <></>
                }
              </div>
              {addressToAdd.primaryPhone ?
                <Typography className="generatedAddressTypography">
                  {formatPhoneNumber(addressToAdd.primaryPhone)}
                </Typography>
                :
                <></>
              }
              <Typography className="generatedAddressTypography">
                {addressToAdd.street1} {addressToAdd.street2}
              </Typography>
              <Typography className="generatedAddressTypography">
                {addressToAdd.cityName} {addressToAdd.provinceCode} {formatPostalCode(addressToAdd.postalCode)}
              </Typography>
              <Typography className="generatedAddressTypography">
                {addressToAdd.countryCode}
              </Typography>
              <div className="shippingAndBillingAddresses">
                {addressToAdd.defaultBillingAddress
                  ?
                  <></>
                  :
                  <Typography
                    className="addressLink"
                    onClick={() => handleBillingShippingUpdate(
                      addressToAdd,
                      profileInfo,
                      setOpenDeleteDialogue,
                      setAddressToDelete,
                      'billing',
                      enqueueSnackbar,
                      setOpen,
                      setAddressToEdit,
                      setAddressBookDivs,
                      theme,
                      setLoadingBillingShippingUpdate
                    )}
                  >
                    Make this my billing address
                  </Typography>
                }
                {addressToAdd.defaultShippingAddress
                  ?
                  <></>
                  :
                  <Typography
                    className="addressLink"
                    onClick={() => handleBillingShippingUpdate(
                      addressToAdd,
                      profileInfo,
                      setOpenDeleteDialogue,
                      setAddressToDelete,
                      'shipping',
                      enqueueSnackbar,
                      setOpen,
                      setAddressToEdit,
                      setAddressBookDivs,
                      theme,
                      setLoadingBillingShippingUpdate
                    )}
                  >
                    Make this my shipping address
                  </Typography>
                }
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <Button
              type="submit"
              className="primaryButtonDefault"
              variant="contained"
              onClick={() => handleClickOpen(
                addressToAdd,
                setAddressToEdit,
                setOpen,
                'edit'
              )}
            >
              Edit
            </Button>
            {profileInfo.addresses.length > 1 &&
            (!addressToAdd.defaultShippingAddress && !addressToAdd.defaultBillingAddress)
              ?
              <Button
                type="submit"
                className="deleteButtonDefault"
                variant="contained"
                onClick={() => {
                  setAddressToDelete(addressToAdd);
                  setOpenDeleteDialogue(true);
                }}
              >
                Delete
              </Button>
              :
              <></>
            }
          </div>
        </div>
      </Grid>
    )
  });
}

// generates addresses dynamically. can add a delete button in here in phase 2 when needed
const generateAddresses = async function (
  profileInfo: ProfileInfoType,
  handleClickOpen: Function,
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToDelete: React.Dispatch<React.SetStateAction<SingleAddress>>,
  handleBillingShippingUpdate: Function,
  enqueueSnackbar: any,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>,
  theme: string,
  setLoadingBillingShippingUpdate: React.Dispatch<React.SetStateAction<boolean>>
) {
  const organizedAddressBook: OrganizedAddressBook = await organizeAddressBook(profileInfo.addresses);
  const organizedAddressDivs: AddressBookDivs = {
    billingAddressesDivs: [],
    shippingAddressDivs: [],
    billingShippingAddressDivs: [],
    alternateAddressDivs: []
  }
  for (const [key, value] of Object.entries(organizedAddressBook)) {
    await organizeAddressDivs(
      enqueueSnackbar,
      setAddressBookDivs,
      setOpen,
      setAddressToEdit,
      setAddressToDelete,
      setOpenDeleteDialogue,
      handleClickOpen,
      handleBillingShippingUpdate,
      profileInfo,
      organizedAddressDivs,
      key,
      value,
      theme,
      setLoadingBillingShippingUpdate
    );
  }
  setAddressBookDivs(organizedAddressDivs);
};

export {generateAddresses, organizeAddressBook};