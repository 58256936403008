import {TFunction} from 'react-i18next';
import React from 'react';
import {PasswordInfo} from '../pages/my-account/change-password/changePasswordTypes';
import {EmailInput} from '../pages/my-account/change-email/changeEmailTypes';
import {Label} from "../pages/register/validation/4-validation";
import {
  ADDRESS_REGEX,
  CITY_REGEX,
  DATE_REGEX,
  DAYS_IN_MONTH,
  EARLIEST_YEAR_FROM_DATE,
  EMAIL_REGEX,
  MORE_REWARDS_AC_REGEX,
  MORE_REWARDS_CARD_REGEX,
  NAME_REGEX,
  OLDEST_YEAR_FROM_DATE,
  ONE_TIME_PASS_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  POSTAL_CODE_REGEX
} from './constants';

export const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
]

export const generateMonths = () => {
  let selectedMonths:  Label[] = [];
  for (const month of months) {
    selectedMonths.push(month);
  }
  return selectedMonths;
}

export const generateDays = () => {
  let selectedDays: Label[] = [];
  for (let i = 1; i <= DAYS_IN_MONTH; i++) {
    if (i < 10) {
      selectedDays.push({ label: i.toString(), value: '0' + i.toString() });
    } else {
      selectedDays.push({ label: i.toString(), value: i.toString() });
    }
  }
  return selectedDays;
}

export const generateYears = () => {
  let selectedYears: Label[] = [];
  let currentYear: number = new Date().getFullYear();
  let start: number = currentYear - EARLIEST_YEAR_FROM_DATE;
  let end: number = currentYear - OLDEST_YEAR_FROM_DATE;
  for (let i = start; i > end; i--) {
    selectedYears.push({ label: i.toString(), value: i.toString() });
  }
  return selectedYears;
}

export const emailValidator = (email: string, t: TFunction): string => {
  if (email.match(EMAIL_REGEX)) {
    return '';
  } //t('
  if (email.length === 0) {
    return t('VALIDATOR.EMAIL.REQUIRED');
  }
  if (email.length > 40) {
    return t('VALIDATOR.EMAIL.TOO_LONG');
  }
  return t('VALIDATOR.EMAIL.INCORRECT');
};
export const moreRewardsCardValidator = (card: string, t: TFunction): string => {
  if (card.match(MORE_REWARDS_CARD_REGEX)) {
    return '';
  }
  if (card.length === 0) {
    return t('VALIDATOR.MORE_REWARDS.REQUIRED');
  }
  return t('VALIDATOR.MORE_REWARDS.INCORRECT');
};
export const moreRewardsActivationCodeValidator = (an: string, t: TFunction): string => {
  if (an.match(MORE_REWARDS_AC_REGEX)) {
    return '';
  }
  if (an.length === 0) {
    return t('VALIDATOR.MORE_REWARDS_AC.REQUIRED');
  }
  return t('VALIDATOR.MORE_REWARDS_AC.INCORRECT');
};
export const passwordValidator = (pass: string, t: TFunction): string => {
  if (pass.match(PASSWORD_REGEX)) {
    return '';
  }
  if (pass.length === 0) {
    return t('VALIDATOR.PASSWORD.REQUIRED');
  }
  if (pass.length < 8) {
    return t('VALIDATOR.PASSWORD.TOO_SHORT');
  }
  if (pass.length > 32) {
    return t('VALIDATOR.PASSWORD.TOO_LONG');
  }
  return t('VALIDATOR.PASSWORD.INCORRECT');
};

export const mobilePhoneValidator = (number: string, t: TFunction): string => {
  if (number.match(PHONE_REGEX)) {
    return '';
  }
  // currently, not required
  if (number.length === 0) {
    return t('VALIDATOR.PHONE.REQUIRED');
  }
  if (number.length > 10) {
    return t('VALIDATOR.PHONE.TOO_LONG');
  }
  return t('VALIDATOR.PHONE.INCORRECT');
};
export const homePhoneValidator = (number: string, t: TFunction): string => {
  if (number.match(PHONE_REGEX)) {
    return '';
  }
  // currently, not required
  if (number.length === 0) {
    return '';
  }
  if (number.length > 10) {
    return t('VALIDATOR.PHONE.TOO_LONG');
  }
  return t('VALIDATOR.PHONE.INCORRECT');
};

export const firstNameValidatorNoRegex = (name: string, t: TFunction): string => {
  name = name.trim();
  if (name.length === 0) {
    return t('VALIDATOR.F_NAME.REQUIRED');
  }
  if (name.length < 1) {
    return t('VALIDATOR.F_NAME.TOO_SHORT');
  }
  if (name.length > 40) {
    return t('VALIDATOR.F_NAME.TOO_LONG');
  }
  return '';
};

export const lastNameValidatorNoRegex = (name: string, t: TFunction): string => {
  name = name.trim();
  if (name.length === 0) {
    return t('VALIDATOR.L_NAME.REQUIRED');
  }
  if (name.length < 1) {
    return t('VALIDATOR.L_NAME.TOO_SHORT');
  }
  if (name.length > 40) {
    return t('VALIDATOR.L_NAME.TOO_LONG');
  }
  return '';
};

export const firstNameValidator = (name: string, t: TFunction): string => {
  name = name.trim();
  if (name.match(NAME_REGEX)) {
    return '';
  }
  if (name.length === 0) {
    return t('VALIDATOR.F_NAME.REQUIRED');
  }
  if (name.length < 1) {
    return t('VALIDATOR.F_NAME.TOO_SHORT');
  }
  if (name.length > 40) {
    return t('VALIDATOR.F_NAME.TOO_LONG');
  }
  return t('VALIDATOR.F_NAME.INCORRECT');
};

export const lastNameValidator = (name: string, t: TFunction): string => {
  name = name.trim();
  if (name.match(NAME_REGEX)) {
    return '';
  }
  if (name.length === 0) {
    return t('VALIDATOR.L_NAME.REQUIRED');
  }
  if (name.length < 1) {
    return t('VALIDATOR.L_NAME.TOO_SHORT');
  }
  if (name.length > 40) {
    return t('VALIDATOR.L_NAME.TOO_LONG');
  }
  return t('VALIDATOR.L_NAME.INCORRECT');
};

export const addressValidator = (add: string, t: TFunction): string => {
  add = add.trim();
  if (add.match(ADDRESS_REGEX)) {
    return '';
  }
  if (add.length === 0) {
    return t('VALIDATOR.ADDRESS.REQUIRED');
  }
  if (add.length < 3) {
    return t('VALIDATOR.ADDRESS.TOO_SHORT');
  }
  if (add.length > 50) {
    return t('VALIDATOR.ADDRESS.TOO_LONG');
  }
  return t('VALIDATOR.ADDRESS.INCORRECT');
};

export const address2Validator = (add: string, t: TFunction): string => {
  if (add.match(ADDRESS_REGEX) || add.length === 0) {
    return '';
  }
  if (add.length < 3) {
    return t('VALIDATOR.ADDRESS.TOO_SHORT');
  }
  if (add.length > 50) {
    return t('VALIDATOR.ADDRESS.TOO_LONG');
  }
  return t('VALIDATOR.ADDRESS.INCORRECT');
};

export const cityValidator = (city: string, t: TFunction): string => {
  city = city.trim();
  if (city.length === 0) {
    return t('VALIDATOR.CITY.REQUIRED'); // Check if it is there first
  }
  if (city.length < 3) {
    return t('VALIDATOR.CITY.TOO_SHORT'); // Then too short second
  }
  if (city.length > 32) {
    return t('VALIDATOR.CITY.TOO_LONG'); // Then too long third
  }
  if (city.match(CITY_REGEX)) {
    return '';
  }
  return t('VALIDATOR.CITY.INCORRECT');
};

export const postalCodeValidator = (pc: string, t: TFunction): string => {
  pc = pc.trim();
  pc = pc.replace('-', '');
  pc = pc.replace(' ', '');
  if (pc.match(POSTAL_CODE_REGEX)) {
    return '';
  }
  if (pc.length === 0) {
    return t('VALIDATOR.POSTAL_CODE.REQUIRED');
  }
  return t('VALIDATOR.POSTAL_CODE.INCORRECT');
};

export const dateValidator = (date: string, t: TFunction): string => {
  if (date.match(DATE_REGEX)) {
    return '';
  }
  if (date.length === 0) {
    return t('VALIDATOR.BIRTH_DATE.REQUIRED');
  }
  return t('VALIDATOR.BIRTH_DATE.INCORRECT');
};

const isValidDate = (dateString: string) => {
  // First check for the pattern
  let regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

  if (!regex_date.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  let parts = dateString.split('-');
  let day = parseInt(parts[2], 10);
  let month = parseInt(parts[1], 10);
  let year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }
  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const dobValidator = (date: string, t: TFunction): string => {
  if (!isValidDate(date)) {
    return t('VALIDATOR.BIRTH_DATE.INCORRECT');
  }
  let maxDate = new Date(new Date().setDate(new Date().getDate() - 365 * 115));
  let minDate = new Date(new Date().setDate(new Date().getDate() - 365 * 13));
  try {
    let dob = new Date(date);
    if (dob < maxDate) {
      return t('VALIDATOR.BIRTH_DATE.MAX');
    } else if (new Date(dob) > minDate) {
      return t('VALIDATOR.BIRTH_DATE.MIN');
    }
  } catch (e) {
    return t('VALIDATOR.BIRTH_DATE.INCORRECT');
  }
  return '';
};

export const oneTimePassValidator = (pass: string, t: TFunction): string => {
  if (pass.match(ONE_TIME_PASS_REGEX)) {
    return '';
  }
  if (pass.length) {
    return t('VALIDATOR.ONE_TIME_PASS.REQUIRED');
  }
  return t('VALIDATOR.ONE_TIME_PASS.INCORRECT');
};

// Check to ensure that the password and password confirmation match
export const passwordsMatch = (
  password: string,
  passwordConfirm: string,
  setPasswordInfo: React.Dispatch<React.SetStateAction<PasswordInfo>>
): boolean => {
  if ((password !== passwordConfirm) && passwordConfirm.length > 0 && password.length > 0) {
    setPasswordInfo((prev: PasswordInfo) => ({
      ...prev,
      helperText: `New password and confirmation password don't match`,
      passwordsMatch: false
    }));
    return false;
  }
  setPasswordInfo((prev: PasswordInfo) => ({
    ...prev,
    helperText: '',
    passwordsMatch: true
  }));
  return true;
};

// Checks to ensure that any input field on the current page is empty or not
export const checkInputFields = (
  inputInfo: any,
  setInputInfoValue: React.Dispatch<React.SetStateAction<PasswordInfo>> | React.Dispatch<React.SetStateAction<EmailInput>>,
  type: string
): void => {
  let anyEmpty: boolean = false;
  Object.entries(inputInfo).map(([key]) => {
    if (type === 'email') {
      if (inputInfo[key] === '') {
        anyEmpty = true;
      }
    } else {
      if (inputInfo[key].input === '') {
        anyEmpty = true;
      }
    }
  });
  setInputInfoValue((prev: any) => ({
    ...prev,
    anyInputIsEmpty: anyEmpty
  }));
};