import {atom} from 'jotai';
import {PhoneNumbers} from "../pages/my-account/profile/profile";

/* jotai global state management for our profile pages
 * https://jotai.org/ */

/* global state atom to keep track of a users phone numbers these
 * values are updated without refreshing the whole component tree */
export const phoneAtom = atom<PhoneNumbers>({
  primary: '',
  alternate1: '',
  alternate2: '',
});

// global state management atom for the rest of the users profile
export const profileInfoAtom = atom({
  email: '',
  firstName: '',
  lastName: '',
  province: '',
  homeStore: '',
  moreRewardsCard: '',
  points: '',
  dateOfBirth: {
    day: '',
    month: '',
    year: ''
  },
  emailSubscriptions: null,
  addresses: null,
  linkedAccounts: null,
  googleJwt: '',
  nameChangeAttributes: [],
  biometricData: {
    deviceName: ''
  }
});

export const goToParam = atom({goTo: ''});